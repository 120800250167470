.VisionsOnboarding {
  height: 100vh;
  &.blue {
    background: var(--hero-blue-0-b-455-f, #0b455f);
    height: 100%;
    display: flex;

    .logo {
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }

  .slides {
    width: 75% !important;
  }

  .topLeft {
    position: absolute;
    top: 0;
    left: 0;
  }

  .titleSlide {
    text-align: left;
    justify-content: center;
    padding-left: 10%;

    h1 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 74px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 81.4px */
      margin-bottom: 1rem;
    }

    h2 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
    }

    p {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
    }
  }

  .flipBoxes {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .box {
      width: 27%;
      display: flex;
      flex-direction: column;
      perspective: 1000px; /* Remove this if you don't want the 3D effect */
      cursor: pointer;

      &.rotate {
        .inner {
          transform: rotateY(180deg);
        }
      }

      .top {
        h4 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 18px */
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }

      .inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
      }

      .front,
      .back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;

        > h2 {
          width: 100%;
          max-width: 100%;
        }
      }

      .back {
        transform: rotateY(180deg);

        > h2 {
          width: 100%;
          max-width: 100%;
        }
      }

      .front,
      .back {
        border-radius: 4.398px;
        background: #eaf1f4;
        border: 10px solid #fff;
        padding: 15px;
        height: 400px;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        text-align: left;
        &.yellow {
          background: #fff8e0;
        }

        &.red {
          background: #fff2eb;
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);

          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
        }

        h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 14.4px */
        }

        h2 {
          color: #2a7394;
          margin-top: 1rem;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
        }

        h5 {
          color: #2a7394;

          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
          margin-top: auto;
        }
      }
    }
  }

  .exampleBoxes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;

    .box {
      height: 500px;
      background: #eaf1f4;
      border-radius: 5.139px;
      border: 10px solid #fff;
      width: 24%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.yellow {
        background: #fff8e0;

        span {
          background: var(
            --e-4-c-037,
            linear-gradient(0deg, #e4c037 0%, #e4c037 100%),
            #ecddd8
          );
        }
      }

      &.red {
        background: #fff2eb;

        span {
          background: var(
            --f-48-a-4-e,
            linear-gradient(0deg, #f48a4e 0%, #f48a4e 100%),
            #ecddd8
          );
        }
      }

      span {
        border-radius: 3.36px;
        background: var(--3485-aa, #3485aa);
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 11.425px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 12.568px */
        padding: 5px 10px;
      }

      h2 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 21.782px */
        margin-top: 1rem;
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
      }

      &.cover {
        background-size: cover;
        background-position: center;

        span {
          border-radius: 3.36px;
          background: var(--hero-blue-0-b-455-f, #0b455f);
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 11.425px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 12.568px */
          padding: 5px 10px;
        }

        .bottom {
          margin-top: auto;
          p {
            color: #fff;
            font-family: Inter;
            font-size: 13.441px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            width: 70%;
          }
        }

        h2 {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.4em;
          margin-top: 10px;
        }
      }
    }
  }

  .topRight {
    position: absolute;
    top: -20px;
    right: -50px;
  }

  .content {
    text-align: left;
    height: 100vh;

    .here {
      max-height: 340px;
      margin: 0px auto;
      display: block;
      margin-top: 3rem;
    }

    h2 {
      color: var(--0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.4em;
      margin-bottom: 1rem;
      max-width: 80%;
      margin-top: 3rem;
    }

    p {
      color: var(--0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      b {
        margin-bottom: -10px;
        display: block;
        padding: 0;
      }
    }
  }
  .bold {
    font-weight: bold;
  }
}
