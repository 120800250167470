.nav-bar__container__mobile {
  width: 100%;
  display: flex;
  position: relative;

  @media (max-width: 1200px) {
    display: flex;
  }

  @media (min-width: 1201px) {
    display: none !important;
  }

  .nav-bar__menu__mobile {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0px 30px;
    margin: 0px auto;
    flex-direction: column;
    position: absolute;
    top: 70px;
    height: 100vh;
    width: 90%;
    right: 0;
    background-color: red;
    background-color: #013247;
    padding-top: 50px;

    z-index: 9999999999;

    .nav-bar__brand.full img {
      margin-top: 20px;
      margin-bottom: 40px;
      width: 150px;
      max-width: 100% !important;
    }

    .nav-bar__links {
      margin-left: 0px;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      width: 95%;

      .link {
        margin-right: 0px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        padding: 13px 15px;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        margin-bottom: 0.5rem;
        flex-direction: row;
        flex-wrap: wrap;

        svg path,
        svg g {
          fill: white;
        }

        span {
          font-size: 9px;
          margin-left: auto;
          color: #fff;
          background-color: #ff9e67;
          padding: 6px 7px;
          border-radius: 5px;
          opacity: 1;
        }

        .item {
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          width: calc(100% + 40px);
          flex-grow: 1;
          padding: 10px;
          padding-right: 0;
          padding-left: 15px;
          margin-left: -15px;

          svg path,
          svg g {
            fill: white;
          }

          &.true {
            background-color: blue;
            background-color: #ff9e67;
            border-radius: 10px;
            color: black;

            svg path {
              color: black;
              fill: black;
            }
          }

          svg:nth-child(2) {
            font-size: 11px;
            opacity: 0.5;
            margin-left: auto;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          margin-left: 48px;
          margin-top: 1rem;
          margin-bottom: -1rem;
          li {
            margin-bottom: 0.5rem;
            padding: 0;
            position: relative;
            padding-bottom: 10px;
            list-style: none;
            font-size: 15px;
            font-weight: 300;

            &:hover,
            &.true {
              color: #fff;
              font-weight: 800;

              &:before {
                background: #fff;
              }
            }

            .line {
              height: 26px;
              width: 2px;
              background: #395868;
              position: absolute;
              left: -26px;
              top: 18px;
            }

            &:before {
              width: 15px;
              height: 15px;
              content: " ";
              background: #395868;
              display: block;
              border-radius: 100%;
              margin-left: -7px;
              margin-top: -10px;
              position: absolute;
              left: -25px;
              top: 13px;
            }
          }
        }

        &.active {
          background-color: #ff9e67;
          border-radius: 10px;
          opacity: 1;
          color: black;

          svg path {
            fill: black !important;
          }
        }

        &:hover {
          opacity: 1;
        }

        a {
          color: #fff;
          text-decoration: none;
          font-size: 1.2rem;
          padding: 20px;
        }

        svg {
          margin-right: 0.8rem;
          width: 1.3em;
          height: 1.3em;
        }

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .bar {
      display: flex;
      margin-left: 50px;

      a {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 18px;
        color: #ffff;
        margin-right: 2em;
      }
    }

    .nav-bar__brand.mobile img {
      max-width: 80px !important;
    }

    @media (min-width: 1200px) {
      .nav-bar__brand.mobile {
        display: none;
      }
    }

    @media (max-width: 1200px) {
      .nav-bar__brand.full {
        display: none;
      }
    }

    img {
      max-width: 200px;
    }

    .nav-bar__actions {
      svg {
        color: #000;
        font-size: 20px;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #e5e8f0;
        padding-right: 12px;

        &:hover {
          background-color: black;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .nav-bar {
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #013247;

    svg {
      color: #fff;
      font-size: 30px;
    }

    .nav-bar__brand.full img {
      max-width: 120px !important;
      margin: 0px auto;
    }

    .nav-bar__brand.mobile {
      width: 100%;
      justify-content: flex-start;
      display: flex;
      margin-left: 0;
      padding-left: 0;
      a {
        justify-self: center;
      }
      img {
        max-width: 40px !important;
        margin-left: 0px;
      }
    }

    @media (min-width: 1200px) {
      .nav-bar__brand.mobile {
        display: none;
      }
    }

    @media (max-width: 1200px) {
      .nav-bar__brand.full {
        display: none;
      }
    }

    img {
      max-width: 200px;
    }

    .nav-bar__actions {
      svg {
        color: #000;
        font-size: 20px;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #e5e8f0;
        padding-right: 12px;

        &:hover {
          background-color: black;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
