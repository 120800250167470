.CompanyOnboarding {
  background: var(--hero-tan-f-4-eeec, #f4eeec);
  height: 100vh;
  display: flex;
  flex-direction: column;

  h3 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    text-align: center;
    /* Heading 4 */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
  }

  .code {
    position: relative;
    p {
      display:none;
      position: absolute;
      background-color: #fb9e67;
      padding: 15px;
      left: 0%;
      top:60px;
      right: auto;
      color: #fff;
      color: var(--white-ffffff, #fff);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 16.8px */
      border-radius: 20px;
      width:100%;

      &:before {
        background-color: #fb9e67;
        width:20px;
        height: 20px;
        content: " ";
        display: block;
        position: absolute;
        top:-5px;
        left:50%;
        transform: rotate(45deg);
      }
    }

    &:hover {
      p {
        display: block;
      }
    }
  }

  button {
    margin-top: 3em;
  }

  label {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    text-align: center;
    /* Heading 1 */
    font-family: Inter;
    font-size: 62px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 68.2px */
    margin-top: 1rem;
  }

  .powered-by {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top:60px;

    img {
      margin:0;
      padding:0;
      margin-bottom:-10px !important;
      margin-left:1rem;
    }

    h5 {
      color: var(--hero-blue-0-b-455-f, #0b455f) !important;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      margin-right: 10px;
      margin:0;
      padding:0;
      margin-top:0 !important;
      margin-right:.5rem !important;
    }
  }
  .input-group,
  form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .error {
      background-color: #ff9e67;
      padding: 10px 40px;
      border-radius: 15px;
      color: #fff;
      width: 100%;
      text-align: center;
      margin: 0;
    }

    button {
      margin-top:80px;
    }
    input {
      border-radius: 20px;
      border: 1px solid #d9d9d9;
      background: #fff;
      color: #d9d9d9;
      font-family: "Inter";
      font-size: 4em;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 68.2px */
      padding: 30px;
      height: auto;
      border-radius: 20px;
      box-shadow: none;
      border: 1px solid #d9d9d9;
      text-align: center;
      margin: 0;
      margin-top: 1rem;

      &:active,
      &:focus {
        color: #000;
      }
    }

    h5 {
      color: #d9d9d9;
      /* Heading 5 */
      font-family: "Inter";
      font-size: 1.8em;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      margin-top: 1em;
      color: var(--c-3988-a, #c3988a);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 28.8px */
    }
  }
}
