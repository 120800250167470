.LifelineWidgetSolo {
    width:100%;
    background-color: transparent;
    .middle {
        padding:20px;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        flex-wrap: nowrap;
        height: 700px;

        .LineChart {
            width:100%;
            min-height: 500px;

            canvas {
                min-height: 500px;
            }

            .legend {
                display:flex;
                margin-bottom:1rem;

                button {
                    padding: 9px 16px;
                    gap: 10px;
                    margin-right:1rem;
                }
            }
        }
        
    }
}