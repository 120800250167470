.LessonTimeEditor {
  background-color: #ececec;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 10px;

  .dates {
    display: flex;
    margin-top: 20px;
    align-items: center;
    > div {
      margin-right: 20px;
    }
  }

  .attendanceBox {
    .saveAttendance {
      margin-top:2rem;
    }

    .mark {
      background-color: #013247;
      color:#fff;
      box-shadow: none;
      font-weight: 500;
    }
  }

  .names {
    display: flex;
    margin-top: 20px;
    align-items: center;
    flex-wrap: wrap;
    > div {
      margin-right: 20px;
      width: 25%;
      display: flex;
      align-items: center;

      input,
      label {
        padding: 0;
        margin: 0;
      }
      input {
        margin-right: 1rem;
      }
    }
  }

  .active {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    input {
      width: 40px;
      min-width: 20px;
    }
  }

  .inputBox.button {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    .btn {
      margin: 0;
      align-self: flex-start;
      margin-left: auto;
    }
  }

  .session {
    width: 70%;
    min-width: 300px;
  }
}
