.LearnPath {
  padding: 40px;

  .path {
    margin-top: 1rem;
    border-bottom: 2px solid #c2d4dc;
    padding-bottom: 2rem;
    padding-top: 1rem;

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      border-bottom: 2px solid #000;
      display: inline-block;
    }

    span {
      margin-left: 2rem;
      vertical-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #777777;

      svg {
        border: 2px solid #777777;
        border-radius: 100%;
        padding: 2px;
        font-size: 12px;
        height: 0.7rem;
        margin-top: 3px;
        width: 0.7rem;
        margin-right: 0.5rem;
      }
    }
  }

  .title {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #013247;
    padding-bottom: 1.5rem;
    align-items: flex-start;
    position: relative;

    img {
      margin-right: 1.5rem;
      margin-top: 1rem;
    }

    .progress {
      background: #ff9e67;
      border-radius: 99px;
      display: block;
      position: absolute;
      top: 50px;
      right: 20px;
      padding: 6px 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 140%;
      text-align: center;
      color: #0b455f;

      @media (max-width: 768px) {
        top:0px;
      
      }
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: #6c7275;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 2rem;
      color: #013247;

      opacity: 0.6;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #777777;
      max-width: 700px;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: #013247;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 140%;
      margin-top: 1rem;
      margin-bottom: 14px;
    }
  }
}
