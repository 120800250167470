.chatMessage.addon-highlight {
  display: flex;
  margin-left: 60px;

  .chatMessageBox {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    align-items: flex-start;
    padding-right: 0;

    > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .hide {
      display: none;
    }

    img.hide {
      display: block;
      opacity: 0;
    }

    .chatMessageTitle {
      max-width: 73%;
      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
      .bubble {
        background: #fff;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0px 20px 20px 20px;
        padding: 15px 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-width: 100px;
        background-image: url("../../../../images/bottom_right_onboarding.png");
        background-position: bottom right;
        background-repeat: no-repeat;

        &.yellow {
          background-color: #fede66;
          background-image: url('../../../../images/chat_bubble.png');
          background-position: bottom left;
          background-repeat: no-repeat;
          padding-top:2rem;


          .left {
            h3 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 140%;
              color: #013247;
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              opacity: 1;
              /* or 21px */

              /* #013247 */

              color: #013247;
            }
          }

          .right {
            button {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 17px;
              line-height: 140%;
              padding:15px 30px !important;
              text-align: center;
              color: #0b455f;
              cursor: pointer;
            }
          }
        }

        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
          display: flex;
          align-items: flex-end;
          color: #000000;
        }

        .option {
          background: #ffffff;
          border: 0.8px solid #dddddd;
          border-radius: 6px;
          padding: 1rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          cursor: pointer;

          .check {
            width: 40px;
            height: 40px;
            border: 1px solid #e1e1e1;
            border-radius: 100%;
            display: block;
            margin-left: auto;

            &.active,
            &:hover {
              background-color: #0a455f;
            }
          }

          h5 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.4rem;
            width: calc(100% - 70px);
          }
        }

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 1rem;
          letter-spacing: -0.02em;
        }

        .type {
          background-color: #fff;
          padding: 10px;
        }

        .dot-flashing {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite linear alternate;
          animation-delay: 0.5s;
          margin-left: 1rem;
        }
        .dot-flashing::before,
        .dot-flashing::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }
        .dot-flashing::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 0s;
        }
        .dot-flashing::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dot-flashing {
          0% {
            background-color: #000;
          }
          50%,
          100% {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 1.4em;
          width: 100%;
          margin: 0;
          padding: 0;

          &.waiting {
            border-radius: 15px 15px 15px 15px;
          }
        }

        button {
          background-color: transparent;
          border: none;
          padding: 0;
          margin-top: 0.5rem;
          color: #0b455f;
          cursor: pointer;
          font-weight: 800;
          font-size: 12px;
        }
      }
    }
  }
}
