.storyboard-dashboard {
  padding-top: 0px;
  padding-bottom: 60px;
  max-width:90rem;
  padding: 0px 40px;
  margin: 0px auto;
  min-height: 100vh;
  position: relative;

  @media (max-width: 768px) {
    padding: 0px;
  }

  .message {
    display: flex;
    width: 100%;
    max-height: 4rem;
    max-width: 990px;
    margin-top: 1rem;
    margin-right: 2.25rem;
    margin-left: 1.25rem;
    align-items: center;
    align-self: center;
    flex: 1;
    border-radius: 0.5rem;
    background-color: #ffde67;
    box-shadow: 0 5px 10px 1px rgb(0 0 0 / 15%);
    color: #000;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    text-align: left !important;

    p {
      margin: 0;
      padding: 1.5rem;
      text-align: left;
    }
  }

  .dashboard__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    h2 {
      color: #fff;
    }
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13.375px;
    line-height: 13px;
  }

  .crew_container {
    display: flex;
    margin-bottom: 50px;
    overflow-x: scroll;

    img {
      border-radius: 100%;
      border-width: 3px;
      border-style: solid;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 5px;
      border-image-source: linear-gradient(to left, #fff6b7, #f6416c);
    }

    .crew {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-right: 20px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13.375px;
        line-height: 13px;
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  .stories {
    display: flex;
    flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 2.5rem 0px;

    &.blank {
      display: block;
    }

    .arrow {
      position: absolute;
      bottom: 50px;
      right: 160px;
      transform: rotate(-30deg);
      z-index: 2;
      @media (max-width: 768px) {
        transform: rotate(0deg);
        bottom: 130px;
        right: 50px;
      }
    }

    @media (max-width: 768px) {
      display: block;
      margin: 0px auto;
    }

    .story {
      padding-bottom: 20px;
      flex-wrap: wrap;
      width: 32%;

      @media (max-width: 1100px) {
        width: 33%;
      }

      @media (max-width: 990px) {
        width: 100%;
        margin-right: 0;
        padding-right: 0;

        video {
          width: 100% !important;
        }
      }
    }
  }

  .none {
    background-color: #fff;
    max-width:90rem;
    padding: 20px 40px;
    box-shadow: 0px 5px 10px 0px #00000026;
    border-radius: 6px;
    margin: 0px auto;
    display: block;
    justify-self: center;
    z-index: 100;
    position: relative;
    border: 1px solid #aaaaaa;

    p {
      display: inline-block;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      span {
        display: block;
        font-size: 48px;
        line-height: 40px;
        margin-bottom: 10px;
      }
    }
  }

  .top {
    width:100%;
    display:flex;
    justify-content: space-between;
    margin-top:1.4rem;
    margin-bottom:1rem;
    padding:0px 0px;

    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #013247;
      opacity: 0.6;
      margin-bottom:.5rem;
    }

    h2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 140%;
      border:none;
      color: #013247;

      &::before, &::after {
        border:none;
        background-color: transparent;
      }
    }
  }

  .selector {
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 0px;
    padding-bottom: 0;

    @media (max-width: 768px) {
      border-radius: 0px;
      margin-left: 15px;
    }

    h3 {
      margin: 0;
      padding: 20px 35px;
      margin: 0px 0px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 16px !important;
      cursor: pointer;
      margin-right: 20px;
      color: rgba(1, 50, 71, 0.55);
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 21.2252px;
      border-radius: 24px;
      &.active,
      &:hover {
        background-color: #013247;
        color:#fff;
      }
    }
  }

  p {
    font-size: 16px;
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 30px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
