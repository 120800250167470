.view {
  max-width:90rem;
  margin: 0px auto;
  padding:20px 40px;

  @media (max-width: 768px) {
    padding:0px 10px;
  }
  
  .customize {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 3em;
    font-family: Inter, sans-serif;
    color: #0d8cc2;
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: 200;
    flex-wrap: wrap;
    overflow: hidden;
    padding-top: -20px;
    overflow: hidden;

    span {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
  .videoContainer {
    display:flex;
    background-color: #fff;
    justify-content: space-between;
    padding:2.5rem 1.25rem 1.25rem;
    border: 1px solid #aaa;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 15%);

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .info {
      margin-top:0 !important;
    }

    .reply {
      h2 {
        margin:0;
        padding:0;
      }
    }


    .replyContainer {
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
      }

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 15px;
        padding: 0;
        margin: 0;
        margin-top: 15px;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
      }
    }
    
    

    .video {
      width:36%;
      video {
        width: 100%;
        max-height: 100%;
        border-radius: 6px;
      }
    }

    .right {
      width:64%;
      padding-left:2rem;

      textarea {
        width: 100%;
      }

      h2 {
        margin-top:0;
      }
    }

    @media (max-width: 768px) {
      .video, .right {
        width:100%;
        padding-left:0;

        .info, .replies, .reply {
          padding-left:0;
          margin-left:0;
        }
      }
    }
  }

  .container {
    background: #ffffff;
    border: 0.5px solid #aaaaaa;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding-bottom: 60px;

    .videoPlayer,
    .TextStory {
      max-width: 400px;
    }

    .videoPlayer {
      margin-top: 20px;
      margin-left: 20px;
    }

    .text {
      background: #f0f0f0;
      border-radius: 5.5px 5.5px 0px 0px;
      padding: 20px;
      font-family: "Inter", sans-serif;
      font-size: 1.125rem;
      line-height: 1.875rem;
    }

    .replies {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      flex-wrap: wrap;
      flex-direction: column;
      flex-grow: unset;

      h2 {
        width: auto;
        align-self: flex-start;
        display: inline-block;
        margin:0;
        margin-right: 25px;
        margin-bottom:10px;
      }

      .reply {
        display: flex;
        margin: 0;
        padding: 0;
        margin-bottom: 15px;

        h2 {
          margin:0;
          padding:0;
        }

        h3,
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          padding: 0;
          margin: 0;
        }

        p {
          font-weight: 400;
        }

        p.date {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 9px;
          line-height: 12px;
          margin-top: 10px;

          color: #58c5f3;
        }
      }

      h2 {
        width: auto;
        align-self: flex-start;
        display: inline-block;
        margin-right: 25px;
      }
    }

    .reply {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      flex-grow: unset;
      margin:20px;

    }

    .replyForm {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      flex-grow: unset;
      margin:20px;
      padding-top:20px;
      border-top:2px solid #ececec;
      padding-top:20px;
      margin-top:0;

      textarea {
        max-width: 100%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
      }

      h2 {
        width: auto;
        align-self: flex-start;
        display: inline-block;
        margin:0;
        margin-right: 25px;
        margin-bottom:10px;
      }

      button {
        display: inline-block;
        width: auto;
        align-self: flex-start;
        display: inline-block;
        margin-right: 25px;
        margin-top: 20px;
      }
    }

    .info {
      display: flex;
      align-items: center;
      margin: 20px;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom:5px;
      margin-bottom:0;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 10px;
      }

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 15px;
        padding: 0;
        margin: 0;
        margin-top: 15px;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
      }
    }
  }
}
