@import "../../../theme.scss";

.ProgramStats {
  display: flex;
  padding-top: 30px;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 30px 40px;

  .attendance {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;

    .names {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top:1rem;
      div {
        width:47%;
      }
      p {
        margin:0;
        padding:0;
        font-size:13px;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    span {
      background-color: #013247;
      padding: 4px 15px;
      color: #fff;
      border-radius: 15px;
    }

    h3 {
      font-size: 20px;
      text-transform: capitalize;
    }
  }

  h2 {
    width: 100%;
    display: inline-block;
    margin-bottom: 0px;
    font-size: 24px;
  }

  .back {
    color: #000;

    a {
      color: #000;
    }
  }

  .subtitle {
    margin-top: 3rem;
  }
  .statsBox {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 30%;

    &.singleStat {
      display:flex;
      flex-direction: column;
      min-height: 300px;
      
      p {
        margin:auto;
        font-size:40px;
      }
    }

    &.togo {
      opacity: 0.4;
    }

    .title {
      display: flex;
      h3 {
        font-size: 20px;
        text-transform: capitalize;
      }

      > span {
        font-size: 14px;
        background-color: #013247;
        color: #fff;
        padding: 4px 15px;
        font-weight: bold;
        border-radius: 20px;
        margin-left: auto;

        &:nth-child(3) {
          margin-left: 10px;
        }
      }
    }

    > h5 {
      font-size: 16px;
      margin-top: 0rem;
      margin-bottom: 1rem;
      opacity: 0.5;
      margin-bottom: 1rem;
    }

    .split {
      display: flex;
      gap: 2rem;

      > div {
        width: 50%;
      }

      h4 {
        font-size: 14px;
        margin-bottom: 1rem;
        border-bottom: 2px solid black;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 15px;
        margin-bottom: 0.5em;
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 5rem;
    flex-shrink: 0;
    flex-grow: 0;
    box-sizing: border-box;

    .header,
    .row {
      display: flex;
      width: 100%;
      flex-shrink: 0;
      flex-grow: 0;

      h4 {
        font-size: 15px;
        font-weight: 600;
      }

      .col {
        width: 16%;
        box-sizing: border-box;
        border: 1px solid black;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        padding: 10px;

        &.togo {
          opacity: 0.4;
        }

        &:first-child {
          width: 20%;
        }

        &.name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          justify-content: space-between;

          span {
            background-color: #013247;
            color: #fff;
            padding: 3px 15px;
            border-radius: 15px;
          }
        }
      }
    }
  }
}
