.PathTool {
  width: 100%;
  padding: 0 !important;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
  padding: 30px 40px !important;

  .top {
    align-items: center;
    display: flex;
    padding-right: 3rem;
    .icon {
      width: 150px;
      height: 150px;
      border: 10px solid #e4e4e4;
      margin: 0px auto;
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        padding: 13px;
      }
    }
  }

  .buttons {
    display:flex;
    align-content: flex-start;
    align-items: center;
    justify-content:center;
    margin-left:auto;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
    button {
      width: 180px
    }

    a {
      text-align: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      color: #FF67A7;
      margin-top:1rem;

      &:hover {
        color: darken(#FF67A7, 30%)
      }
    }
  }

  .bottom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    width:60%;
    padding-top:0;
    align-items: flex-start;
    align-content: flex-start;

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 34px;
      line-height: 36px;
      margin:0;
      padding:0;
      margin-bottom:1rem;
    }

    .btn {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.5em;
    }
  }
}
