.sessionBox {
  border: 1px solid #aaaaaa;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 3rem;
  padding-bottom:40px;

  .time {
    font-size:1.3rem;
    font-family: "Inter";
    margin-top:1rem;
  }
  

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }
}

.top h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.top h2:after {
  background: #88d57b;
  height: 5px;
  content: "";
  display: block;
  margin-top: -10px;
}

.top .info {
  display: flex;
  margin-top: 27px;
  margin-bottom: 27px;
}

.top p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  margin: 0;
  padding: 0;
}

.expand {
  margin-left: auto;
  margin-top: 30px;
  text-align: right;
  cursor: pointer;
}

.expand p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #58c5f3;
}

.expand p:hover {
  cursor: pointer;
}

.top .info p {
  border: 2px solid #88d57b;
  border-radius: 200px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  padding: 4px 10px;
  color: #88d57b;
  margin-right: 10px;
  display: flex;
}

.top .info p svg {
  margin-right: 6px;
  margin-left: 0;
}

.bottom {
  padding-top: 30px;
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom .right {
  margin-left: 40px;
  width: 160px;
}

.bottom .right a {
  background: #dcf4ff;
  border-radius: 6px;
  padding: 10px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  border: none;
  text-decoration: none;
  align-items: center;
}

.bottom .right a svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  padding-top: 5px;
}

.bottom .left h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 10px;
}

.bottom .left p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
}

.bottom .left h3 {
  display: flex;
  align-items: center;
}

.bottom .left h3 span {
  background: #dcf4ff;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 10px;
  padding: 4px 5px;
  margin-left: 10px;
}

.activities {
  margin-top: 30px;
}

.activity {
  display: flex;
  align-items: center;
  width: 100%;
}

.recording {
  border: 2px solid #88d57b;
  border-radius: 200px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  padding: 4px 10px;
  color: #88d57b;
  margin-right: 10px;
  display: flex;
  text-decoration: none;
}

.activity .info {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex: 1;
  align-items: flex-start;
}

.activity .info {
  display: flex;
  margin-top: 27px;
  margin-bottom: 27px;
  justify-content: flex-start;
}

.activity .time {
  margin-left: auto;
}

.activity .info .image {
  width: 60px;
}

.activity .title h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  padding: 0;
}

.activity .title h3 span {
  background: #dcf4ff;
  border-radius: 50px;
  padding: 0px 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.activity .title h3 span img {
  margin-right: 10px;
  padding: 0;
  margin: 0;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.activity {
  background: #fff9e4;
  border-radius: 4px;
  padding: 10px 20px;
  border-bottom: 2px solid #aaaaaa;
}

.activity:last-child {
  border-bottom: 0px;
}

.activity .title .description {
  margin-top: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.activity .action a,
.activity .action button {
  margin-left: auto;
  background: #58c5f3;
  border-radius: 6px;
  padding: 6px 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  border: none;
  margin-left: auto;
  color: #ffffff;
  margin-left: auto;
}

.activity .action {
  margin-left: auto;
  width: 123px;
  display: flex;
  justify-content: flex-end;
}
