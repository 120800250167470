@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;600&family=Inter:wght@400;500;600;700&family=Space+Grotesk:wght@400;500;600&display=swap");

/* Theme */

$purple: #c695f7;
$purpleHover: #8c44d4;
$purpleDisabled: #f5ebff;
$yellow: #ffde67;
$pink: #ff67a7;
$pinkHover: #bc0e57;
$pinkDisabled: #ffe6f1;
$yellowDisabled: #ffde67;
$yellowHover: #ffde67;

$orange: #ff9e67;
$green: #87d47b;
$greenHover: #2b9d19;
$greenDisabled: #e3f8e0;
$blue: #58c5f3;
$red: #ff6767;
$orangeHover: darken($orange, 10%);

:root {
  --orange: #ff4f40;
  --indigo: #635dff;
  --white: #fff;
  --light-aluminium: #eaecee;
  --aluminium: #bdc4cf;
  --dark-aluminium: #2a2e35;
  --black: #000000;
  --yellow: #ebca40;
  --mandarine: #ff7f38;
  --pink: #ff44dd;
  --blue: #3885ff;
  --aqua: #3ec6eb;
  --emerald: #1bc99f;

  --yellow-mandarine-gradient: linear-gradient(
    153.07deg,
    var(--yellow) -2.47%,
    var(--mandarine) 102.78%
  );

  --mandarine-orange-gradient: linear-gradient(
    153.07deg,
    var(--mandarine) -2.47%,
    var(--orange) 102.78%
  );

  --pink-yellow-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--yellow) 102.78%
  );

  --pink-indigo-gradient: linear-gradient(
    153.07deg,
    var(--pink) -2.47%,
    var(--indigo) 102.78%
  );

  --indigo-aqua-gradient: linear-gradient(
    153.07deg,
    var(--indigo) -2.47%,
    var(--aqua) 102.78%
  );

  --blue-aqua-gradient: linear-gradient(
    153.07deg,
    var(--blue) -2.47%,
    var(--aqua) 102.78%
  );

  --aqua-emerald-gradient: linear-gradient(
    153.07deg,
    var(--aqua) -2.47%,
    var(--emerald) 102.78%
  );

  --emerald-yellow-gradient: linear-gradient(
    153.07deg,
    var(--emerald) -2.47%,
    var(--yellow) 102.78%
  );

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Inter", sans-serif;
  --font-mono: "Inter", sans-serif;
}

iframe {
  border: none;
}

// Form Styles

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 15px;
}

input,
textarea,
.rbt-input-wrapper,
select,
.MuiTextField-root {
  background: #dcf4ff;
  border: 1px solid #dcf4ff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  border-bottom: 2px solid #58c5f3;
  padding: 10px 20px;

  &:focus {
    background-color: #fff;
    color: #000;
    border: none;
    outline: none;
    border: 2px solid #58c5f3;
  }
}

textarea {
  padding: 20px;
}

// Heading Styles

.underline {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  display: inline-block;
  padding-right: 10px;
}

.highlight {
  background-color: #ffde67;
  padding: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  display: inline-block;
  margin: 0;
  padding: 0.2em 1em;

  &.blue {
    background-color: $blue;
  }
  &.green {
    background-color: $green;
  }
  &.orange {
    background-color: $orange;
  }
  &.purple {
    background-color: $purple;
  }
  &.red {
    background-color: $red;
  }
  &.yellow {
    background-color: $yellow;
  }
  &.pink {
    background-color: $pink;
  }
}

// Button Styles

.btn.box {
  border-radius: 5.54717px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.39623px 14.7925px;
  gap: 6.47px;
  border: 0.924528px solid #e1e4ee;
  border-radius: 5.54717px;
  background-color: none;
  padding: 50px;
  background-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12.9434px;
  line-height: 18px;
  color: black;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
  }
}

.btn.text {
  background-color: transparent;
  border: none;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }
}

.btn.empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 4px;
  background: #f1c94b;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  cursor: pointer;
  border: none;

  &:hover {
    background: darken(#f1c94b, 20);
    color: #000;
    transform: scale(1.03);
  }
}

.btn.blank {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 36px;
  gap: 10px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #aaaaaa;
  cursor: pointer;
  background: transparent;
  border: none;
  border: 2px solid #aaaaaa;

  svg {
    font-size: 1em;
  }

  &:hover {
    background: darken(#aaaaaa, 20);
    color: #fff;
    transform: scale(1.03);
  }
}

.btn.back {
  background: #ff67a7;
  border-radius: 6px;
  padding: 6px 12px;
  color: #fff;
  width: 70px;
  height: 24px;
  color: #fff;
  border: none;
  text-align: left;
  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;

  &:hover {
    background-color: #bc0e57;
    color: #fff;
    cursor: pointer;
  }
}

.btn.primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 36px;
  gap: 10px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  border: none;
  border: none;
  background-color: $orange;
  box-shadow: 6px 6px 0px rgba(255, 158, 103, 0.24);
  color: #0b455f;


  svg {
    font-size: 1em;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  &:hover {
    background-color: $orangeHover;
    cursor: pointer;
  }

  &:disabled {
    background-color: $orangeHover;
    cursor: initial;
  }

  &.green {
    background-color: $green;
    border-color: $green;
    &:hover {
      background-color: $greenHover;
      border-color: $greenHover;
    }
    &:disabled {
      background-color: $greenDisabled;
      border: 2px solid $greenDisabled;
      color: #fff;
      cursor: initial;
    }
  }
  &.yellow {
    background-color: $yellow;
    border-color: $yellow;
    color: #7b6002;

    svg {
      color: #7b6002;
    }
    &:hover {
      background-color: $yellowHover;
      border-color: $yellowHover;
    }
    &:disabled {
      background-color: $yellowDisabled;
      border: 2px solid $yellowDisabled;
      color: #fff;
      cursor: initial;
    }
  }
  &.purple {
    background-color: $purple;
    border-color: $purple;
    &:hover {
      background-color: $purpleHover;
      border-color: $purpleHover;
    }
    &:disabled {
      background-color: $purpleDisabled;
      border: 2px solid $purpleDisabled;
      color: #fff;
      cursor: initial;
    }
  }
  &.red {
    background-color: $red;
    border-color: $red;
    &:hover {
      background-color: $red;
      border-color: $red;
    }
    &:disabled {
      background-color: $red;
      border: 2px solid $red;
      color: #fff;
      cursor: initial;
    }
  }
}

.btn.secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  cursor: pointer;
  background: $pink;
  border: none;
  background-color: $orange;
  box-shadow: 6px 6px 0px rgba(255, 158, 103, 0.24);
  color: #0b455f;

  &:hover {
    background-color: $orangeHover;
    cursor: pointer;
  }

  &.disabled {
    opacity: .5;
  }

  &:disabled {
    background-color: $orangeHover;
    border: 2px solid $orangeHover;
    cursor: initial;
  }

  &.green {
    background-color: $green;
    border-color: $green;
    &:hover {
      background-color: $greenHover;
      border-color: $greenHover;
    }
    &:disabled {
      background-color: $greenDisabled;
      border: 2px solid $greenDisabled;
      color: #fff;
      cursor: initial;
    }
  }
  &.purple {
    background-color: $purple;
    border-color: $purple;
    &:hover {
      background-color: $purpleHover;
      border-color: $purpleHover;
    }
    &:disabled {
      background-color: $purpleDisabled;
      border: 2px solid $purpleDisabled;
      color: #fff;
      cursor: initial;
    }
  }
  &.red {
    background-color: $red;
    border-color: $red;
    &:hover {
      background-color: $red;
      border-color: $red;
    }
    &:disabled {
      background-color: $red;
      border: 2px solid $red;
      color: #fff;
      cursor: initial;
    }
  }
}

.btn.blank {
  display: flex;
  border: 1px solid #ff9e67;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 140%;
  text-align: center;
  color: #0b455f;
  padding: 10px 30px;

  svg {
    background: #ff9e67;
    padding: 9px;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    font-size: 12px;
  }

  &:hover {
    background-color: $orangeHover;
    cursor: pointer;
  }

  &:disabled {
    background-color: $orangeHover;
    border: 2px solid $orangeHover;
    cursor: initial;
  }
}

.btn.blue {
  display: flex;
  border-radius: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  color: #0b455f;
  padding: 10px 20px;
  background: #0b455f;
  box-shadow: 6px 6px 0px rgba(11, 69, 95, 0.24);
  border-radius: 12px;
  color: #fede66;

  svg {
    background: #ff9e67;
    padding: 9px;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    font-size: 8px;
  }

  &:hover {
    background-color: darken(#0b455f, 5);
    cursor: pointer;
  }

  &:disabled {
    background-color: $orangeHover;
    border: 2px solid $orangeHover;
    cursor: initial;
  }
}

.white-background {
  background-color: white;
  padding: 40px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: 0.5px solid #aaaaaa;
}

.breadcrumb {
  margin-bottom: 20px;
  display: block;

  .content {
    display: inline-block;
    padding: 0px;
    padding-left: 0;
  }
  a {
    color: black;
    font-weight: 700;
  }
}

.heading1 {
  font-family: Inter, sans-serif;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

.body-text {
  margin-bottom: 1rem;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 1.875rem;

  &.large {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 300;
  }
}
