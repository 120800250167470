.waitlist {
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22.1887px;
    line-height: 26px;
    text-align: center;
    color:#fff;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    mix-blend-mode: normal;
    text-align: center;

    &.skip {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #fff;
      mix-blend-mode: normal;
      margin-top: 40px;
    }
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 4px;
    background: #000000;
    border-radius: 49px;
    color: #fff;
    margin: 0px auto;
    margin-top: 20px;
    font-family: "Inter";
    box-shadow: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid #fff;
    color: #ffffff;

    cursor: pointer;

    &:hover {
      background: #fff;
      color: #000;
    }
  }
}
