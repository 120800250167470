.videoreview {
  max-width: 650px;
  margin: 0px auto;

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15.9787px;
    line-height: 25px;
    color: #fff;
    margin-top:20px;
    display:block;
    text-align: center;
  }

  h3 {
    color:#fff;
    text-align: center;
  }

  button {
    margin-top:20px;
  }

  video {
    width:100%
  }
}
