.pathIndex {
  .container {
    max-width:90rem;
  padding:0px 40px;
    margin: 0px auto;
    
    @media screen and (max-width: 768px) {
      padding: 0px 20px;
    }
  }

  h2 {
    margin-bottom: .5em;
  }

  .body-text {
    font-size: 22px;
    line-height: 1.5em;

    @media screen and (max-width: 768px) {
      font-size: 18px !important;
    }
  }

  h2 span {
    @media screen and (max-width: 768px) {
      font-size: 25px;
    }
  }

  .full-width {
    margin-top: 50px;
    padding: 30px 0px;
    background-color: #dcf4ff;
    padding-bottom: 60px;
    background-color: #521e86;
    padding: 50px 0px;
    padding-top: 25px;
    background-image: radial-gradient(
      circle at center,
      /* dot color */ rgb(140, 68, 213) 2px,
      rgb(140, 68, 213) 0,
      /* background color */ rgb(82, 30, 134) 2px,
      rgb(82, 30, 134) 100%
    );
    padding-bottom: 0;
    background-repeat: repeat;
    background-position: left center;
    background-size: 20px 20px;
    min-height: 100vh;
    padding-bottom:5rem;


    .headline {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom:1rem;
      margin-top:1rem;


      h3 {
        border:none;
        span {
          color: #fff;
          font-size: 36px;
          line-height: 36px;

          &:after {
            background-color: #8C44D5;
          }
        }
      }

      p {
        margin-left: 100px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 1.3em;
        margin-left: 100px;
        max-width: 600px;
        margin-top: 40px;
      }
    }

    .pathTools {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

    }
  }

  .yellow-box {
    background: #ffeaa0;
    padding: 40px;
    margin-top: 70px;
    margin-bottom: 100px;

    h3 {
      margin-top:0;
      padding-top:0;
    }

    .btn {
      background-color: #fff;
      border-color: #fff;
      color: #ff67a7;
    }

    .crewsContainer {
      margin-top: 2rem;

      .crew {
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        margin-top:1rem;
        h3 {
          font-size: 1.4rem;
          margin-bottom:1rem;
        }

        p {
          font-weight: 300;
        }
      }
    }
  }
}
