.StoryboardWelcomeModal {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  .skrim {
    background: #013247;
    opacity: 0.67;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    max-width: 900px;
    max-height: 95vh;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 50px;
    border-radius: 15px;
    border: none;
    background: #ffffff;
    border-radius: 24px;
    overflow: scroll;
    border: 0px;
    height: 90%;
    text-align: left;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
      

    .right {
      width: 60%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      text-align: left;
      margin-top: 2.5rem;
      padding-right: 3rem;

      @media (max-width: 768px) {
        width: 100%;
        padding-right: 0;
      }
        

      .final {
        display: flex;
        align-items: center;
        margin-top: 2rem;

        button {
          width: 200px;
        }

        p {
          text-align: left;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #013247;
          opacity: 0.6;
          margin-right: 30px;
        }
      }

      .box {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;

        svg {
          width: 50px;
          height: 50px;
          padding: 0;
          font-size: inherit;
          flex: none;
        }

        .content {
          margin-left: 0.5em;
          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14.5427px;
            line-height: 140%;
            color: #013247;
            margin: 0;
            padding: 0;
          }
          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            margin: 0;
            padding: 0;
            text-align: left;
            color: #013247;

            opacity: 0.6;
          }
        }
      }

      > h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 140%;
        color: #013247;
        margin: 0;
        padding: 0;
      }

      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: left;
        color: #013247;
        opacity: 0.6;
        margin: 0;
        padding: 0;
      }

      > h5 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14.5427px;
        line-height: 140%;
        text-decoration-line: underline;
        color: #013247;
        margin-top: 2rem;
      }
    }

    .left {
      width: 40%;
      height: 100%;
      @media (max-width: 768px) {
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      margin-top: 3rem;

      .story {
        display: flex;
        background: rgba(255, 158, 103, 0.1);
        padding: 30px;
        width: 200px;
        height: 100px;
        margin: 0px auto;
        max-height: 200px;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          width: 100%;
          padding-right: 0;
        }
      }

      .info {
        display: flex;
        padding: 20px;
        margin-top: auto;

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 13.069px;
          line-height: 140%;
          color: #0b455f;
        }

        h5 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 9.80172px;
          line-height: 140%;
          color: #0b455f;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 1rem;
        }
      }
      .story {
        margin-top: 0px;
        height: calc(100% - 100px);

        &.blue {
          background-image: url("../../../../images/card-blue.png");
          background-repeat: no-repeat;
          background-position: top right;
          background-color: #0b455f;
          background-size: 40px;
          color: #fff;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          overflow: scroll;
          padding: 40px;
        }

        &.white {
          background-image: url("../../../../images/card-white.png");
          background-repeat: no-repeat;
          background-position: top right;
          background-size: 40px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          color: #000 !important;
          padding: 40px;

          p {
            color: #000;
          }
        }

        &.yellow {
          background-image: url("../../../../images/card-yellow.png");
          background-repeat: no-repeat;
          background-position: top left;
          background-color: #fede66;
          background-size: 40px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          color: #000;
          padding: 40px;

          p {
            color: #000;
          }
        }

        &.orange {
          background-image: url("../../../../images/card-orange.png");
          background-repeat: no-repeat;
          background-position: bottom left;
          background-color: #ff9e67;
          background-size: 40px;
          color: #0b455f;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          padding: 40px;
        }
      }

      video {
        width: 100%;
      }
    }
  }
}
