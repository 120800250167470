/* General */

* {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Inter", sans-serif;
  line-height: 1.4em;
  height: 100vh !important;
}

body {
  height: 100vh !important;
  overflow: hide;
}

#root {
  height: 100vh !important;
}

.content_container {
  max-width: 90rem;
  margin: 0px auto;
  position: relative;
  z-index: 2;
  width: calc(100% - 205px);
  height: 100vh;
  overflow: scroll;

  &.minimized {
    width: calc(100% - 100px);
  }

  @media (max-width: 1200px) {
    width: 100%;
    overflow: initial;
  }
}

@media (max-width: 1200px) {
  .home {
    iframe {
      height: 100% !important;
      border: none;
    }
  }

  .page-layout__content {
  }
}

.page-layout__content {
  height: 100vh;
  background-color: #f4eeec;
}

.page-layout {
  margin: 0px auto;
  padding: 0px 0px;
  height: 100vh;
}

@media (max-width: 1200px) {
  .page-layout {
    padding: 0px;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: "200px";
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-size: 15px;
}

body {
  background-color: #f4eeec;
  background-color: #f4eeec;

  font-size: 16px;
}

.page-layout__content {
  display: flex;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

body.white {
  background-color: #fff;
}

.subtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  margin-top: 1em;
  text-align: left;
  margin: 0;
}

.login {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  justify-content: center;
  margin: 0px auto;
  padding: 20px;
  padding-top: 60px;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.86);
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  background: #ffffff;
    border: 1px solid #f4eeec;
    box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36);
    border-radius: 7px;
}

a {
  color: var(--white);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

input,
select {
  height: 47px;
}

iframe {
  border: none;
}

.reveal .controls {
  .navigate-right, .navigate-left {
    .controls-arrow {
      margin-bottom: 80px;
    }
  }
  @media (max-width: 1200px) {
    height: calc(100vh - 80px);
  }
}

.HighlightReelOnboarding.start {
  .controls-arrow {
    color:#fff;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  .skrim {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 900px;
    max-height: 85vh;
    overflow: scroll;
    z-index: 20;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .row {
      display: block;
    }

    .inputBox {
      margin-bottom: 40px;
    }

    label,
    input,
    textarea,
    select {
      width: 100%;
      text-align: left;
    }
  }
}

.designBlobs {
  z-index: 0;
  position: relative;
  .yellow-bg {
    position: absolute;
    top: 20%;
    z-index: 0;
    left: -25px;
  }

  .blue-bg {
    position: absolute;
    top: 100%;
    z-index: 0;
    right: -25px;
  }
}
