.LearnDashboard {
    padding:0px 40px;
    padding-bottom:5rem;
    max-width:90rem;
    padding:0px 40px;
    margin: 0px auto;
    padding-bottom:30px;

    h2 {
        margin-top:2rem;
        display: table;
    }
}