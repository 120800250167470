.addHighlight {
  position: absolute;
  z-index: 999999999999;
}

.addStory {
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  overflow: scroll;
  > p {
    margin-top: 20px;
  }
  position: fixed;
  z-index: 900;
  overflow: hidden;
  height: 100%;

  .rdw-block-wrapper,
  .rdw-fontsize-wrapper,
  .rdw-fontfamily-wrapper,
  .rdw-list-wrapper,
  .rdw-text-align-wrapper,
  .rdw-colorpicker-wrapper,
  .rdw-link-wrapper,
  .rdw-embedded-wrapper,
  .rdw-emoji-wrapper,
  .rdw-image-wrapper,
  .rdw-remove-wrapper,
  .rdw-history-wrapper {
    display: none;
  }

  video {
    margin: 0px auto;
    max-width: 100%;
    display: block;
  }

  iframe {
    border-radius: 26px;
  }

  .sharebox {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    background-color: #fff;
    height: 100px;
    padding: 30px;
    border-radius: 15px;
    margin: 0px auto;
    margin-top: 100px;

    svg {
      margin-right: 20px;
    }

    h5 {
      margin: 0 !important;
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;

    h3 {
      width: 100%;
    }
  }

  .steps {
    margin-top: auto;
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;

    .step {
      width: 30px;
      height: 30px;
      background: #ffeaa0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: #fff;
      margin-right: 1rem;
      font-weight: 600;

      &.active {
        background: #ffde67;
        color: #000;
      }
    }
  }

  .rdw-editor-main {
    padding: 10px;
    border: 2px solid #ececec;
    border-top: none;
    margin-top: -6px;
    min-height: 240px;
  }

  .rdw-editor-wrapper {
  }

  .rdw-editor-toolbar {
    background-color: #005579;
  }

  &.edit {
    width: 100%;

    .left {
      width: 400px !important;
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }

    .right {
      width: calc(100% - 400px) !important;
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow: scroll;
  }

  .white-background {
    margin-top: 0px;
    position: relative;
    border-radius: 0px;
    height: 100%;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    > h2 {
      margin-bottom: 20px;

      span {
        font-size: 1.5rem;
      }
    }
  }

  .avatar {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    select {
      background-color: #e1f6ff;
      border: None;
      color: #000;
      font-weight: 500;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 20px;
    }
  }
  .form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    button {
      &:hover {
      }
    }

    label {
      margin-bottom: 5px;
      width: 100%;
      display: flex;

      span {
        margin-left: auto;
        opacity: 0.3;
        font-size: 0.7rem;
      }
    }
    input,
    textarea {
      background-color: #ececec;
      border: 1px solid #58c5f3;
    }

    .right {
      width: calc(100% - 400px);
      background-color: #f4eeec;
      padding-left: 40px;
      display: flex;
      padding-right: 40px;

      .link {
        display: flex;
        flex-direction: column;
        padding:40px;

        > h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 20px;
          color: #013247;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #013247;
          opacity: 0.6;
          margin-top:20px;
        }

        .bubbleContainer {
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          .left {
            border: none;
            padding-right: 0;
            flex: none;
            width: auto;
            img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
            }
          }
          .rightCol {
            padding-left: 20px;
            width: 100%;
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 25.6px;
              line-height: 31px;
              color: #000000;
            }
            p {
              background: #fede66;
              padding: 20px;
              opacity: 1;
              border-radius: 0px 24px 24px 24px;
            }
          }

          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      image {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
      }

      .white-background {
        width: 87%;
        padding: 5px 20px;
        margin-top: 20px;
        height: 90vh;
        border-radius: 8px;
        padding-top: 0;
        margin: 0px auto;
        margin-top: 50px;
        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 20px 20px;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .preview {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 0 0 100%; /* Let it fill the entire space horizontally */
        max-width: 100%;
        flex-direction: column;

        .email {
          margin: 0px auto;
          background-color: #fff;
          width: 90%;
          margin-top: 20px;
          border-radius: 12px;
          margin-bottom: 20px;
          max-width: 600px;
          overflow: scroll;
          height: 90%;
          .header {
            background-color: #77c2ef;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-radius: 12px 12px 0px 0px;
            img {
              width: 60px;
              height: 60px;
            }
          }

          .logo {
            width: 200px;
            margin: 0px auto;
            display: block;
            margin-bottom: 30px;
          }

          .content {
            h4 {
              font-size: 16px;
              font-weight: 700;
            }

            .share {
              margin-top: 30px;
              background-color: #9ad284;
              text-align: center;
              padding: 40px;

              .underline {
                font-size: 1.8rem;
                margin-bottom: 15px;
              }
            }

            button.primary {
              background-color: #ff9e67;
            }
          }
        }

        &.white {
          .highlightArea .content {
            background-image: url("../../../images/card-white.png");
            background-repeat: no-repeat;
            background-position: top right;
            background-size: 40px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 140%;
          }
        }

        &.yellow {
          .highlightArea .content {
            background-image: url("../../../images/card-yellow.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-color: #fede66;
            background-size: 40px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 140%;
          }
        }

        &.orange {
          .highlightArea .content {
            background-image: url("../../../images/card-orange.png");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-color: #ff9e67;
            background-size: 40px;
            color: #0b455f;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 140%;
          }
        }

        &.blue {
          .highlightArea .content {
            background-image: url("../../../images/card-blue.png");
            background-repeat: no-repeat;
            background-position: top right;
            background-color: #0b455f;
            background-size: 40px;
            color: #fff;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 140%;
          }
        }

        img {
          width: 100%;
          object-fit: cover;
        }

        h5 {
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 15px;
        }
        .highlightArea {
          background-color: #000;
          width: 100% !important;
          height: 90%;
          margin-bottom: 20px;
          background: #013247;
          border-radius: 6px;
          position: relative;
          display: flex;
          flex-wrap: wrap;

          > svg {
            margin-top: -10px;
            width: 100%;
            z-index: 999;
            transform: scale(0.6);
          }

          .content {
            padding: 20px;
            margin-left: -18px;
            margin-top: -18px;
            width: calc(100% + 36px);
            border-radius: 6px;
            color: #000;
            padding: 40px;
          }

          .bottomArea {
            display: flex;
            padding-top: 2rem;
            margin-left: -20px;
            width: calc(100% + 40px);
            padding-left: 20px;
            flex-wrap: wrap;
            img {
              width: 40px;
              height: 40px;
              border-radius: 100%;
              margin-right: 1rem;
            }

            .actions {
              width: 100%;
              border-top: 0.653448px solid rgba(11, 69, 95, 0.12);
              padding-top: 20px;
              display: flex;
              justify-content: space-between;
              padding-right: 20px;
              .reply {
                background: rgba(255, 158, 103, 0.2);
                border-radius: 7.84138px;
                padding: 8px 10px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 9.80172px;
                line-height: 140%;
                text-align: center;
                border: none;
                color: #0b455f;
              }

              .btn.primary {
                background-color: orange;
                background: rgba(255, 158, 103, 0.2);
                border-radius: 7.84138px;
                padding: 8px 10px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 9.80172px;
                line-height: 140%;
                text-align: center;
                border: none;
                color: #0b455f;
                background: #ff9e67;
                box-shadow: 3.92069px 3.92069px 0px rgba(255, 158, 103, 0.24);
                border-radius: 7.84138px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 9.80172px;
                line-height: 140%;
                text-align: center;
                color: #0b455f !important;
              }
            }

            h4 {
              color: #000;
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 13.069px;
              line-height: 140%;
              color: #0b455f;
            }
            h5 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 9.80172px;
              line-height: 140%;
              color: #0b455f;
            }
          }

          .highlightMoment {
            background: #ffffff;
            border: 1px solid #aaaaaa;
            border-radius: 6px;
            padding: 20px;
            width: 60%;
            max-height: 92%;
            overflow: scroll;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;

            @media screen and (max-width: 768px) {
              width: 90%;
            }

            img {
              max-height: 200px;
              margin-bottom: 1rem;
            }

            h3 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 800;
              font-size: 32px;
              line-height: 36px;
              color: #9d9d9d;
              margin-bottom: 1rem;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 300;
              font-size: 17.5469px;
              line-height: 26px;
              margin: 0;
              padding: 0;
              margin-bottom: 1rem;
            }

            a {
              color: #0d8cc2;
              display: block;
              margin-top: 2em;
            }
          }
        }
      }

      .btn.primary {
        background-color: #6699af;
        border-color: #6699af;
        color: #fff;
        padding: 7px 20px;
      }

      .buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .btn {
          background-color: #6699af;
          border-color: #6699af;
          color: #fff;
          padding: 7px 20px;
          border-radius: 5px;
          box-shadow: none;
          border: none;
          font-size: 1em;
          font-weight: 600;
          margin: 0;
          margin-left: 20px;
          cursor: pointer;
          height: 40px;

          &:hover {
            background-color: darken(#6699af, 10);
            border-color: #6699af;
          }

          &.delete {
            background-color: #ff6767;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .left {
      width: 400px;
      padding-top: 20px;
      overflow: scroll;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 0rem;
        padding-left: 15px;
        padding-right: 15px;
        height: auto !important;
      }
      height: 100vh;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      flex-direction: column;

      textarea {
        min-height: 200px;
      }

      .title {
        display: flex;
        border-bottom: 2px solid #ececec;
        margin-bottom: 0em;
        padding-bottom: 1em;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        .close {
          display: flex;
          position: relative;
          padding: 7px 10px;
          background-color: #999999;
          border-radius: 100%;
          color: #fff;
          margin: 0;
          margin-right: 0.5em;
        }

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          margin: 0;
          padding: 0;
        }
      }

      .collaborators {
        margin-bottom: 0rem;
        margin-top: 2rem;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        border-top: 2px solid #ececec;
        padding-bottom: 1rem;
        display: flex;
        flex-direction: row;
        max-height: 50%;
        flex-wrap: wrap;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }

        p {
          margin-bottom: 0.5rem;
          align-self: flex-start;
          width: 100%;
        }
        h5 {
          font-size: 1rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 100%;
          font-size: 1rem;
          font-weight: 700;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;

          svg {
            margin-right: 10px;
          }

          .arrow {
            margin-left: 10px;
          }

          span {
            opacity: 0.5;
            font-size: 0.6rem;
            margin-left: auto;
          }
        }

        .collaboratorInput {
          margin-top: 0rem;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
          margin-bottom: 2rem;

          .error {
            width: 100%;
            display: flex;
            background-color: #ee9b65;
            color: #fff;
            padding: 6px 20px;
            border-radius: 5px;
          }

          .form-group {
            width: 100%;
            margin-bottom: 0.5rem;
            width: 100%;
            padding: 0;

            label {
              font-weight: 600;
              font-size: 0.8rem !important;
            }

            @media screen and (max-width: 768px) {
              width: 100%;
              margin-top: 2em;
            }

            &.full {
              width: 100%;
            }
          }
          input {
            width: 100%;
          }

          .infoSection {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-top: 2rem;
            h4 {
              font-size: 0.9rem;
              font-weight: 700;
              span {
                display: block;
                font-weight: 300;
                font-size: 0.9rem;
              }
            }
            button {
              background-color: transparent;
              color: #ee9b65;
              padding: 2px 10px;
              font-size: 0.8rem;
              height: auto;
              margin-top: 0 !important;
              box-shadow: none;
            }
          }

          label {
            font-weight: 300;
            font-size: 1rem;

            span {
              font-size: 0.7rem !important;
              font-weight: 600;
            }
          }
          button {
            height: 47px;
            border-radius: 5px 5px 5px 5px;
          }
        }

        .collaborator-item {
          width: 100%;
        }

        .collaborator,
        .collaborator-item {
          margin-top: 2rem;
          padding-bottom: 0.5rem;
          display: flex;
          border-bottom: 2px solid #ececec;
          opacity: 0.5;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-right: 10px;
          }

          svg {
            font-size: 30px;
          }

          .delete {
            cursor: pointer;

            &:hover {
              opacity: 1;
              color: #58c5f3;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
          p {
            margin: 0;
            width: 100%;
            padding: 0;
            margin-right: 2rem;
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }
        }
      }

      .buttonActions {
        margin-bottom: 0px;
        background: #ffffff;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.11);
        margin-left: -30px;
        width: calc(100% + 60px);
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        padding-left: 60px;
        padding-right: 60px;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-left: 0px;
          box-shadow: none;
          justify-content: space-between;
          margin-top: 0;
          padding: 10px 0px;

          .btn {
            margin-right: 20px;
          }
        }

        .btn {
          background: #005579;
          border-radius: 6.38597px;
          color: #fff;
          font-size: 16px;
          box-shadow: none;
          font-weight: 500;
          padding: 10px 40px;

          &:disabled {
            opacity: 0.2;
          }

          &.secondary {
            background: #e4e6ea;
            color: #000;
            &:hover {
              border: none;
              background: darken(#e4e6ea, 10);
            }
          }

          &:hover {
            border: none;
            background: darken(#005579, 10);
          }
        }
      }

      .photoBox {
        border-top: 2px solid #ececec;
        padding-top: 2em;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 2em;

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 20px;
          line-height: 20px;
          color: #000000;
        }

        .inputPhotoBox {
          display: flex;
          align-items: center;
          position: relative;
          .icon {
            margin-top: 1em;
            margin-bottom: 1rem;
            background-color: #f0f2f5;
            display: inline-block;
            padding: 10px 13px;
            border-radius: 100%;
          }

          img {
            width: 1rem;
            height: 1rem;
          }

          input {
            position: absolute;
            top: 10px;
            left: 0;
            width: 100%;
            opacity: 0;
          }

          h5 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16.5306px;
            line-height: 19px;
            margin-left: 15px;
          }

          span {
            margin: 0;
            padding: 0;
            margin-left: auto;
            opacity: 0.5;
            font-size: 11px;
          }
        }
      }

      .photo {
        position: relative;
        width: auto;
        display: flex;
        img {
          max-width: 100%;
          min-width: 50%;
          object-fit: cover;
        }

        svg {
          background-color: #005579;
          padding: 5px;
          color: #fff;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .dragAndDrop {
        width: 100%;
        height: 500px;
        background-color: #ececec;
        padding: 20px;
        align-items: center;
        justify-content: center;
        border: 2px dotted black;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;

        @media screen and (max-width: 768px) {
          height: 250px;
        }

        svg {
          font-size: 60px;
          opacity: 0.5;
          margin-bottom: 1rem;
        }

        h4 {
          font-size: 1.4rem;
        }

        p {
          font-size: 18px;
          line-height: 25px;
          margin-top: 1em;
          opacity: 0.5;
        }
      }
    }

    .preview {
      max-width: 200px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .success {
      padding: 20px;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #013247;
        display: flex;
        align-items: center;

        svg {
          background: #47884f;
          color: #fff;
          padding: 5px;
          border-radius: 100%;
          margin-right: 1rem;
        }
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        color: #0b455f;
      }
    }

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      margin-top: 20px;

      padding-left: 30px;
      padding-right: 30px;

      &.copy {
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-bottom:3rem;

        label {
          width: auto;
          margin:0px;
          padding:0px;
          margin-right:15px;
          
        }

        button {
          padding: 7.84138px 15.6828px;
          box-shadow: 3.92069px 3.92069px 0px rgba(255, 158, 103, 0.24);
          border-radius: 7.84138px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 9.80172px;
          line-height: 140%;
          text-align: center;
          color: #0B455F;
          width:150px;
        }
      }

      .backgrounds {
        display: flex;
        justify-content: space-between;

        .background {
          width: 70px;
          height: 70px;
          background-color: white;
          border: 2px solid grey;
          background-size: 40px;
          border: 1px solid #f4eeec;
          border-radius: 9.20014px;

          &.active {
            &:before {
              content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" style="fill:white"/></svg>');
              width: 20px;
              height: 20px;
              display: block;
              margin: 0px auto;
              margin-top: 15px;
              background: rgba(0, 0, 0, 0.64);
              border-radius: 100%;
              padding: 10px;
            }
          }

          &.white {
            background-image: url("../../../images/card-white.png");
            background-repeat: no-repeat;
            background-position: top right;
          }

          &.yellow {
            background-image: url("../../../images/card-yellow.png");
            background-repeat: no-repeat;
            background-position: top left;
            background-size: 40px;
            background-color: #fede66;
          }

          &.orange {
            background-image: url("../../../images/card-orange.png");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 40px;
            background-color: #ff9e67;
          }
          &.blue {
            background-image: url("../../../images/card-blue.png");
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 40px;
            background-color: #0b455f;
          }
        }
      }

      &.split {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        label {
          width: 100%;
        }
        input {
          width: 47%;
        }
      }

      .group {
        display: flex;
        justify-content: flex-start;

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          text-align: center;
          color: #0b455f;
          margin-right: 2rem;
          padding: 10px 20px;
          cursor: pointer;

          &.active {
            background: #2a7394;
            border-radius: 99px;
            color: #fff;
          }
        }
      }

      &.buttonGroup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        label {
          width: 100%;
        }

        button {
          width: 45%;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 0px;
          border: 3px solid #0b455f;
          background-color: #fff;
          color: #023247;
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 18px;
          line-height: 24px;
          text-align: center;

          svg {
            color: #023247;
            margin-bottom: 1rem;
          }
        }

        button.active,
        button:hover {
          background: #0b455f;
          border: 3px solid #0b455f;
          color: #fff;

          svg {
            color: #fff;
            margin-bottom: 1rem;
          }
        }
      }

      &.info {
        .reel {
          display: flex;
          width: 100%;
          margin-bottom: 1.5rem;
          align-items: center;
          .icon {
            width: 40px;
            height: 40px;
            background-color: #ececec;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
          }

          input {
            margin-left: auto;
            width: 20px;
          }

          .text {
            margin-left: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            h4 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              margin: 0;
              padding: 0;
              width: 100%;
              line-height: 1.2em;
            }
            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              margin: 0;
              padding: 0;
              line-height: 1.5em;
            }
          }
        }
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          margin-top: 10px;
          font-size: 13px;
          line-height: 1.5em;
          margin-top: 10px;
        }
      }
      input,
      textarea {
        background-color: #fff !important;
        padding: 10px 15px;
        margin-bottom: 0rem;
        border: 2px solid #dadada;
        border-radius: 6px;

        &:focus {
          border: 2px solid #0d8cc2;
        }
      }
      label {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &.flex {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;

        label,
        input {
          display: inline-block;
          margin: 0;
          padding: 0;
        }

        label {
          font-size: 1rem;
        }

        input {
          width: 2em !important;
          height: 2em !important;
          background-color: #fff !important;
        }
      }

      input[type="checkbox"] {
        padding: 0;
        width: 20px;
        height: 20px;
      }

      select {
        background: #dcf4ff;
        border: 1px solid #dcf4ff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        padding: 10px 20px;
        font-size: 14px;
        background-color: #ececec;
        border: 1px solid #58c5f3;
        color: #000;
      }
    }
  }
}
