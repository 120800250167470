.TextStory {
  margin: 0px auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 6px 6px;
  border: 1px solid #aaaaaa;
  background: #ffffff;
  border: 0.653448px solid #f4eeec;
  box-shadow: 0px 2.61379px 9.14828px rgba(232, 221, 218, 0.36);
  border-radius: 24px;

  .awaiting {
    position: absolute;
    top:15px;
    left:15px;
    color:#fff;
    font-size:12px;
    z-index:300;
    background-color: rgba(0,0,0,0.5);
    padding:6px;
    border-radius: 6px;
    p {
      margin:0;
      padding:0;
      font-weight: 600;
    }
  }

  .hide {
    position: absolute;
    top:15px;
    left:15px;
    background-color: rgba(0,0,0,0.5);
    padding:5px 9px;
    border-radius: 100%;
    color:#fff;
    font-size:12px;
    z-index:300;
    cursor: pointer;

    p {
      font-size:12px;
    }

    &:hover {
      background-color: rgba(0,0,0,0.8);
    }
  }
  .actionBox {
    border-top: 2px solid #d9d9d9;
    width: calc(100% - 20px);
    margin-left: 10px;
    cursor: pointer;
    .responses {
      display: inline-block;
      background: #ffe6f1;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px 12px;
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 15px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #ff67a7;
      background: rgba(255, 158, 103, 0.2);
      border-radius: 7.84138px;

      svg {
        color: #0b455f;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 9.80172px;
        line-height: 140%;
        text-align: center;
        color: #0b455f;
      }

      @media (max-width: 768px) {
        span {
          display: none;
        }

        svg {
          margin-right: 0 !important;
          padding-right: 0;
        }
      }

      svg {
        margin-right: 5px;
      }

      &:hover {
        background: darken(rgba(255, 158, 103, 0.2), 30);
        color: #fff;
      }
    }
  }

  &.blue {
    .image {
      background-image: url("../../images/card-blue.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-color: #0b455f !important;
      background-size: 40px;
      color: #fff;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 140%;
    }
  }

  &.white {
    .image {
      background-image: url("../../images/card-white.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 40px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 140%;
      color: #000 !important;

      p {
        color:#000;
      }
    }
  }

  &.yellow {
    .image {
      background-image: url("../../images/card-yellow.png");
      background-repeat: no-repeat;
      background-position: top left;
      background-color: #fede66;
      background-size: 40px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 140%;
      color:#000;

      p {
        color:#000;
      }
    }
  }

  &.orange {
    .image {
      background-image: url("../../images/card-orange.png");
      background-repeat: no-repeat;
      background-position: bottom left;
      background-color: #ff9e67;
      background-size: 40px;
      color: #0b455f;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 140%;
    }
  }

  .notSeen {
    position: absolute;
    left: 0%;
    top: 1rem;
    right: 0%;
    bottom: auto;
    z-index: 100;
    display: flex;
    width: auto;
    height: 3rem;
    max-width: none;
    min-width: auto;
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 4px 12px 1px rgb(0 0 0 / 25%);
    font-family: Inter, sans-serif;
    font-size: 0.625rem;
    line-height: 0.75rem;
    font-weight: 500;

    span {
      cursor: pointer;
      color: white;
      border-style: solid;
      border-width: 1px;
      border-color: #ff67a7;
      background-color: #ff67a7;
      transition: all 300ms ease-in-out;
      text-decoration: none;
      padding: 5px 10px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 0.563rem 0.75rem;
      justify-content: space-between;
      align-items: center;
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      border-radius: 6px;
      font-family: Inter, sans-serif;
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-weight: 800;
    }
  }

  .response {
    position: absolute;
    top: 50px;
    left: 10px;
    svg {
      color: black;
      font-size: 15px;
      padding: 10px;
      background-color: white;
      width: 1em;
      height: 1em;
      border-radius: 100%;
    }
  }

  p {
    line-height: 1.4em;
    font-size: 15px;
    text-align: initial;
  }

  @media (max-width: 768px) {
    width: 95%;
  }

  .container {
    padding: 20px;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  @media (max-width: 768px) {
    border-radius: 0px;
  }

  .textOverlay {
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 202;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    p {
      color: #000;
      font-size: 15px;
    }

    .navbar {
      width: 100%;
      background-color: #c695f6;
      height: 80px;
      z-index: 300;
      position: relative;

      .inside {
        max-width: 600px;
        margin: 0px auto;
        display: flex;
        justify-content: flex-start;
        color: #fff;
        align-items: center;
        padding-top: 0px;
        height: 80px;
        padding: 20px;
        width: 100%;

        .information {
          display: flex;
        }

        svg {
          margin-right: 20px;
          font-size: 30px;
          justify-self: flex-start;
        }
        p {
          color: #fff;
          display: inline;
          text-align: left;
          width: initial;
          margin: 0;
          padding: 0;
        }
        img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 10px;
        }
      }
    }

    .videoContainer {
      z-index: 202;
      width: auto !important;
      max-width: 850px;
      display: flex;
      padding-top: 10px;
      padding: 50px 100px;
      padding-top: 20px;
      padding-bottom: 100px;
      margin: 0px auto;
      width: 100% !important;

      p,
      span {
        text-align: inherit;
        margin: 10px 0px;
        font-size: 15px;
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100% !important;
        padding: 10px 20px;
        margin-right: 0;
        max-width: 100%;
        padding-bottom: 100px;
      }

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        font-size: 30px;
        width: 30px;
        height: 30px;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        color: #000;
        z-index: 200;
        opacity: 80%;

        &:hover {
          cursor: pointer;
          opacity: 100%;
          background-color: rgba(255, 255, 255, 1);
        }
      }

      video {
        width: 100%;
        max-height: 100%;
      }
    }
  }

  .info {
    padding: 1rem;
    width: 100%;
    color: #fff;
    left: 0;
    display: flex;
    align-items: center;
    margin-top: 0px;

    &.empty {
      p {
        width: 100%;
      }
    }

    img {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 100%;
      margin: 0;
      padding: 0;
      margin-right: 0px;
      border: 2px solid #fff;
      object-fit: cover;
      margin-left: -5px;
      margin-right: 0.625rem;
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      width: calc(100% - 55px);
      margin-right: 9px;
      color: #000;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 15px;
      /* identical to box height, or 100% */

      color: #000000;

      span {
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        margin-top: 5px;
        display: block;
        line-height: 14px;
        /* identical to box height, or 117% */

        font-family: Inter, sans-serif;
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-weight: 300;
      }
    }
  }

  .image {
    position: absolute;
    top: 0%;
    left: 0;
    bottom: 0;
    right: 0;
    color: #58c5f3;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 6px 6px 0px 0px;
    padding: 0px;
    margin: 0px;
    background-color: #58c5f3;
    overflow: hidden;
    width: 100%;
    text-align: center;
    background: linear-gradient(135deg, #e8d07a 0%, #5312d6 100%);
    border-radius: 24px 24px 0px 0px;
    padding: 10px;
    padding-top:40px;

    .icon {
      svg {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        border-radius: 100%;
        font-size: 32px !important;
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 140%;
      color: #ffffff;
    }

    .icon {
      position: absolute;
      position: absolute;
      padding: 8px 14px;
      border-radius: 6px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-direction: row;

      svg {
        position: relative;
        font-size: 64px;
        transform: initial;
        left: initial;
        top: initial;
        margin-right: 10px;
        color: #dcf4ff;
      }

      p {
        padding: 0;
        margin: 0;
        color: #dcf4ff;
        font-size: 15px;
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @media (max-width: 768px) {
      border-radius: 0px;
    }

    &:hover {
      cursor: pointer;

      svg {
        opacity: 1;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 50px;
    }
  }
}
