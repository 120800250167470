.chatContainer {
  padding: 0px 0px;
  padding-top: 0;
  width: 100%;
  padding-bottom: 0;
  height: 100vh;
  overflow: scroll;
  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  img {
    max-width: 150px;
    margin-top: 50px;
  }


  .chatBox {
    min-width: 300px;
    width: 100%;
    margin: 0px auto;
    min-height: 500px;
    border-radius: 6px;
    margin-top: 0px;
    overflow: scroll;
    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
    padding-bottom: 0px;

    .chatsContainer {
      max-height: calc(100vh - 100px);
      overflow: scroll;
      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
      padding-bottom: 20px;

      > div:last-child {
        padding-bottom:60px;
      }

    }

    img {
      margin-top: 0;
    }

    .title {
      background-color: #fff;
      margin-bottom: 1rem;
      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.02em;
        padding: 20px 20px;
        padding-bottom: 20px;
      }
    }
  }
}
