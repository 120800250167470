@import "../../theme.scss";

.couponsAdmin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 40px;
  position: relative;
  margin-top: 30px;

  h2 {
    width: 100%;
    display: inline-block;
  }

  .add {
    .input {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 3rem;
      label {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      input,
      select {
        width: 100%;
      }
    }

    textarea {
      width: 100%;
    }
  }

  .right {
    width: 70%;
    padding: 20px;
    background-color: white;
    margin-top: 20px;

    .link {
      margin-left: auto;
      margin-bottom: 2em;

      background-color: transparent;
      border: none;
      color: red;
      padding: 0;

      a {
        color: #ff67a7;
        font-size: 1.4em;
      }
    }

    .coupon {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      flex-wrap: wrap;

      .people {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: 0px 40px;
        padding-left: 15px;
        margin: 0px auto;

        .person {
          display: flex;
          width: 100%;
          justify-content: space-between;

          p {
            width: 50%;
            text-align: left;

            &:first-child {
              width: 200px;
            }

            svg {
              opacity: 0.5;
              margin-right: 0.5em;
            }
          }
        }

        > h3 {
          font-size: 15px;
          opacity: 0.5;
          width: 100%;
          margin-bottom: 1em;
          text-transform: uppercase;
        }
      }

      &:nth-child(odd) {
        background-color: #f5f5f5;
      }

      p,
      button {
        width: 20%;
        text-align: left;
        padding-left: 10px;
      }

      button {
        width: 100px;
      }

      &.top {
        margin-bottom: 0px;
        border-bottom: 2px solid #ececec;
        p {
          font-weight: 600;

          &:last-child {
            width: 100px;
          }
        }
      }
    }
  }
}
