@import "../../theme.scss";

.create {
  max-width:90rem;
  padding:0px 40px;
  margin: 0 auto;
  margin-top: 0px;
  width: 100%;
  padding-bottom: 60px;
  min-height: calc(100vh - 200px);
  padding-top:0;


  .continueBox {
    margin-top: 40px;
    display:flex;
    justify-content: space-between;
    width: 100%;

    .btn.primary {
      justify-self: flex-end;
      width:200px !important;
      align-self: end;
      margin-left:auto;
    }

    .btn.secondary {
      width:150px;
      background-color: transparent;
      color:$pink;
      font-family: Inter, sans-serif;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 900;
      letter-spacing: 0.5px;

      &:hover {
        border-color: #bc0e57;
        background-color: #ffe6f1;
      }
    }



    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 137% */

      color: #000000;
    }
    button {
      width: 100%;
    }
  }

  

  .white-background {
    background-color: white;
    padding: 40px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    border: 0.5px solid #aaaaaa;

    ol {
      padding: 0;
      margin: 0;
      list-style: decimal;

      li {
        display: flex;
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 200px;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 50px;
    border-radius: 15px;
    border: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    justify-content: center;

    .modal__buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-top: 20px;
    }

    p {
      font-size: 15px;
      color: #000;
      text-align: center;
    }

    h3 {
      font-size: 22px;
      margin-bottom: 10px;
      font-family: "Inter", sans-serif;
      color: #000;
      margin-top: 0;
    }
  }

  .error {
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    background-color: #ed8777;
    padding: 10px;
    border-radius: 6px;
  }

  .progress {
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    z-index: 200;

    .container {
      max-width: 600px;
      display: flex;
      margin: 0 auto;
      width: 100%;
      align-items: center;

      .bars {
        width: 100%;
        align-items: center;
        display: flex;
        .bar {
          width: 25%;
          height: 5px;
          margin-right: 20px;
          background-color: white;

          &.active {
            background: black;
          }
        }
      }

      svg {
        color: #fff;
        font-size: 20px;
        margin-right: 20px;
      }
    }
  }

  &.email {
    form {
      input,
      textarea {
        background-color: #fff !important;
        color: #000 !important;
      }

      textarea {
        height: 150px;
      }
    }
  }

  &.success {
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 28px;
      /* identical to box height, or 78% */

      text-align: center;

      color: #ffffff;
    }
  }

  &.preview {
    color: #fff;
    max-width: 350px;
    margin: 0px auto;

    p {
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      /* identical to box height, or 200% */
      text-align: center;
      color: #ffffff;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 34.24px;
      line-height: 52px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      margin-top: 60px;
      color: #ffffff;
    }

    button {
      margin: 0px auto;
      margin-top: 30px;
    }
  }

  form {
    margin-top: 50px;
    .form-group {
      display: flex;
      justify-content: space-between;

      &.non-flex {
        display: block;
        margin-top: 40px;
      }

      > div {
        width: 48%;
      }

      label {
        width: 100%;
        display: block;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: #fff !important;
        color: #676c7c;
        margin-bottom: 5px;
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 15px;
        background: transparent;
        border: 1.17934px solid #e5e8f0;
        border-radius: 4.71736px;
        color: #fff;
        font-weight: 500;
        font-size: 12.9978px;
        line-height: 15px;
      }
    }

    button {
      margin-left: auto;
      background-color: #fff;
    }
  }

  .previewBox {
    position: relative;
    svg {
      position: absolute;
      font-size: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 30px;
      width: 30px;
      height: 30px;
      padding: 15px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      color: #000;
      z-index: 200;
      opacity: 80%;
    }
  }

  .boxContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 350px;
    margin: 0px auto;
    margin-top: 50px;

    .box {
      height: 130px;
      margin-top: 0;
      background-color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      svg {
        margin: 0px auto;
        font-size: 41px;
        padding-top: 15px;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 19.957px;
        line-height: 21px;
      }
    }
  }

  iframe {
    height: calc(100% - 150px);
  }

  a {
    text-align: center;
    display: block;
    margin-top: 50px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15.9787px;
    line-height: 25px;
    text-align: center;
    color: #fff;
  }
}

.video {
  iframe {
    height: 100% !important;
    border: none;
  }
}
