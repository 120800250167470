.OnboardedValueFlowAdd {
  border-radius: 10px;
  border: 1px dashed var(--hero-blue-0-b-455-f, #0b455f);
  padding: 10px 20px;
  width: 90%;
  margin-left: 5%;
  display: flex;
  margin-bottom: 2rem;

  input {
    border: none;
    box-shadow: none;
    padding: 0;
    color: var(--hero-blue-0-b-455-f, #0b455f);
    /* Heading 6 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;



    button.blank {
      margin-right: 0 !important;
      margin-left: 0px !important;

      &.cancel {
        background-color: red;
        opacity: 0.5;
      }
    }
  }
  .value__image {
    width: 100%;

    &:active,
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  &.notAdding {
    &:hover {
      background-color: var(--e-26-c-29, #e26c29);
      cursor: pointer;

      button {
        color: #fff;
        cursor: pointer;
      }
    }
    button {
      background-color: transparent;
      border: none;
      width: 100%;
      text-align: left;
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
    }
  }

  &.editing {
    border-radius: 10px;
    background: #f4eeec;
    border: none;

    .actionBox p {
      margin-top: 0;
      margin: 0;
    }

    .actionBox {
      .box {
        justify-content: space-between;
      }
    }

    .pill {
      margin-top: 10px !important;
      display: flex;
      align-items: center;
    }
  }

  h3 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    /* Heading 6 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .actionBox {
    display: flex;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    button {
      margin-left: 0 !important;
      width:47%;

      &:disabled {
        background-color: #3485aa;
        opacity: 0.2;
        border: none;
      }
    }

    .box {
      display: flex;
      width: 100%;

      button {
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 0;
      }
    }

    &.row {
      flex-direction: row;
    }

    textarea {
      border-radius: 5px;
      border: 1px solid #3485aa;
      opacity: 0.5;
      background-color: transparent;

      &:active, &:focus, &:hover {
        opacity: 1;
      }
    }

    .save {
      margin-left: 0 !important;
      margin-top: 15px;
    }

    .pill {
      border-radius: 50px;
      background: rgba(52, 133, 170, 0.2);
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      display: flex;
      padding: 10px 15px;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      margin-top: 15px;

      p {
        padding: 0;
        margin: 0;
      }
    }

    button {
      border-radius: 12px;
      background: var(--hero-blue-0-b-455-f, #0b455f);
      border: none;
      color: #fff;
    }
  }
}
