.contextMenu {
  position: absolute;
  top: 25px;
  right: 35px;
  z-index: 560;
  cursor: pointer;
  .icon {
    position: absolute;
  }

  .menu {
    position: absolute;
    top: 20px;
    right: -10px;
    width: 200px;
    background-color: #fff;
    border-radius: 4px;
    padding: 0px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    > div {
      border-bottom: 2px solid #ececec;
      padding: 10px 20px;
      font-size:14px;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #ececec;
      }
    }
  }
}
