.storyModal {
  position: fixed;
  top:0;
  height: 100%;
  width: 100%;
  left:0;
  .skrim {
    background: #013247;
    opacity: 0.67;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    max-width: 900px;
    max-height: 90vh;
    background-color: white;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 50px;
    border-radius: 15px;
    border: none;
    background: #ffffff;
    border-radius: 24px;
    overflow: scroll;
    border: 0px;
    flex-wrap: wrap;
    height: 80%;

    .right {
      width: 48%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;

      @media (max-width: 768px) {
        width: 100%;
      }

      .replies {
        height: calc(100% - 120px);
        overflow: scroll;
      }

      .replyForm {
        margin-top: auto;
        margin-right: 15px;

        button {
          position: absolute;
          right: 35px;
          bottom: 30px;
          background-color: transparent;
          border: none;
          font-size: 18px;
          color: #ff9e67;
        }
        textarea {
          background-color: white;
          width: 100%;
          font-size: 14px;
          border: none;
          border: 2px solid #ececec;
        }
      }

      .replyContainer {
        margin-top: 1rem;
        display: flex;
        color: #000;
        overflow: scroll;

        .reply {
          display: flex;
          text-align: left;
          align-items: flex-start;
          flex-direction: column;
          margin-bottom: 1rem;
          width: 100%;

          .date {
            opacity: 0.5;
            display: inline-block;
            margin-left: auto;
          }

          h3 {
            margin: 0;
            padding: 0;
            text-align: left;
            width: 100%;
            display: inline-block;
            display: flex;
          }

          p {
            text-align: left;
            margin: 0;
            padding: 0;
            margin-top: 10px;
          }
        }
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 140%;
          color: #0b455f;
          opacity: 0.6;
        }
      }
      .header {
        display: flex;
        align-items: center;
        margin-top: 0;

        h5 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 140%;
          color: #0b455f;
        }
      }
    }

    .left {
      width: 50%;
      height: 100%;
      margin-top: -1px;
      border-right: 1px solid rgba(1, 50, 71, 0.12);
      @media (max-width: 768px) {
        width: 100%;
      }
      display: flex;
      flex-direction: column;

      .info {
        display: flex;
        padding: 20px;
        margin-top: auto;

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 13.069px;
          line-height: 140%;
          color: #0b455f;
        }

        h5 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 9.80172px;
          line-height: 140%;
          color: #0b455f;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          margin-right: 1rem;
        }
      }
      .story {
        margin-top: 0px;
        height: calc(100% - 100px);

        &.blue {
          background-image: url("../../../../images/card-blue.png");
          background-repeat: no-repeat;
          background-position: top right;
          background-color: #0b455f;
          background-size: 40px;
          color: #fff;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          overflow: scroll;
          padding: 40px;
        }

        &.white {
          background-image: url("../../../../images/card-white.png");
          background-repeat: no-repeat;
          background-position: top right;
          background-size: 40px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          color: #000 !important;
          padding: 40px;

          p {
            color: #000;
          }
        }

        &.yellow {
          background-image: url("../../../../images/card-yellow.png");
          background-repeat: no-repeat;
          background-position: top left;
          background-color: #fede66;
          background-size: 40px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          color: #000;
          padding: 40px;

          p {
            color: #000;
          }
        }

        &.orange {
          background-image: url("../../../../images/card-orange.png");
          background-repeat: no-repeat;
          background-position: bottom left;
          background-color: #ff9e67;
          background-size: 40px;
          color: #0b455f;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          padding: 40px;
        }
      }

      video {
        width: 100%;
      }
    }
  }
}
