.CompanyIntro {
  height: 100vh;
  width: 100%;
  background: var(--hero-tan-f-4-eeec, #f4eeec);
  .bar {
    width: 100%;
    height: 30px;
    background-color: #fff;
    height: 6px;
    flex-shrink: 0;
    margin-top: 0px;
    padding: 0px 0px;
    position: absolute;
    width: calc(100%);
    margin-left: 0px;

    .stepBar {
      height: 6px;
      border-radius: 15px;

      background-color: #ff9e67;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom:20px;
    left:calc(50% - 100px);
    span,
    img {
      margin: 0;
      padding: 0;
      color: var(--hero-blue-0-b-455-f, #0b455f);
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 163.5%; /* 29.43px */
    }

    img {
      margin-left: 1rem;
      height: 30px;
    }
  }



  .photo .userInfoBox {
    fill: var(
      --155673,
      linear-gradient(0deg, #155673 0%, #155673 100%),
      #ecddd8
    );
    display: flex;
    border-radius: 10px;
    background: #2a7394;
    padding: 20px 30px;
    align-items: center;
    margin: 0px auto;
    margin-top: 2rem;
    max-width: 700px;
    background-size: cover;
    background-position: 400px 0px;
    background-repeat: no-repeat;

    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    h2 {
      color: var(--white-ffffff, #fff);
      text-align: left;
      font-family: "Inter";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 68.2px */
    }

    h3 {
      color: #d9d9d9;
      /* Heading 3 */
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 52.8px */
      margin-top: 0.5rem;
    }

    h4 {
      border-radius: 10px;
      background: var(--hero-orange-ff-9-e-67, #ff9e67);
      display: block;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: auto;
      font-size: 13px;
      margin-top: 1rem;
      color: #fff !important;
    }

    .left {
      img {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .right {
      text-align: left;
      margin-left: 3rem;
      color: #fff;
    }
  }

  section {
    .controlsItem {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:3rem;

        button {
            height: 3rem;
            border-radius: 10px;
            margin-left:1rem;
            margin-right:1rem;
            box-shadow: none;

            &.blank {
                background: transparent;
                color: #0b455f;
                border: 1px solid #0b455f;
                box-shadow: none;
            }

            &:disabled {
                opacity: .5;
            }
        }
      }
  }

  .slide1 {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sides {
      display: flex;
      align-items: center;
    }

    .left,
    .right {
      padding: 0px 50px;
    }
  }

  .footer {
    position: fixed;
    bottom: 20px;
    margin: 0px auto;
    left: calc(50% - 150px);
  }

  .departments {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    .department {
      border-radius: 10px;
      background: #d9d9d9;
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--hero-blue-0-b-455-f, #0b455f);
      text-align: center;
      /* Heading 5 */
      font-family: Inter;
      font-size: 1em;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      border: none;
      margin-bottom: 1rem;
      margin-right: 1rem;
      cursor: pointer;

      &:hover,
      &.active,
      &:active,
      &:focus {
        background: darken(#d9d9d9, 25);
        color: #fff;
        outline: none;
        border: none;
      }
    }
  }

  .photo {
    h3 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      text-align: center;
      /* Heading 4 */
      font-family: Inter;
      font-size: 1.5em;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }
    h2 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      text-align: center;
      /* Heading 1 */
      font-family: Inter;
      font-size: 2.8em;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 68.2px */
      margin-top: 0.5rem;
    }
  }

  input[type="text"] {
    display: flex;
    width: 881px;
    padding: 30px 40px;
    align-items: center;
    gap: 10px;
    color: #d9d9d9;
    /* Heading 1 */
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 68.2px */
    box-shadow: none;
    text-align: center;
    margin: 0px auto;
    margin-top: 2rem;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    background: #fff;

    &:active,
    &:focus {
      outline: none;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .inputBox {
    border-radius: 378px;
    border: 5px dashed #d9d9d9;
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    margin-top: 2rem;
    position: relative;
    background-size: cover;
    background-position: center;

    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }

    svg {
      height: 62px;
      width: 62px;
      color: #d9d9d9;
    }
  }

  h2 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    text-align: center;
    /* Heading 1 */
    font-family: "Inter";
    font-size: 62px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 68.2px */
  }

  h3 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 163.5%; /* 78.48px */
  }
  p {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    text-align: center;
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 123.5%; /* 58.86px */
  }
}
