@import "../../theme";

.strengthsOverview {
  padding-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 80px;
  margin-top: -15px;
  padding: 50px 50px;
  padding-top: 25px;
  background-color: transparent;
  padding-bottom: 0;
  background-repeat: repeat;
  background-position: left center;
  background-size: 20px 20px;
  min-height: 100vh;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;
  padding-bottom: 90px;

  .presenterView {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .skrim {
      background: #f4eeec;
      opacity: 1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 20px 40px;
      padding-bottom: 50px;
      border-radius: 5px;
      width: 80%;
      max-width: 1000px;
      overflow: scroll;
      z-index: 20;
      background: #ffffff;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      .top {
        align-items: center;
        opacity: 1;
        h3 {
          color: #013247;
          opacity: 1;
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 38.4px */
          margin-bottom: 2rem;
          margin: 0;
          padding: 0;
        }

        button {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 200px;
          border-radius: 12px;
          background: rgba(52, 133, 170, 0.2);
          color: var(--hero-blue-0-b-455-f, #0b455f);
          text-align: center;
          border: none;
          font-family: "Inter";
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */

          &:hover {
            cursor: pointer;
            background: darken(rgba(52, 133, 170, 0.2), 20);
          }
        }
      }

      .strength {
        border-radius: 6px;
        border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
        background: #fff;
        padding: 18px 328px 15px 19px;
        margin-bottom: 1rem;

        span {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 26px */
        }

        span:first-child {
          color: #013247;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 120% */
          margin-right: 1rem;
        }
      }

      .close {
        position: absolute;
        top: 20px;
        right: 40px;
        font-size: 30px;
        opacity: 0.3;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .left {
        width: 40%;
        border: none;
        @media (max-width: 768px) {
          width: 100%;
        }

        .box {
          background-color: rgba(255, 158, 103, 0.1);
          padding: 90px;
          display: flex;
          border-radius: 24px;

          @media (max-width: 768px) {
            padding: 20px;
          }
          svg {
            margin: 0px auto;
          }
        }
      }

      .right {
        text-align: left;
        width: 60%;
        @media (max-width: 768px) {
          width: 100%;
        }
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          color: #ff9e67;
          padding: 0;
          text-align: left;
        }

        .number {
          background-color: #013247;
          width: 50px;
          height: 44px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }

        > h2 {
          margin-bottom: 0;
        }

        > p {
          margin-top: 0;
          padding-top: 0;
        }

        button {
          margin-left: auto;
        }

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 140%;
          margin-top: 2rem;
          color: #013247;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          /* or 20px */

          color: #013247;

          opacity: 0.6;
        }
      }

      .row {
        display: block;
      }

      .inputBox {
        margin-bottom: 40px;
      }

      label,
      input,
      textarea,
      select {
        width: 100%;
        text-align: left;
      }
    }
  }

  &.partner {
    .top {
      padding-left: 0px;
    }
    .insights,
    .collaborators,
    .topfive {
      border-radius: 20px;
      background: #fff;
      padding: 40px 40px;
      width: 100%;

      .insightsContainer {
        display: flex;
        margin-top: 15px;
        align-items: flex-start;
        gap: 30px;
        .insight {
          width: 20%;
          border-radius: 10px;
          background: linear-gradient(0deg, #ebf3f7 0%, #ebf3f7 100%), #ecddd8;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-self: stretch;

          &.add {
            background: transparent !important;
            border-radius: 10px;
            border: 1px dashed var(--3485-aa, #3485aa);
            margin-top: 0;

            h4 {
              margin-bottom: 2rem;
            }
          }

          .blank {
            border-radius: 8px;
            background: #ebf3f7 !important;
            display: flex;
            padding: 8px 15px;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            color: #155673;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 14.4px */
            margin-top: auto;
            justify-self: flex-start;
            border: none;
            align-self: flex-start;
          }

          svg {
            border-radius: 8px;
            background: linear-gradient(0deg, #c6dde8 0%, #c6dde8 100%), #ecddd8;
            padding: 5px;
          }

          h4 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 16.8px */
            margin-top: 1rem;
          }

          h5 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
            margin-top: 1rem;
          }
        }
      }

      .blue {
        border-radius: 8px;
        background: #ebf3f7;
        padding: 20px;
        margin-top: 2rem;

        .top {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          h4 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 15.4px */
            margin-left: 15px;
          }
        }

        .buttons {
          display: flex;
          gap: 20px;

          .btn {
            font-size: 15px;
            border-radius: 10px;
            font-weight: 600;
          }
        }

        .input {
          h4 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
          }

          textarea {
            width: 100%;
            background-color: #fff;
            margin-top: 1rem;
            border: none;
            margin-bottom: 1rem;
          }
        }
      }

      h3 {
        color: #013247;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 38.4px */
      }
    }

    .insights {
      margin-top: 3rem;
    }

    .collaborators {
      .top {
        opacity: 1;
        margin-top: 0;
        align-items: center;
        margin-bottom: 2rem;

        button {
          box-shadow: none;
        }

        h3 {
          opacity: 1;
        }
      }
      .nonResponded {
        margin-bottom: 1rem;
        padding: 40px 0px;
        h5 {
          color: #013247;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
          margin-bottom: 1.4rem;
        }

        .invite {
          display: flex;
          align-items: center;
          margin-bottom: 0rem;
          gap: 30px;

          button:disabled {
            pointer-events: none;
            background-color: transparent;
            height: 42px;
            padding: 14px 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 7px;
            border: 1px solid var(--hero-blue-0-b-455-f, #0b455f);
            font-size: 16px;
            font-weight: 600;

            &:hover {
              cursor: not-allowed;
            }
          }

          button,
          .primary.btn {
            display: flex;
            height: 42px;
            padding: 14px 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 7px;
            border: 1px solid var(--hero-blue-0-b-455-f, #0b455f);
            font-size: 16px;
            font-weight: 600;
          }

          .blank {
            &:hover {
              background-color: var(--hero-blue-0-b-455-f, #0b455f);
            }
          }

          .primary.btn {
            border: none;
            box-shadow: none;
          }

          span {
            color: #000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px; /* 85.714% */
          }

          span:nth-child(2) {
            width: 15%;
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 28px */
          }

          span:nth-child(3) {
            margin-right: 70px;
          }

          .actions {
            margin-left: auto;
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-right: 0;
          }
        }
      }

      .nextSteps {
        border-radius: 8px;
        background: #fff2eb;
        padding: 20px;
        margin-top: 1rem;

        h5 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
          margin-bottom: 1rem;
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          margin: 0;
          padding: 0;
        }
      }
    }

    .topfive {
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-self: flex-start;

      .sub {
        color: var(--hero-blue-0-b-455-f, #0b455f);

        /* Heading 6 */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
      .top {
        display: flex;
        padding-right: 0;

        .buttons {
          margin-left: auto;
          display: flex;
          gap: 30px;

          .btn {
            border-radius: 10px;
            background: #ebf3f7;
            display: flex;
            padding: 15px 20px;
            align-items: center;
            gap: 10px;
            color: var(--hero-blue-0-b-455-f, #0b455f);
            text-align: center;
            border: none;
            /* Button Body */
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
            cursor: pointer;

            &:hover {
              background: darken(#ebf3f7, 30);
            }
          }
        }
        h3 {
          color: #013247;
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 38.4px */
          opacity: 1;
        }
      }

      .strengths {
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin-top: 30px;
        background-color: transparent;
        min-height: auto;

        &.sidebyside {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1rem;

          .left,
          .right {
            width: 45%;
            padding: 0;
            border: none;
          }
        }
        .strength {
          border-radius: 6px;
          border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
          background: var(--gray100, #fafafa);
          padding: 13px 20px;
          margin-bottom: 1rem;

          span {
            color: var(--c-3988-a, #c3988a);
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 120% */
          }

          span:nth-child(2) {
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 21px */
            margin-left: 20px;
          }
        }
      }

      .laststeps {
        border-radius: 8px;
        background: #fff2eb;
        padding: 20px;

        h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
          margin-bottom: 1rem;
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
        }

        button {
          box-shadow: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  .strengthInviteModal {
    z-index: 999999 !important;
  }

  .top {
    padding-left: 40px;
    width: 100%;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.4rem;
    margin-bottom: 1rem;

    button {
      margin-left: auto;
    }
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #013247;
      opacity: 0.6;
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 140%;
      color: #013247;
      text-decoration: none;
      border: none;

      &:after {
        background: transparent;
        display: none;
      }
    }
  }

  .invites {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .top {
      padding: 0;
      margin-bottom: 1em;
      align-items: flex-start;

      button {
        width: 240px;
      }
    }
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 140%;
      color: #013247;
      text-decoration: none;
      border: none;
      width: 100%;
      margin-bottom: 2rem;
      &:after {
        background: transparent;
        display: none;
      }
    }
    .invite {
      width: 100%;
      border-radius: 24px;
      padding: 20px 0px;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;

        &.inviteProgramRow-modal {
          svg {
            margin-left: auto;
          }

          .modal-content {
            max-width: 900px;
            padding: 40px 30px;
            border-radius: 24px;

            .inputs {
              display: flex;
              justify-content: space-between;
              .left,
              .right {
                width: 45%;
              }

              .input {
                display: flex;
                border: 2px solid #ececec;
                margin-bottom: 1rem;
                align-items: center;
                border-radius: 6px;
                border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
                background: #fff;

                span {
                  margin: 0;
                  margin-left: 15px;
                  width: 2rem;
                  padding: 0;
                  color: var(--hero-blue-0-b-455-f, #0b455f);
                  font-family: Inter;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px; /* 120% */
                }

                input {
                  box-shadow: none;
                  border: none;
                  padding: 0;
                  margin: 0;
                }
              }
            }

            h3 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 19.689px; /* 82.038% */
            }

            p {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 18px */
              margin-top: 1rem;
            }
          }
        }

        .skrim {
          background: #013247;
          opacity: 0.67;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          cursor: pointer;
        }

        .modal-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #fff;
          padding: 20px 40px;
          padding-bottom: 50px;
          border-radius: 5px;
          width: 80%;
          max-width: 900px;
          overflow: scroll;
          z-index: 20;
          background: #ffffff;
          border-radius: 24px;
          display: flex;
          flex-direction: column;

          > h2 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 120%;
            margin-top: 2rem;
            color: #013247;
            margin-bottom: 10px;
          }

          > h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
            color: #0b455f;
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;
            border-bottom: 1px solid #dadada;
            padding-bottom: 2rem;
            justify-content: space-between;
            .strength {
              width: 45%;
              margin-bottom: 1rem;
              display: flex;
              align-items: center;
              &:before {
                content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" style="fill:black"/></svg>');
                color: #fff;
                width: 1.3em;
                height: 1.3em;
                padding: 0.5em;
                display: block;
                border-radius: 100%;
                margin-right: 1rem;
              }
              span {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 20px;
                color: #013247;
              }
            }
          }

          > p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #013247;
            opacity: 0.6;
          }

          .comment {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            .left {
              width: auto;

              img {
                height: 30px;
                width: 30px;
                margin: 0;
                margin-right: 1rem;
              }
            }

            .right {
              h4,
              p {
                margin: 0;
                padding: 0;
              }

              p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 140%;
                color: #0b455f;
                opacity: 1;
              }

              h4 {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 140%;
                color: #0b455f;
                opacity: 0.6;
              }
            }
          }

          .header {
            display: flex;
            flex-direction: row;
            align-items: center;

            .left {
              width: auto;
            }

            .right {
              h3 {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 20px;
                color: #013247;
              }

              h4 {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
                /* identical to box height, or 98% */

                color: #013247;

                opacity: 0.6;
              }
            }

            img {
              width: 100px;
              height: 100px;
            }
          }

          .close {
            position: absolute;
            top: 20px;
            right: 40px;
            font-size: 30px;
            opacity: 0.3;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }

          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }

          .left {
            width: 40%;
            border: none;

            .box {
              background-color: rgba(255, 158, 103, 0.1);
              padding: 90px;
              display: flex;
              border-radius: 24px;
              svg {
                margin: 0px auto;
              }
            }
          }

          .right {
            text-align: left;
            width: 60%;
            margin-left: 30px;
            h3 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              text-align: center;
              color: #ff9e67;
              padding: 0;
              text-align: left;
            }

            button {
              margin-left: auto;
            }

            h2 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 32px;
              line-height: 140%;
              margin-top: 2rem;
              color: #013247;
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              /* or 20px */

              color: #013247;

              opacity: 0.6;
            }
          }

          .row {
            display: block;
          }

          .inputBox {
            margin-bottom: 40px;
          }

          label,
          input,
          textarea,
          select {
            width: 100%;
            text-align: left;
          }
        }
      }

      .resultsModal {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        background-color: #fff;
        padding: 20px;

        .strength {
          width: 33%;
          margin-bottom: 1rem;
        }
      }

      .success {
        width: 100%;
        background-color: #f89967;
        padding: 10px 40px;
        border-radius: 20px;
        margin-bottom: 1rem;
        color: #fff;
      }

      .actions {
        margin-left: auto;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 2rem;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #013247;
        margin-right: 2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;

        &.small {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 12px;
        }
      }

      .friendStrengths {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
      }
      .check {
        width: 48%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15.5583px;
        line-height: 26px;
        color: #000000;

        &:before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #2a7394;
          border-radius: 100%;
          margin-right: 0.5em;
        }
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 140%;
        color: #013247;
        margin-top: 1rem;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    padding: 40px;
    padding-top: 20px;
    justify-content: space-between;

    .card {
      width: 47%;
      background-color: #fff;
      padding: 20px 40px;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 24px;

      .bubble {
        fill: #88d47b;
      }

      .bubble-label {
        text-align: center;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 50%;
        will-change: left, top, height, width;
        font-size: 1rem !important;
      }

      &.row {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .insight {
        background: rgba(255, 158, 103, 0.13);
        border: 1px solid rgba(255, 158, 103, 0.53);
        border-radius: 24px;
        padding: 15px;
        margin-bottom: 1rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* or 20px */

        /* #013247 */

        color: #013247;
      }

      .left,
      .right {
        width: 45%;
        margin-top: 0rem;

        .strength {
          margin-bottom: 1rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 140%;
          color: #013247;
          span:first-child {
            background: #2a7394;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            color: #fff;
            display: inline-block;
            text-align: center;
            padding-top: 3px;
            margin-right: 0.5em;
          }
        }
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        margin-bottom: 1rem;
        color: #013247;
        width: 100%;
      }

      .chart__bubble {
        width: 100%;
        display: flex;
        position: relative;
        display: flex;
        user-select: none;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}
