.enterPages {
  display: flex;
  .sidebar {
    width: 400px;
    height: 100vh;
    @media (max-width: 1200px) {
      height: calc(100vh - 80px);
    }
    background-color: #fff;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .bottom {
      margin-top: auto;
      padding: 20px 20px;

      button {
        font-size: 15px;
        box-shadow: none;
        font-weight: 600;
        border-radius: 10px;

        &:disabled {
          opacity: 0.5;
        }
      }

      .blank {
        border-radius: 12px;
        background: #f0f2f5;
        border: none;
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        &:hover {
          background: darken(#f0f2f5, 10%);
        }
      }
    }

    .title {
      display: flex;
      padding: 15px;
      border-bottom: 2px solid #ececec;

      svg {
        margin-right: 1em;
        opacity: 0.7;
      }

      h3 {
        color: #013247;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
    }

    .empty {
      padding: 30px;
      text-align: left;

      h1 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      p {
        &:nth-child(3) {
          opacity: 0.4;
        }
      }
    }

    .inner {
      padding: 15px 25px;
      height: calc(100% - 100px);
      overflow: scroll;

      .prompt {
        border-radius: 9.535px;
        background: #eaf1f4;
        display: inline-flex;
        padding: 15px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        color: #0b455f;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
      }

      .inputBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        position: relative;

        .counter {
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: var(--3485-aa, #3485aa);
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .photoPlaceholder {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;

          input {
            width: 100%;
            height: 120%;
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          h4 {
            color: #013247;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 18px */
          }

          svg {
            border-radius: 100px;
            background: #ebf3f7;
            display: flex;
            padding: 15px;
            align-items: flex-start;
            gap: 10px;
            color: #0c455f;
            margin-right: 1rem;
            width: 25px;
            height: 25px;
          }

          img {
            width: 40px;
            border-radius: 100px;
            margin-right: 1rem;
            width: 40px;
            height: 40px;
            object-fit: cover;
          }

          span {
            color: #939191;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 21px */
            margin-left: auto;
          }
        }

        textarea {
          background-color: #fff;
          border-radius: 7px;
          border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
          background: var(--white-ffffff, #fff);
          min-height: 6em;
          box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 21px */
          padding: 10px 15px;
        }

        label {
          color: var(--0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 21px */
        }
      }
    }

    .subtitle {
      border-bottom: 2px solid #ececec;
      padding-bottom: 1rem;

      h4 {
        color: var(--0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
        margin-bottom: 0.5rem;
      }

      p {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        padding: 0;
        margin: 0;
      }
    }

    .prompts {
      text-align: left;

      .prompt {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #ececec;
        padding-bottom: 1rem;

        &:last-child {
          border-bottom: 0px;
        }

        .expanding {
          display: none;
          gap: 15px;
          flex-direction: column;

          .box {
            border-radius: 9.535px;
            background: #eaf1f4;
            display: flex;
            padding: 25px 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;

            &.yellow {
              background: #fff8e0;

              &.selected {
                background: darken(#fff8e0, 20%);
              }
            }

            &.red {
              background: #fff2eb;
              &.selected {
                background: darken(#fff2eb, 20%);
              }
            }

            &.selected {
              background: darken(#eaf1f4, 20%);
            }

            p {
              padding: 0;
              margin: 0;
              color: #0b455f;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
              word-wrap: break-word;
            }
          }
        }

        &.expanded {
          margin-bottom: 1rem;
          .expanding {
            display: flex;
          }
        }

        .title {
          text-align: left;
          display: flex;
          flex-direction: column;
          border: none;
          padding: 0;
          position: relative;

          span {
            color: var(--3485-aa, #3485aa);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
          }

          h3 {
            color: var(--013247, #013247);

            /* Button Body */
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
          }

          p {
            color: #0b455f;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
            margin-top: 10px;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  .right {
    width: calc(100% - 400px);
    padding: 40px;

    .slides {
      display: flex;
      gap: 1.5rem;

      .box {
        width: 33%;
        height: 600px;
        border-radius: 6.795px;
        background: #eaf1f4;
        border: 10px solid #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 6.795px;

        &.nonActive {
          opacity: 0.2;

          cursor: pointer;

          &:hover {
            opacity: 1;
          }
          max-width: 220px;
          max-height: 600px;
          overflow: hidden;
        }

        &.growth {
          background: #eaf1f4;

          span {
            background: var(--3485-aa, #3485aa);
          }
        }

        &.relationships {
          background: #fff8e0;

          span {
            background: var(
              --e-4-c-037,
              linear-gradient(0deg, #e4c037 0%, #e4c037 100%),
              #ecddd8
            );
          }
        }

        &.impact {
          background: #fff2eb;

          span {
            background: var(
              --f-48-a-4-e,
              linear-gradient(0deg, #f48a4e 0%, #f48a4e 100%),
              #ecddd8
            );
          }
        }

        img {
          max-height: 200px;
          width: auto;
          align-self: flex-start;
          margin: 0px auto;
          margin-top: auto;
        }

        h4 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 18px */
          opacity: 0.5;
        }

        h2 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 28.8px */
          opacity: 0.5;
          word-wrap: break-word;
        }

        span {
          display: flex;
          padding: 10px 15px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 5px;
          background: var(--3485-aa, #3485aa);
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 13.2px */
          width: auto;
          flex-grow: 0;
          flex-grow: 0;
          align-self: flex-start;
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 19.2px */
          opacity: 0.5;
          word-wrap: break-word;
        }
      }
    }
  }
}
