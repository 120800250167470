@import "../../../theme.scss";

.crews {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 40px;
  position: relative;
  margin-top: 30px;

  h2 {
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;
  }

  .modal {
    .modal-content {
      padding: 40px;
      h2 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 1em;
      }

      .boxes {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        .box {
          width: 30%;
          border-radius: 10px;
          background: var(--3485-aa, #3485aa);
          display: flex;
          padding: 25px 47px 152px 25px;
          flex-direction: column;
          align-items: center;

          h4 {
            color: var(--white-ffffff, #fff);

            /* Heading 6 */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
          }
        }
      }
    }
  }

  .crews {
    padding: 0;
  }

  #async-example {
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    padding: 10px;
    z-index: 55;

    .dropdown-item {
      margin-bottom: 15px;
      color: #000;
      padding: 10px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        border-radius: 100%;
      }
    }
  }

  .right {
    width: 70%;
    padding: 20px;
    background-color: white;
    margin-top: 20px;

    h3 {
      font-size: 2rem;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      flex-direction: row;
      width: 200px;
      align-items: flex-end;
      margin-left: 40px;
      justify-content: flex-end;

      a,
      button {
        box-shadow: none;
      }

      .edit {
        padding: 8px;
        margin: 0;
        width: auto;
        font-size: 16px;
        margin-left: 15px;

        svg {
          margin-right: 0;
        }
      }

      a {
        width: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        background-color: transparent;
        margin-top: 15px !important;
      }

      .info {
        width: auto !important;
      }
    }

    .row {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        padding: 5px 20px;
      }

      h3 {
        padding: 0;
        margin: 0;
      }
    }

    .program {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      margin-top: 15px;
      width: 100%;

      .title {
        p {
          margin: 0;
          padding: 0;
          margin-top: 15px;
          font-weight: 400;
        }

        .full-title {
          display: flex;

          .pill {
            font-size: 11px;
            padding: 0;
            margin: 0;
            margin-left: 15px;
            background-color: $green;
            color: #fff;
            padding: 1px 15px;
            border-radius: 20px;
            font-weight: 800;

            &.in-active {
              background-color: $green;
            }

            &.purple {
              background-color: red;
            }
          }
        }
      }

      h6 {
        margin: 0;
        padding: 0;
      }

      .crew {
        color: black;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ececec;
        padding: 15px 25px;
        border-radius: 10px;
        align-items: flex-start;
        cursor: pointer;

        button {
          margin-bottom: 15px;
        }

        &:hover {
          background-color: darken(#ececec, 10);
        }
        .info {
          background-color: $pink;
          color: #fff;
          padding: 6px 10px;
          border-radius: 10px;
          width: 150px;
          margin-left: 20px;
        }
      }

      svg {
        margin-right: 5px;
      }
    }

    h3 {
      margin-top: 0;
    }
  }
}
