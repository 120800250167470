.profile-dropdown {
  position: relative;
  border-top: 0.8px solid #016894;
  padding-top:1rem;
  &:hover {
    cursor: pointer;
  }

  .image {
    display:flex;
    align-items: center;

    p {
      padding:0;
      margin:0;
      font-size:1rem;
      font-weight: 800;
      color:#fff;
      margin-left:1rem;
    }
  }
  img {
    width: 40px;
    border-radius: 100%;
    border: 4px solid #016894;
    height:40px;
    object-fit: cover;
  }

  .skrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
  }

  .dropdown-content {
    z-index: 201;
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    color: #000;
    width: 200px;
    right: 0;
    top: 60px;
    box-shadow: 0px 0px 12px rgba(129, 129, 131, .5);
    align-items: center;
    display: flex;
    padding-top:10px;
    padding-bottom:10px;

    .dropdown-item {
        width:100%;
    }

    a {
      color: #000;
      width: 170px;
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter";
      font-style: normal;
      font-weight: 550;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.128px;
      color: #2c2e30;
      font-variation-settings: "slnt" 0;
      padding:10px 20px;
      margin-left:15px;
      margin-right:15px;

      &:hover {
        color: lighten(#000, 5);
        text-decoration: none;
        background: rgba(35, 55, 55, 0.05);
border-radius: 4px;
      }

      svg {
        margin-right: 10px;
        width: 1em;
        margin-left: 0px;
      }
    }
  }
}
