.CanvasDashboard {
  padding-bottom:40px;
  margin-bottom:40px;
  .center {
    max-width: 90rem;
    margin: 0px auto;
  }
  .bg {
    background: #2a7394;
    height: 300px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    z-index: 1;
    overflow: hidden;

    img {
      position: absolute;
      right: 0;

      &:nth-child(1) {
        z-index: 2;
        right: 0;
      }
    }

    h1 {
      padding-left: 40px;
      padding-top: 40px;
    }
  }

  .content {
    z-index: 6;
    position: relative;
    padding: 50px;

    h1 {
      color: #fff;
      color: var(--white-ffffff, #fff);
      font-family: "Inter";
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 52.8px */
    }

    p {
      color: var(--white-ffffff, #fff);
      font-family: "Inter";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
      margin-top: 15px;
    }

    .body {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }

      .left {
        width: 77%;

        @media (max-width: 1200px) {
          width: 100%;
        }

        .currentProgram {
          background-color: #fff;
          border-radius: 8px;
          background: #fff;
          /* Artifact Card */
          box-shadow: 0px 4px 24px 0px rgba(11, 39, 51, 0.05);
          display: flex;
          padding: 14px 15px 16.253px 13.75px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 12px;
          padding: 30px;

          .top {
            span {
              border-radius: 4px;
              background: rgba(52, 133, 170, 0.15);
              display: flex;
              padding: 5px 12px;
              flex-direction: row;
              align-items: flex-start;
              gap: 10px;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 20.4px */
            }
          }

          .sides {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            @media (max-width: 1200px) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .left {
              width: 25%;

              @media (max-width: 1200px) {
                width: 100%;
                margin-bottom: 1rem;
              }

              img {
                width: 100%;
                border-radius: 10px;
              }
            }

            .right {
              width: 60%;
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: left;
              padding-right: 40px;

              @media (max-width: 1200px) {
                width: 100%;
              }

              h4 {
                color: var(--3485-aa, #3485aa);
                font-family: Inter;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 13.232px */
                margin-bottom: 0.8rem;
              }

              h2 {
                color: #000;
                font-family: Inter;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 600;
                line-height: 22.505px; /* 138.902% */
                line-height: 1.3em;

                span {
                  color: var(--3485-aa, #3485aa);
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 600;
                }
              }

              p {
                color: #797979;
                font-family: Inter;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 11.342px */
              }
            }

            .action {
              width: 10%;
              display: flex;
              align-items: center;
              justify-content: center;

              .btn {
                box-shadow: none !important;
              }
            }
          }
        }

        .widgets {
          display: flex;
          margin-top: 2rem;
          flex-wrap: wrap;
          margin-bottom: 3rem;
          justify-content: flex-start;
          gap: 2.74%;

          @media (max-width: 1300px) {
            gap:4%;
          }
          .widget {
            width: 31.5%;
            display: flex;
            padding: 114px 48px 123px 47px;
            flex-direction: column;
            align-items: center;
            gap: 22px;
            border-radius: 8px;
            margin-bottom: 2rem;
            background: var(
              --ecddd-8,
              linear-gradient(0deg, #ecddd8 0%, #ecddd8 100%),
              #ecddd8
            );
            align-self: stretch;

            @media (max-width: 1300px) {
              width: 48% !important;
              margin-right: 0;
            }

            @media (max-width: 990px) {
              width: 100% !important;
            }

            .btn {
              background-color: var(--c-3988-a, #c3988a);
              padding: 8px 25px;
              border: none;
              border-radius: 7px;
              font-weight: 15px;
              color: var(
                --ecddd-8,
                linear-gradient(0deg, #ecddd8 0%, #ecddd8 100%),
                #ecddd8
              );
              cursor: pointer;

              &:hover {
                background-color: darken(#c3988a, 10);
              }

              &.soon {
                opacity: .5;
                cursor: initial;

                &:hover {
                  background-color: var(--c-3988-a, #c3988a);
                }
              }
            }

            @media (max-width: 1200px) {
              width: 100%;
            }

            h3 {
              color: var(--c-3988-a, #c3988a);
              text-align: center;
              /* Button Body */
              font-family: Inter;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: 110%; /* 18.7px */
            }
          }
        }
      }

      .right {
        width: 20%;

        @media (max-width: 1200px) {
          width: 100%;

          h2 {
            color:#000 !important
          }
        }

        h2 {
          color: var(--white-ffffff, #fff);
          /* Heading 6 */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
        }

        .notifications {
          display: flex;
          flex-direction: column;
          margin-top: 1rem;

          .notification {
            border-radius: 8px;
            background: #fff;

            h4 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 14.4px */
              border-radius: 4px;
              background: rgba(52, 133, 170, 0.15);
              display: flex;
              padding: 4px 8px;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              margin: 15px;
              margin-bottom: 0;
            }

            p {
              padding: 15px;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 128.571% */
              margin: 0;

              span {
                color: var(--hero-blue-0-b-455-f, #0b455f);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
