.LearnActivities {
  padding: 40px;

  > h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #6c7275;
  }

  .activities {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .activity {
      width: 30%;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border: 0.940585px solid #ffffff;
      padding: 0;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      @media screen and (max-width: 1024px) {
        width: 48%;
      }

      &:hover {
        background: darken(#ffffff, 3);

        .top {
          background: darken(#fde9e0, 10);
        }
      }

      .top {
        background: #fde9e0;
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;

        img {
          vertical-align: bottom;
          max-height: 100%;
        }
      }

      .content {
        padding: 20px;
        width: 100%;
        margin-top: 0px;
        h6 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 11.287px;
          line-height: 140%;
          margin-bottom: 0.5rem;
        }

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 0.5rem;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #777777;
        }
      }
    }
  }
}
