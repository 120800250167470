.templateTool {
  .bluebg {
    background-color: #dcf4ff;
    width: 100%;
    height: 330px;
    margin-top: -20px;
    position: absolute;
    top: 90px;
    left: 0;
    z-index: -1;
  }

  .body-text {
    @media screen and (max-width: 768px) {
      font-size: 18px !important;
      line-height: 1.5em !important;
    }
  }

  .container {
    max-width:90rem;
  padding:0px 40px;
    margin: 0px auto;

    @media screen and (max-width: 768px) {
      padding: 0px 20px;
    }
  }

  .intro {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    .left,
    .right {
      width: 45%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        h3 span {
          font-size: 45px;
        }
      }
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 36px;
      margin-top: 80px;
      span {
        font-weight: 900;
        font-size: 80px;
        line-height: 60px;
      }
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 48px;
    }

    .left {
      display: flex;
      justify-content: flex-start;
      img {
        width: 100%;
        margin-left: 0;
        margin: 0;
        border: 30px solid #efefef;
        max-height: 450px;
        background-color: #fff;
        padding: 10px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 350px;

      @media screen and (max-width: 768px) {
        height: auto;
      }
    }
  }

  .content {
    padding-top: 70px;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 24px;
      line-height: 48px;
    }

    .description {
      margin-top: 70px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 100px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .left,
      .right {
        width: 45%;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 50px;
        }

        &.white-background {
          padding: 30px !important;

          h3 {
            margin: 0;
          }
        }

        &.yellow {
          background-color: #fff9e4;

          ol {
            li {
              div {
                background-color: #ffde67;
                color: black;
              }
            }
          }
        }
      }

      ol {
        list-style: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
        margin-top: 20px;

        li {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 20px;

          > div {
            height: 30px !important;
            width: 30px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #58c5f3;
            border-radius: 50%;
            color: #fff;
          }

          p {
            display: inline-block;
            width: calc(100% - 40px);
            font-size: 14px;
            line-height: 1.4em;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .moreTools {
    background-image: url("../../../../images/gridbg.png");
    background-repeat: repeat;
    background-color: #f3f3f3;
    padding-top: 50px;
    padding-bottom: 90px;

    .tools {
      display: flex;
      justify-content: flex-start;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      > div {
        width: 30%;
        margin-right: 3%;
        margin-top: 110px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &:last-child {
          margin-right: 0;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          height: 4em;
        }
      }
    }
  }
}
