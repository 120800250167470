.interestsAdmin {
  margin-top: 0rem;
  padding: 10px 45px;
  .white-background {
    margin-top: 2rem;
    padding-bottom:6rem;
  }

  p {
    margin:0;
    padding:0;
  }

  button {
    margin-left:1em;

    &.red {
      color:#fff;
    }
  }


  .timesThatWork {
    background-color: #013247;
    color: #fff;
    padding: 15px 25px;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 15px;

    h4 {
      color: #fff;
      font-size: 17px;
      margin-bottom: 1rem;
    }

    .times {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      gap: 2%;

      .time {
        width: 23%;
        background-color: lighten(#013247, 10);
        padding: 10px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;

        &.active {
          background-color: lighten(#013247, 30);

          h4 {
            opacity: 0.7;
          }
        }

        h4 {
          opacity: 0.5;
          height: 3em;
          font-size: 14px;
        }
      }
    }
  }
  .week {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    flex-wrap: wrap;
    h3 {
      width: 100%;
      font-size: 22px;
      margin-bottom: 1rem;
      opacity: .5;
    }

    .capitilize {
      text-transform: capitalize;
    }

    .personContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 3%;
      width: 100%;
      margin-top: 1rem;

      .top {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom:1rem;

        button {
          width: 200px;
        }
      }

      h4 {
        width: 100%;
        font-size: 16px;
        margin-bottom: 0.5rem;
      }
    }

    .person {
      display: flex;
      width: 30%;
      flex-wrap: wrap;
      background-color: #ececec;
      border-radius: 15px;
      padding: 22px 25px;

      .box {
        width: 100%;

        h4 {
          font-size: 17px;
          opacity: 0.5;
          margin: 0;
          padding: 0;
          text-transform: capitalize;
        }

        p {
          text-transform: capitalize;
        }
      }
    }
  }
}

.interestModal {
  .select {
    display: flex;
    flex-direction: column;
    margin-bottom:1rem;
    margin-top:1rem;

    label {
      margin:0;
      padding:0;
    }
  }
}