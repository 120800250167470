.NavigationBox {
    display:flex;
    margin-top:40px;
    max-width: 120px;
    margin:0px auto;
    margin-top:40px;
    justify-content: space-between;
    .box {
        background-color: #FFEAA0;
        border-radius: 100%;
        width:30px;
        height:30px;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 12px;

        &.active {
            color:black;
        }
    }
}