.singleHighlight {
  z-index: 999999999;
  position: fixed;
  width: 100%;
  height: 100%;
  .skrim {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    cursor: pointer;
  }
}
.highlightReel-single {
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  height: 95%;
  overflow: scroll;
  max-width: 1300px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  button:disabled {
    opacity: 0.2;
    cursor: initial;
  }

  .highlightReel-add {
    z-index: 99999999999;

    .form {
      height: 100%;
    }
  }

  > p {
    margin-top: 20px;
  }

  .success {
    text-align: center;
    padding: 40px;
    padding-bottom: 40px;
    margin: 0px auto;
    margin-bottom: 3rem;
    border-bottom: 2px solid #ececec;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    h4 span {
      font-size: 40px;

      @media screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 1.4em;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      max-width: 450px;
      margin: 0px auto;
      align-items: center;

      span {
        color: #000;
        font-weight: 900;
      }

      button {
        padding: 8px 15px;
        background-color: transparent;
        border: 2px solid #58c5f3;
        border-radius: 5px;
        color: #58c5f3;
        font-weight: 600;
        cursor: pointer !important;

        &:hover {
          background-color: #58c5f3;
          color: #fff;
        }
      }
    }

    p {
      color: #000;
      margin-top: 20px;
    }
  }

  .content {
    width: 100%;
    padding: 0px 0px;
    color: #fff;
    margin: 0px auto;
    display: block;
    z-index: 5;
    position: relative;
    color: #000;
    border-radius: 6px;

    .empty {
      margin-top: 20px;

      p {
        padding-left: 0;
      }
    }
    h2 {
      font-size: 1.6rem;
      padding: 1rem 2rem;
      padding-top: 2rem;
      display: flex;
      align-items: center;
      color: #0b455f;
      span {
        margin-left: auto;

        a {
          color: #0d8cc2;
          font-size: 0.8rem;
          margin-left: auto;
          svg {
            margin-right: 5px;
          }
        }
      }

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }
    }

    p {
      padding-left: 2rem;
      margin-bottom: 1rem;
      line-height: 1.5em;
      padding-bottom: 0;
      padding-right: 2rem;
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Small Body */
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
      @media screen and (max-width: 768px) {
        padding-left: 0;
      }
    }

    @media screen and (max-width: 768px) {
      height: auto;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0px 0px;
    }

    a {
      color: #fff;
    }
  }
  .white-background {
    margin-top: 20px;
    margin: 0 auto;
    padding: 0px 0px;
    display: block;
    max-width: 100%;
    height: 100%;
    box-shadow: none;
    border: none;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    border-radius: 6px;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0px 0px;
      flex-wrap: wrap;
      height: 100%;
    }

    .left {
      width: 60%;
      border: none;
      border-right: 2px solid #ececec;
      height: 100%;
      min-height: 95vh;

      @media screen and (max-width: 768px) {
        width: 100%;
        border: none;
        border-bottom: 2px solid #ececec;
        height: auto;
        min-height: auto;
      }

      .image {
        width: 90%;
        margin-left: 5%;
        border-radius: 10px;
        height: 300px;
        max-width: 100%;
        object-fit: contain;
        margin-bottom: 20px;
        background-size: cover;
        background-position: center center;
        border: none;
        margin-bottom: 0;
        background-size: contain;
        background-repeat: no-repeat;
        @media screen and (max-width: 768px) {
          padding-left: 0;
          margin-left: 0;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          border: none;
          border-bottom: 2px solid #ececec;
          height: 200px;
        }
      }

      .collaborators {
        padding-left: 2rem;
        margin-top: 0;

        @media screen and (max-width: 768px) {
          padding-left: 0;
        }
      }
    }

    .right {
      width: 40%;
      justify-content: flex-start;
      padding-top: 0px;
      flex-wrap: wrap;
      height: 95vh;
      overflow: scroll;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        border: none;
        border-bottom: 2px solid #ececec;
        height: auto;
      }

      .comments {
        padding-left: 2rem;
        border-top: 2px solid #ececec;
        width: 100%;
        overflow: scroll;
        height: 43%;
        padding-top: 0px;
        margin-top: 0;
        padding-bottom: 0px;
        @media screen and (max-width: 768px) {
          padding-left: 0;
        }

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }

        .comment-item {
          display: flex;
          align-items: flex-start;
          padding-bottom: 1rem;
          padding-top: 0rem;
          margin-top: 1rem;
          .user {
            display: flex;
            align-items: flex-start;

            .text {
              span,
              p {
                margin: 0;
                padding: 0;
              }
            }
          }
        }

        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          padding-top: 20px;
        }
      }

      .entryForm {
        margin-top: 0rem;
        display: flex;
        align-self: flex-end;
        width: 90%;
        position: relative;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
        height: 8%;
        margin-right: 5%;

        input {
          width: 100%;
          background-color: transparent;
          border: none;
          height: 100%;
        }

        button {
          position: absolute;
          right: 0;
          height: 100%;
          background-color: transparent;
          border: none;
          padding: 0px 20px;
          cursor: pointer;
          color: #000;
        }
      }

      .content {
        height: 33%;
        overflow: scroll;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }

        @media screen and (max-width: 768px) {
          padding-left: 0 !important;
        }
      }

      .top {
        display: flex;
        box-sizing: border-box;
        padding-left: 2rem;
        padding-bottom: 1rem;
        width: 100%;
        height: 10%;
        @media screen and (max-width: 768px) {
          padding-left: 0;
          position: relative;
        }

        .close {
          margin-left: auto;
          margin-right: 1rem;
          background: #999999;
          border-radius: 100%;
          padding: 6px;
          width: 34px;
          height: 34px;
          border: none;
          cursor: pointer;
          z-index: 200;

          @media screen and (max-width: 768px) {
            position: fixed;
            top: 20px;
            right: 10px;
          }

          svg {
            color: #fff;
          }
        }

        .edit {
          background: transparent;
          border-radius: 6.38597px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          padding: 10px 20px;
          border: 2px solid blue;
          border-radius: 37.057px;
          border: 0.741px solid rgba(52, 133, 170, 0.75);
          color: var(--3485-aa, #3485aa);
          font-family: Inter;
          font-size: 12.599px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 13.859px */

          svg {
            margin-right: 10px;
          }
        }

        .share {
          background: #005579;
          border-radius: 6.38597px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          padding: 10px 20px;
          border: none;
          color: #fff;
          margin-left: 15px;
          svg {
            margin-right: 10px;
          }
        }
      }

      .content {
        padding-left: 2rem;
        padding-top: 2rem;
        padding-right: 2rem;
        color: #000;

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 1rem;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: "Inter";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
        }

        a {
          color: #0d8cc2;

          svg {
            margin-right: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 15px 20px;
      border: none;
      box-shadow: none;
    }

    .top {
      display: flex;
      width: 100%;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      .author {
        display: flex;
        align-items: center;
        img {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          margin-right: 20px;
        }

        h4 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 0px;
          margin-bottom: 5px;
        }

        h6 {
          font-size: 1rem;
          font-weight: 700;
          color: gray;
        }

        color: #000;
      }

      .type {
        background-color: #faece0;
        border: 2px solid #f2c9a9;
        border-radius: 5px;
        margin-left: 3rem;
        padding: 6px 20px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          color: #ec9a53;
        }
        h4 {
          font-size: 0.8rem;
          text-transform: uppercase;
          color: #ec9a53;
        }
      }
    }

    .middle {
      margin-top: 3rem;

      h2 {
        margin: 0;
        padding: 0;
        font-size: 1.8em;
        font-weight: 900;
      }

      p {
        margin-top: 1rem;
        color: #000;
        line-height: 1.5em;
      }

      img {
        max-width: 100%;
        max-height: 400px;
      }

      .link {
        margin-top: 1.5rem;
        a {
          color: #0f8cc2;
          font-weight: 600;

          svg {
            color: grey;
          }
        }
      }
    }

    .divider {
      background-color: #ececec;
      border: none;
      width: 100%;
      max-width: 700px;
      height: 2px;
      display: block;
    }

    .collaborators {
      margin-top: 0rem;
      display: flex;
      flex-wrap: wrap;
      padding-top: 1.5rem;
      padding-left: 2rem;
      overflow: scroll;
      align-items: flex-start;
      flex-direction: row;
      flex-basis: auto;
      padding-bottom: 20px;
      flex-flow: column;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      h3 {
        width: 100%;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px !important;
          line-height: 24px;
          padding-top: 20px;
          color: #0b455f;
        }
      }

      .collaborator {
        margin-top: 1em;
        width: 100%;
        background-color: #fff;
        padding: 0px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: center;
        display: flex;
        padding-right: 20px;
        margin-bottom: 0.5rem;

        @media screen and (max-width: 1500px) {
          width: 50%;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 1em;
        }

        h4 {
          font-size: 1.3rem;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }

        > svg {
          color: #ee9b65;
          font-size: 50px;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          margin-right: 1rem;
        }

        button {
          background-color: #dcf4ff;
          border: 2px solid #58c5f3;
          border-radius: 5px;
          padding: 6px 20px;
          color: #0f8cc2;
          font-weight: 600;
        }

        p {
          color: #000;
        }

        span {
          color: #000;
          font-size: 1.3rem;
          font-weight: 600;
        }
      }

      h3 {
        span {
          font-size: 1.8rem;
        }
      }
    }

    .comments {
      margin-top: 3rem;

      .noComments {
        margin-top: 2rem;
        h4 {
          font-size: 1.5rem;
          font-weight: 300;
          margin-bottom: 0px;
          margin-bottom: 5px;
        }
      }

      .entryForm {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 4rem;
        img {
          width: 60px;
          height: 60px;
          border-radius: 100%;
          margin-right: 1rem;
        }

        input {
          width: 100%;
          background-color: #f3f3f3;
          border: 2px solid #58c5f3;
          padding: 15px;
          padding-right: 80px;
          height: 100%;
        }

        button {
          position: absolute;
          right: 20px;
          background-color: transparent;
          border: none;
          cursor: pointer;

          &:hover {
            color: #58c5f3;
          }
        }
      }

      h3 {
        font-size: 1.8rem;
        color: #0b455f;
      }
    }
  }
}
