.ShareWidget {
  position: relative;
  z-index: 99900;
  border:none !important;
  .skrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
  }

  .modal {
    overflow: scroll;
    top: 0 !important;
    z-index: 2;
    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }

    h4 {
      font-size: 1.2rem;
      display: flex;

      span {
        font-size: 0.8rem;
        text-align: right;
        margin-left: auto;
        display: block;
        text-transform: uppercase;
        opacity: 0.5;
      }
    }

    .link {
      width: 100%;
      margin-bottom: 2em;

      .link-container {
        display: flex;
        align-items: center;
        margin-top: 10px;
        svg {
          margin-left: 10px;
          font-size: 1.2em;
          position: absolute;
        }
      }
      input {
        width: 100%;
        padding-left: 3em;
        background-color: transparent;
        border: none;
      }
    }

    .coach {
      display: flex;
      margin-bottom: 2em;
      margin-top: 2em;

      p {
        line-height: 1.5em;
        font-weight: 400;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 2em;
      }
    }


    > p {
      margin-top: 20px;
    }
    position: fixed;
    top: 50%;
    z-index: 500;
    .white-background {
      margin-top: 0px;
      position: relative;
      padding-bottom: 40px;
      max-height: 90%;
      overflow: scroll;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 65%;
      z-index: 50;

      @media screen and (max-width: 768px) {
        width: 90%;
        left:5%;
        transform: none;
        top:5%
      }

      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }

      h3 {
        text-align: left;

        span {
          font-size: 1.8rem;
          color:var(--hero-blue-0-b-455-f, #0b455f);
        }
      }
    }

    .close {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 25px;
      cursor: pointer;
    }
  }
}
