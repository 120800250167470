.learningActivityCard {
  width: 31%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 0.940585px solid #ffffff;
  padding: 0;
  margin-bottom: 1.5rem;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  margin-right:2%;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100% !important;
  }

  .done {
    position: absolute;
    top: 30px;
    right: 40px;
    display: none;

    svg {
      color: darken(#ff9e67, 10);
      border: 3px solid darken(#ff9e67, 10);
      border-radius: 100%;
      padding: 4px;
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  @media screen and (max-width: 990px) {
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    width: 48%;
  }

  &:hover {
    .top {
      background: darken(#fff5f0, 4);
    }
  }

  .top {
    background: #fff5f0;
    width: calc(100% - 40px);
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-left: 20px;
    margin-right: 50px;
    margin-top: 20px;
    border-radius: 24px;

    &:hover {
      background: darken(#fff5f0, 4);
    }

    img {
      vertical-align: bottom;
      max-height: 100%;
      max-width: 70px;
    }
  }

  .content {
    padding: 20px;
    width: 100%;
    margin-top: 0px;
    h6 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 11.287px;
      line-height: 140%;
      margin-bottom: 0.5rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #013247;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #ff9e67;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0.5rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 26px;
      color: #013247;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 140%;
      color: #013247;
      margin-top: 1rem;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #777777;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #013247;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #013247;
      opacity: 0.6;
    }
  }
}
