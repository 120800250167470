.onboardingIntro {
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }

  button {
    margin:0px auto;
    margin-top:2rem;
  }

  .box {
    background-color: #fff;
    z-index: 40;

    p {
      color: #000;
      font-size: 30px;
      width: 60%;
      padding:20px 40px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fff;
      z-index: 10;
      background: #ffffff;
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 123.5%;
      color: #0b455f;
    }
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    color: #0b455f;
    margin-bottom: 1rem;
    font-family: "Inter";
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 163.5%;
    /* or 65px */

    text-align: center;

    /* #0B455F */

    color: #0b455f;
  }
}

.onboardingHome {
  width: 100%;
  height: 100%;
}
