.LearningSection {
  border-bottom: 1px solid #013247;
  padding: 20px;
  padding-left: 0px;
  padding-right:0px;

  &:hover {
    cursor: pointer;
  }

  .top {
    display: flex;
    align-items: center;
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      color: #013247;
    }

    .not-done {
      margin-left: auto;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      svg {
        margin-left:10px;
        color:#013247;
      }
    }

    .done {
      margin-left: auto;

      svg {
        color: black;
        border: 3px solid #FF9E67;
        border-radius: 100%;
        padding: 4px;
        width: 1.3rem;
        height: 1.3rem;
        background-color: #FF9E67;
      }
    }
  }

  .dropdown {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
}
