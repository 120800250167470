.nav-bar__container {
  width: 250px;
  display: flex;
  margin-bottom: 20px;
  background-color: #013247;
  height: 100vh;
  padding-bottom: 30px;
  overflow: scroll;

  @media screen and (max-width: 1200px) {
    display: none !important
  }
  

  &.minimized {
    width: 100px;

    .nav-bar {
      padding: 0;
      min-height: 100%;
    }

    img {
      padding: 0px 33px;
    }

    svg {
      margin: 0;
      padding: 0;
      margin-right: 0 !important;
    }

    .canvas {
      display: none !important;
    }

    .profile-dropdown {
      margin-top:auto;
      p {
        display: none;
      }

      img {
        margin: 0px auto;
        object-fit: cover;
        padding: 0;
      }
    }

    .link,
    .item {
      display: flex;
      justify-content: center !important;
      padding: 0 !important;
      margin-left: 0 !important;

      .text {
        display: none !important;
      }

      .item.true {
        background-color: transparent !important;
        border: none !important;
        color: #fff !important;
        width: calc(100% + 50px);

        svg path {
          fill: #ee9b65 !important;
        }
      }

      .dropdown {
        ul {
          padding: 0 !important;
          margin-left: 0px !important;

          li {
            text-align: center;
          }
        }
      }
    }

    .nav-bar .nav-bar__links .link {
      margin-bottom: 2rem !important;

      &.active {
        background-color: transparent !important;
        border: none !important;
        color: #fff !important;

        svg path {
          fill: #ee9b65 !important;
        }
      }
    }

    .fa-chevron-down {
      display: none;
    }

    .text {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    display: none !important;
  }

  .yellow-bg {
    position: fixed;
    top: 20%;
    z-index: 0;
    left: -120px;
  }

  .blue-bg {
    position: fixed;
    top: 70%;
    z-index: 0;
    right: 0;
  }

  .nav-bar__links__overlay {
    background-color: rgba(0, 0, 0, 0.01);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  .toolMenu,
  .pathMenu {
    &:hover {
      .tool-navigation,
      .path-navigation {
        display: block;
      }
    }

    .tool-navigation,
    .path-navigation {
      display: none;
    }

    .path-navigation {
      margin-left: -10px;
    }
  }

  .nav-bar {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0px 10px;
    margin: 0px auto;
    flex-direction: column;

    .nav-bar__brand.full img {
      margin-top: 20px;
      margin-bottom: 40px;
      width: 150px;
      max-width: 100% !important;
      margin-left: 20px;
    }

    .nav-bar__links {
      margin-left: 0px;
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      width: 95%;

      .link {
        margin-right: 0px;
        color: #fff;
        font-weight: 600;
        font-size: 15px;
        display: flex;
        padding: 8px 15px;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        margin-bottom: .6rem;
        flex-direction: row;
        flex-wrap: wrap;
        padding-right: 0;

        .dropdown {
          margin-top:5px;
          margin-left:-5px;
        }

        &.disabled {
          opacity: 0.5;

          &:hover {
            opacity: 0.5 !important;
          }

          .item {
            opacity: 0.5 !important;
          }

          ul {
            li {
              font-weight: 300;

              &:before {
                background: #395868 !important;
              }

              &:hover {
                font-weight: 300;
              }
            }
          }
        }

        svg path,
        svg g {
          fill: white;
        }

        span {
          font-size: 9px;
          margin-left: auto;
          color: #fff;
          padding: 6px 7px;
          border-radius: 5px;
          opacity: 1;
        }

        .text {
          font-size: inherit;
          margin-left: initial;
          background-color: initial;
          color: inherit;
          font-size: 15px;
        }

        .item {
          padding: 0;
          margin: 0;
          align-items: center;
          width: 100%;
          padding: 10px;
          padding-right: 0;
          padding-left: 15px;
          margin-left: -15px;
          display: flex;

          &.true {
            width: calc(100% + 50px);
          }

          > svg:first-child {
            width: 1.3em;
            height: 1.3em;
            flex-grow: initial;
            flex: none;
          }

          .fa-chevron-down {
            margin-right: 0;
          }

          .dropdown {
            width: 100%;
          }
          .text {
            width: 100%;
            display: flex;

            svg {
              margin-left: auto;
              float: right;
              margin-right: -15px;
            }
          }

          svg path,
          svg g {
            fill: white;
          }

          &.true {
            background-color: blue;
            background-color: #ff9e67;
            border-radius: 10px;
            color: black;
            .fa-chevron-up,
            .fa-chevron-down {
              margin-right: 0.8em;
            }

            svg path {
              color: black;
              fill: black;
            }
          }

          svg:nth-child(2) {
            font-size: 11px;
            opacity: 0.5;
            margin-left: auto;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          margin-left: 40px;
          margin-top: 0rem;
          margin-bottom: -.5rem;
          li {
            margin-bottom: 0.5rem;
            padding: 0;
            position: relative;
            padding-bottom: 10px;
            list-style: none;
            font-size: 15px;
            font-weight: 300;

            &:hover,
            &.true {
              color: #fff;
              font-weight: 800;

              &:before {
                background: #fff;
              }
            }

            .line {
              height: 26px;
              width: 2px;
              background: #395868;
              position: absolute;
              left: -26px;
              top: 18px;
            }

            &:before {
              width: 15px;
              height: 15px;
              content: " ";
              background: #395868;
              display: block;
              border-radius: 100%;
              margin-left: -7px;
              margin-top: -10px;
              position: absolute;
              left: -25px;
              top: 13px;
            }
          }
        }

        &.active {
          background-color: #ff9e67;
          border-radius: 10px;
          opacity: 1;
          color: black;

          svg path {
            fill: black !important;
          }
        }

        &:hover {
          opacity: 1;
        }

        a {
          color: #fff;
          text-decoration: none;
          font-size: 1.2rem;
          padding: 20px;
        }

        svg {
          margin-right: 0.5rem;
          width: 1.3em;
          height: 1.3em;
        }

        &:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .bar {
      display: flex;
      margin-left: 50px;

      a {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 18px;
        color: #ffff;
        margin-right: 2em;
      }
    }

    .nav-bar__brand.mobile img {
      max-width: 80px !important;
    }

    @media (min-width: 1200px) {
      .nav-bar__brand.mobile {
        display: none;
      }
    }

    @media (max-width: 1200px) {
      .nav-bar__brand.full {
        display: none;
      }
    }

    img {
      max-width: 200px;
    }

    .nav-bar__actions {
      svg {
        color: #000;
        font-size: 20px;
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #e5e8f0;
        padding-right: 12px;

        &:hover {
          background-color: black;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
