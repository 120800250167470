.profile-edit {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 60px;
  margin: 0px auto;
  color: #000;
  border-radius: 6px;
  padding-top: 20px;
  max-width: 90rem;
  padding: 30px 40px;
  margin: 2rem 1.5rem 1.5rem;
  margin-top: 0;

  .delete {
    margin-top: 3rem;
    opacity: 0.5;
    box-shadow: none !important;
    background-color: red !important;
    color: #fff !important;
    border: none !important;

    &:hover {
      background-color: red !important;
      color: #fff !important;
      opacity: 1;
    }
  }

  > h2 {
    margin-bottom: 2rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */

    /* #013247 */

    color: #013247;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 18px;
  }

  textarea {
    width:100%;
    background-color: #fff;
    border:none;
    background: #ffffff;
    border: 1px solid #f4eeec;
    box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36);
    border-radius: 7px;
  }

  .input {
    margin-top: 30px;
    position: relative;

    &:hover {
      button {
        background-color: #ff7d34;
      }
    }
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      padding: 0;
      margin: 0;
      margin-top: 0 !important;
      height: 60px;
    }
  }

  .actions {
    display: flex;
    align-items: flex-start;

    button {
      margin-right: 2rem;
      padding: 13px 20px;
      align-items: center;

      svg {
        margin-right: 1rem;
      }

      &.opacity {
        opacity: 1;
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .dashboard__header {
    color: #fff;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    flex-direction: row;
    align-items: center;
    padding-bottom: 30px;

    .empty {
      margin-top: 20px;
    }

    img {
      width: 140px;
      height: 140px;
      margin-right: 1em;
    }

    h3 {
      color: #fff;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
    }

    img {
      border-radius: 100%;
    }
  }

  .settings {
    margin-top: 0px;
    background-color: #fff;
    padding: 40px;
    border-radius: 26px;

    .form-container {
      width: "100%";
      display: flex;
      justify-content: space-between;

      &.full {
        .form-group {
          width: 100%;
        }
      }

      .form-group {
        width: 48%;
        margin-bottom: 20px;
        label {
          display: block;
        }

        input {
          width: 100%;
        }
      }
    }
  }
}
