@import "../../../theme.scss";


.GroupEdit {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width:90rem;
    padding:0px 40px;
    position: relative;
    margin-top:30px;

    h2 {
        width: 100%;
        margin-bottom:30px;
    }

    .white-background {
        margin-bottom:40px;
    }

    .container {
        width: 100%;

        #async-example {
            background-color: white;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
            padding:10px;

            .dropdown-item {
                margin-bottom:15px;
                color:#000;
                padding:10px;
                img {
                    width: 30px;
                    height: 30px;
                    margin-right:5px;
                    border-radius: 100%;
                }
            }
        }

        h3 {
            margin:0;
            padding:0;
        }
        
        .inputBox {
            display: flex;
            flex-direction: column;
            margin-bottom:30px;
        }
    }

    .selected {
        margin-top:40px;
        display: flex;
        flex-direction: column;

        .item {
            display:flex;
            align-items: center;
            margin-bottom:15px;
            border-bottom:2px solid #ececec;
            width:300px;
            padding-bottom:15px;

            .btn {
                padding:6px;
                margin-left:15px;
                margin-left:auto
            }

            img {
                width: 30px;
                height: 30px;
                margin-right:5px;
                border-radius: 100%;
                margin-right:15px;
            }
        }

    }
}