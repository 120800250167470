.highlightReel-welcome {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: scroll;
  transform: translate(-50%, -50%);
  max-height: 80%;

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  > p {
    margin-top: 20px;
  }
  position: fixed;
  top: 50%;
  z-index: 500;
  .white-background {
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    z-index:99999;

    .left,
    .right {
      width: 45%;
    }

    .right {
      width: 50%;
    }

    .left, .right {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .right {
      h6 {
        font-size:1em;
        text-align: right;
        margin-top:20px;
      }

      button {
        margin-left:auto
      }
    }

    .video {
      position: relative;
      height: 250px;
    }

    .left {
      > h2 {
        margin-bottom: 20px;
        font-size: 1.5rem;
      }
    }

    p {
      line-height: 1.6rem;
    }

    h5 {
      font-size: 1rem;
      margin: 0;
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      margin-left: 30px;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }

    button {
      margin-top: 1em;
      padding: 10px 25px;
      font-size: 1rem;

    }
  }

  .close {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
  }

  .avatar {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    select {
      background-color: #e1f6ff;
      border: None;
      color: #000;
      font-weight: 500;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 20px;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    button {
      background-color: #005579;
      border-color: #005579;

      &:hover {
        background-color: darken(#005579, 10);
        border-color: #005579;
      }
    }

    label {
      margin-bottom: 5px;
      width: 100%;
      display: flex;

      span {
        margin-left: auto;
        opacity: 0.3;
        font-size: 0.7rem;
      }
    }
    input,
    textarea {
      background-color: #ececec;
      border: 1px solid #58c5f3;
    }

    .right {
      width: 60%;

      .btn.primary {
        margin-left: auto;
        margin-top: 3rem;
        background-color: #6699af;
        border-color: #6699af;
        color: #fff;
        padding: 7px 20px;
      }
    }

    .left {
      width: 35%;

      .photo {
        position: relative;
        width: auto;
        display: flex;
        img {
          max-width: 100%;
          min-width: 50%;
          object-fit: cover;
        }

        svg {
          background-color: #005579;
          padding: 5px;
          color: #fff;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .dragAndDrop {
        width: 100%;
        height: 500px;
        background-color: #ececec;
        padding: 20px;
        align-items: center;
        justify-content: center;
        border: 2px dotted black;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;

        svg {
          font-size: 60px;
          opacity: 0.5;
          margin-bottom: 1rem;
        }

        h4 {
          font-size: 1.4rem;
        }

        p {
          font-size: 18px;
          line-height: 25px;
          margin-top: 1em;
          opacity: 0.5;
        }
      }
    }

    .preview {
      max-width: 200px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      label {
        font-size: 1rem;
        font-weight: 300;
      }
      &.flex {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;

        label,
        input {
          display: inline-block;
          margin: 0;
          padding: 0;
        }

        label {
          font-size: 1rem;
        }

        input {
          width: 2em !important;
          height: 2em !important;
        }
      }

      input[type="checkbox"] {
        padding: 0;
        width: 20px;
        height: 20px;
      }

      select {
        background: #dcf4ff;
        border: 1px solid #dcf4ff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        padding: 10px 20px;
        font-size: 14px;
        background-color: #ececec;
        border: 1px solid #58c5f3;
        color: #000;
      }
    }
  }

  .collaborators {
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: 100%;
    h5 {
      font-size: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      border-top: 2px solid #ececec;
      padding-top: 30px;
      font-size: 1rem;
      font-weight: 700;

      svg {
        margin-right: 10px;
      }

      .arrow {
        margin-left: 20px;
      }

      span {
        opacity: 0.5;
        font-size: 0.6rem;
        margin-left: auto;
      }
    }

    .collaboratorInput {
      display: flex;
      margin-top: 2rem;
      justify-content: space-between;
      flex-wrap: wrap;

      .error {
        width: 100%;
        display: flex;
        background-color: #ee9b65;
        color: #fff;
        padding: 6px 20px;
        border-radius: 5px;
      }

      .form-group {
        width: 45%;
        margin-bottom: 2rem;

        &.full {
          width: 100%;
        }
      }
      input {
        width: 100%;
      }

      .infoSection {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        h4 {
          font-size: 0.9rem;
          font-weight: 700;
          span {
            display: block;
            font-weight: 300;
            font-size: 0.9rem;
          }
        }
        button {
          background-color: transparent;
          border-color: #ee9b65;
          color: #ee9b65;
          padding: 2px 10px;
          font-size: 0.8rem;
          height: auto;
          margin-top: 0 !important;
        }
      }

      label {
        font-weight: 300;
        font-size: 1rem;

        span {
          font-size: 0.7rem !important;
          font-weight: 600;
        }
      }
      button {
        height: 47px;
        border-radius: 5px 5px 5px 5px;
      }
    }

    .collaborator-item {
      width: 100%;
    }

    .collaborator,
    .collaborator-item {
      margin-top: 2rem;
      padding-bottom: 0.5rem;
      display: flex;
      border-bottom: 2px solid #ececec;
      opacity: 0.5;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 10px;
      }

      svg {
        font-size: 30px;
      }

      .delete {
        cursor: pointer;

        &:hover {
          opacity: 1;
          color: #58c5f3;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
      p {
        margin: 0;
        width: 100%;
        padding: 0;
        margin-right: 2rem;
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
