@import "../../theme";

.strengthsInvite {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 80px;
  margin-top: -20px;
  background-color: #f4eeec;
  padding: 50px 0px;
  padding-top: 25px;

  padding-bottom: 0;
  background-repeat: repeat;
  background-position: left center;
  background-size: 20px 20px;
  min-height: 100vh;
  align-items: flex-start;
  align-content: flex-start;

  .top {
    max-width:90rem;
  padding:0px 40px;
    margin: 0px auto;
    padding-bottom: 30px;
    width:100%;

    h2 {
      color: #000;
      border: none;
      margin-bottom: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 50px;
      &:after {
        display: none;
      }
    }

    p {
      color: #000;
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }

  button.blank {
    border: 3px solid #8C44D5;
    color:#8C44D4;

    &:hover {
        background-color: #8C44D5;
        color:#fff;
    }
  }

  .white-background {
    max-width:90rem;
  padding:40px 40px;
    margin: 0px auto;
    width:calc(100% - 80px);
    margin-bottom:30px;

    &.padding {
      padding: 40px;
      height: auto;
      display: block;
    }

    > p {
      width: 100%;
    }
  }

  h2 span {
    @media screen and (max-width: 768px) {
      font-size: 25px !important;
      line-height: 1.3em !important;
    }
  }

  .body-text {
    @media screen and (max-width: 768px) {
      font-size: 15px !important;
      line-height: 1.5em !important;
    }
  }

  .dots {
    display: flex;
    margin-bottom: 10px;
    span {
      width: 20px;
      height: 20px;
      background-color: #ffeaa0;
      margin-right: 10px;
      padding: 4px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      &.active {
        background-color: #ffde67;
        color: black;
      }
    }
  }

  .form {
    width: 100%;

    .input-group {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;

      input {
        width: 45%;
      }
    }

    .form-group.half {
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .input-group {
        width: 45%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 30px;
        }

        input {
          width: 100%;
        }

        .types {
          display: flex;

          @media screen and (max-width: 768px) {
            flex-wrap: wrap;
          }

          .btn {
            background: #ffffff;
            border: 3px solid #58c5f3;
            border-radius: 6px;
            padding: 12px 26px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 24px;
            color: #0d8cc2;
            margin-right: 20px;
            margin-top: 15px;

            @media screen and (max-width: 768px) {
              margin-right: 0px;
              margin-bottom: 10px;
              width: 100%;
            }

            &.active {
              background: #58c5f3;
              color: #ffffff;
            }
          }
        }
      }

      label {
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }

  .email {
    width: 100%;
    .form-group {
      display: flex;
      margin-top: 30px;
      width: 100%;

      .input-group {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    .gray {
      background-color: #f3f3f3;
      padding: 40px 20px;
    }
  }

  .bottom {
    width: 100%;
    margin-top: 0px;
    justify-content: space-between;
    display: flex;
    max-width:90rem;
    margin: 0px auto;
    padding-bottom: 40px;
    padding:20px 40px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      padding: 0px 20px;

      button {
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
        margin-right: 0px !important;
      }
    }
  }

  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 48px;
    }
  }

  > p {
    margin-bottom: 20px;
  }

  .white-background {
    display: flex;
    flex-wrap: wrap;
  }
}
