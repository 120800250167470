.chatMessage {
  display: flex;
  margin-bottom:0rem;

  .chatMessageBox {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    align-items: flex-start;

    > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .hide {
      display: none;
    }

    img.hide {
      display: block;
      opacity: 0;
    }

    .chatMessageTitle {
      margin-left: 1rem;
      max-width: 72%;
      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
      .bubble {
        background: #fff;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0px 20px 20px 20px;
        padding: 15px 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-width: 85px;

        &.hideImage {
          border-radius: 20px;
        }



        span {
          display: block;
          width: 100%;
          opacity: 0.5;
          margin-top: 1em;
        }

        .type {
          background-color: #fff;
          padding: 10px;
        }

        .dot-flashing {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite linear alternate;
          animation-delay: 0.5s;
          margin-left: 1rem;
        }
        .dot-flashing::before,
        .dot-flashing::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }
        .dot-flashing::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 0s;
        }
        .dot-flashing::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dot-flashing {
          0% {
            background-color: #000;
          }
          50%,
          100% {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 1.4em;
          width: 100%;
          margin: 0;
          padding: 0;

          &.waiting {
            border-radius: 15px 15px 15px 15px;
          }
        }

        button {
          background-color: transparent;
          border: none;
          padding: 0;
          margin-top: 0.5rem;
          color: #0b455f;
          cursor: pointer;
          font-weight: 800;
          font-size: 12px;
        }
      }
    }
  }
}
