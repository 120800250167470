.videoPlayer {
  margin: 0px auto;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 6px 6px;
  border: none;
  border-radius: 24px;

  .awaiting {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #fff;
    font-size: 12px;
    z-index: 300;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 6px;
    border-radius: 6px;
    p {
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
  }

  p {
    line-height: 1.4em;
  }

  .hide {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 9px;
    border-radius: 100%;
    color: #fff;
    font-size: 12px;
    z-index: 300;

    p {
      font-size:12px;
    }

    &:hover {
      background-color: rgba(0,0,0,0.8);
    }
  }

  .actionBox {
    border-top: 2px solid #d9d9d9;
    width: calc(100% - 20px);
    margin-left: 10px;
    cursor: pointer;

    .responses {
      display: inline-block;
      background: #ffe6f1;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px 12px;
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 15px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #ff67a7;
      background: rgba(255, 158, 103, 0.2);
      border-radius: 7.84138px;

      svg {
        color: #0b455f;
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 9.80172px;
        line-height: 140%;
        text-align: center;
        color: #0b455f;
      }

      @media (max-width: 768px) {
        span {
          display: none;
        }

        svg {
          margin-right: 0 !important;
          padding-right: 0;
        }
      }

      svg {
        margin-right: 5px;
      }

      &:hover {
        background: darken(rgba(255, 158, 103, 0.2), 30);
        color: #fff;
      }
    }
  }

  .notSeen {
    position: absolute;
    left: 0%;
    top: 1rem;
    right: 0%;
    bottom: auto;
    display: flex;
    width: auto;
    height: 3rem;
    max-width: none;
    min-width: auto;
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 4px 12px 1px rgb(0 0 0 / 25%);
    font-family: Inter, sans-serif;
    font-size: 0.625rem;
    line-height: 0.75rem;
    font-weight: 500;

    span {
      cursor: pointer;
      color: white;
      border-style: solid;
      border-width: 1px;
      border-color: #ff67a7;
      background-color: #ff67a7;
      transition: all 300ms ease-in-out;
      text-decoration: none;
      padding: 5px 10px;
      display: flex;
      padding: 0.563rem 0.75rem;
      justify-content: space-between;
      align-items: center;
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      border-radius: 6px;
      font-family: Inter, sans-serif;
      font-size: 0.625rem;
      line-height: 0.75rem;
      font-weight: 800;
    }
  }

  @media (max-width: 768px) {
    width: 95%;
  }

  .container {
    padding: 0px;
    position: relative;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  @media (max-width: 768px) {
    border-radius: 0px;
  }

  .video {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 202;

    .navbar {
      width: 100%;
      background-color: #c695f6;
      height: 80px;
      z-index: 600;
      position: relative;

      .inside {
        max-width: 900px;
        margin: 0px auto;
        display: flex;
        justify-content: flex-start;
        color: #fff;
        align-items: center;
        padding-top: 0px;
        height: 100%;

        .information {
          display: flex;
          margin: 0px auto;
        }

        svg {
          margin-right: 30px;
          font-size: 30px;
          cursor: pointer;
        }
        p {
          color: #fff;
          display: inline;
          text-align: left;
          width: initial;
          margin: 0;
          padding: 0;
        }
        img {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 10px;
        }
      }
    }

    p {
      color: #fff;
    }

    .videoContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 202;
      height: 95vh !important;
      width: auto !important;
      max-width: 90%;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        width: 95% !important;
      }

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #000;
        font-size: 30px;
        width: 30px;
        height: 30px;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 100%;
        color: #000;
        z-index: 200;
        opacity: 80%;

        &:hover {
          cursor: pointer;
          opacity: 100%;
          background-color: rgba(255, 255, 255, 1);
        }
      }

      video {
        width: 100%;
        max-height: 100%;
      }
    }

    .skrim {
      background-color: white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .info {
    padding: 1rem;
    width: 100%;
    color: #fff;
    left: 0;
    display: flex;
    align-items: center;

    &.empty {
      p {
        width: 100%;
      }
    }

    img {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 100%;
      margin: 0;
      padding: 0;
      margin-right: 0px;
      border: 2px solid #fff;
      object-fit: cover;
      margin-left: -5px;
      margin-right: 0.625rem;
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      width: calc(100% - 55px);
      margin-right: 9px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;

      color: #000000;

      span {
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
        display: block;
        font-family: Inter, sans-serif;
        font-size: 0.875rem;
        line-height: 0.875rem;
        font-weight: 600;
      }
    }
  }

  .image {
    position: absolute;
    top: 0%;
    left: 0;
    bottom: 0;
    right: 0;
    color: #000;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    overflow: hidden;

    .icon {
      position: absolute;
      position: absolute;
      padding: 10px 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-direction: row;

      svg {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1rem;
        border-radius: 100%;
        font-size: 32px !important;
        height: 2.5rem;
        width: 2.5rem;
      }

      p {
        padding: 0;
        margin: 0;
        color: #fff;
      }
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @media (max-width: 768px) {
      border-radius: 0px;
    }

    &:hover {
      cursor: pointer;

      svg {
        opacity: 1;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 64px;
      padding: 24px 30px;
      border-radius: 100%;
    }
  }
}
