@import "../../../theme.scss";

.programs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding:0px 40px;
  max-width:90rem;
  margin:0px auto;
  position: relative;
  margin-top:30px;

  .programs {
    padding:0;
  }
  h2 {
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;
  }
  


  #async-example {
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    padding: 10px;
    z-index: 55;

    .dropdown-item {
      margin-bottom: 15px;
      color: #000;
      padding: 10px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        border-radius: 100%;
      }
    }
  }

  .right {
    width: 70%;
    padding: 20px;
    background-color: white;
    margin-top: 20px;

    h3 {
      font-size: 2rem;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      flex-direction: column;
      width: 200px;
      align-items: flex-end;
      margin-left: 40px;
      justify-content: flex-end;

      .edit {
        padding: 8px;
        margin: 0;
        width: auto;
        font-size: 16px;

        svg {
          margin-right: 0;
        }
      }

      a {
        width: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        background-color: transparent;
        margin-top: 15px !important;
      }

      .info {
        width: auto !important;
      }
    }

    .row {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        padding: 5px 20px;
      }

      h3 {
        padding: 0;
        margin: 0;
      }
    }

    .program {
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      margin-top: 15px;
      width: 100%;

      .title {
        p {
          margin: 0;
          padding: 0;
          margin-top: 15px;
          font-weight: 400;
        }

        .full-title {
          display: flex;

          .pill {
            font-size: 11px;
            padding: 0;
            margin: 0;
            margin-left: 15px;
            background-color: $green;
            color: #fff;
            padding: 1px 15px;
            border-radius: 20px;
            font-weight: 800;

            &.in-active {
              background-color: $green;
            }

            &.purple {
              background-color: red;
            }
          }
        }
      }

      h6 {
        margin: 0;
        padding: 0;
      }

      a {
        color: black;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent !important;
        padding: 15px 25px;
        border-radius: 10px;
        align-items: flex-start;

        .info {
          background-color: $pink;
          color: #fff;
          padding: 6px 10px;
          border-radius: 10px;
          width: 150px;
          margin-left: 20px;
        }
      }

      svg {
        margin-right: 5px;
      }
    }

    h3 {
      margin-top: 0;
    }
  }
}
