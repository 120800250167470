.topicsDashboard {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 0px 40px;

  @media (max-width: 1200px) {
    padding: 0px 20px;
  }

  .presenterView {
    z-index: 60;
  }

  .shareModal {
    display: flex;
    flex-direction: column;

    h3 {
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Heading 4 */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }

    label {
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Heading 6 */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
    }

    p {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
      opacity: 0.5;
    }

    .inputBox {
      display: flex;
      position: relative;
      cursor: text;

      .copy {
        position: absolute;
        top: -2px;
        cursor: pointer;
        right: 10px;

        p {
          color: var(--0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 21px */
          opacity: 1;
        }
      }

      svg {
        position: absolute;
        left: 15px;
        top: 15px;
      }

      input {
        border-radius: 7px;
        border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
        background: var(--white-ffffff, #fff);
        box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
        padding-left: 60px;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin: 0;
    }

    .right {
      width: 180px;
      margin-left: 120px;
      justify-content: flex-end;
      display: flex;
    }
  }

  .modal {
    .skrim {
      background: rgba(11, 69, 95, 0.5);
    }
  }

  .modal-content {
    display: flex;
    padding: 60px 50px !important;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 32px;
      opacity: 0.5;
      cursor: pointer;
    }

    .left {
      width: 40%;
      flex-shrink: 0;
    }

    .right {
      button {
        margin-left: auto;
        margin-top: 4rem;
      }
      h2 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 44.8px */
        padding: 0;
        margin: 0;
      }

      p {
        color: #013247;

        /* Small Body */
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
      }
    }
  }

  h5 {
    color: var(--013247, #013247);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  h2 {
    color: var(--013247, #013247);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
    margin-bottom: 2rem;
  }

  p {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-top: 1rem;
  }

  .valueImageContainer {
    padding: 30px;
    border-radius: 17.949px;
    border: 0.897px dashed var(--hero-orange-ff-9-e-67, #ff9e67) !important;
    margin-top: 2rem;
  }

  .white-background {
    border: none;
    margin-bottom: 3rem;

    &.allTopics {
      display: flex;
      flex-direction: column;
    }

    .notes {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      textarea {
        background-color: #fff;
        border: none;
        margin-top: 1em;
        border-radius: 7px;
        border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
        background: var(--white-ffffff, #fff);
        box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
        min-height: 200px;
      }

      button {
        width: 100px;
        margin-top: 2rem;
      }
    }

    .topics {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .topic {
        h4 {
          color: var(--3485-aa, #3485aa);
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
        }

        p {
          border-radius: 6px;
          border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
          background: #fff;
          width: 100%;
          padding: 21px 925px 21px 25px;
          align-items: center;
          flex-shrink: 0;
        }
      }
    }

    .inSession {
      border-radius: 8px;
      background: #ebf3f7;
      display: flex;
      padding: 20px;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 1.5rem;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        h5 {
          margin: 0;
          margin-right: 1rem;
        }

        span {
          border-radius: 50px;
          background: #ffc8a8;
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
          margin: 0;
          padding: 5px 10px;
        }
      }

      &.steps {
        border-radius: 8px;
        background: #fff2eb;

        button {
          margin-top: 1.5rem;
          border-radius: 12px;
          background: rgba(255, 158, 103, 0.5);
          box-shadow: none;
        }
      }

      h5 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 15.4px */
        margin: 0;
        text-transform: uppercase !important;
        margin-bottom: 1rem;
        padding: 0;
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        margin: 0;
        padding: 0;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
    }

    &.insights {
      display: flex;
      flex-direction: column;

      button {
        margin-left: auto;
        background-color: transparent;
        box-shadow: none;
        border: none;
      }
      .content {
        border-radius: 8px;
        background: #ebf3f7;
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-top: 1rem;

        &.empty {
          background-color: transparent;
          padding: 0;
        }

        .allInsights {
          display: flex;
          overflow: scroll;

          .insight {
            border-radius: 10px;
            background: linear-gradient(0deg, #ebf3f7 0%, #ebf3f7 100%), #ecddd8;
            width: 250px;
            padding: 15px;
            margin-right: 2rem;
            flex-shrink: 0;
            padding-bottom: 25px;

            &:last-child {
              margin-right: 0;
            }

            .icon {
              border-radius: 8px;
              background: linear-gradient(0deg, #c6dde8 0%, #c6dde8 100%),
                #ecddd8;
              flex-shrink: 0;
              width: 30px;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                color: #1e6484;
              }
            }

            h4 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 16.8px */
              margin-top: 1rem;
              margin-bottom: 1.2rem;
            }

            h5 {
              font-size: 15px;
            }
          }
        }

        textarea {
          background-color: #fff;
          border: none;
          margin-top: 1em;
        }

        > .btn {
          width: 100px;
          margin-top: 1rem;

          &:disabled {
            opacity: 0.3;
          }
        }

        > h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
        }

        > h4 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          /* Heading 6 */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
          margin-top: 20px;
        }
      }
    }

    .invites-list {
      img {
        width: 60px;
        height: 60px;
        border-radius: 100%;
      }
      > h3 {
        color: #013247;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
        margin-bottom: 2rem;
      }
    }

    .top {
      display: flex;
      align-items: center;
      width: 100%;

      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }

      .buttonContainer {
        display: flex;
        margin-left: auto;

        button:first-child {
          border-radius: 50px;
          background: rgba(52, 133, 170, 0.2);
          color: var(--hero-blue-0-b-455-f, #0b455f);
          text-align: center;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
          margin-right: 1.5rem;

          &:hover {
            background: darken(rgba(52, 133, 170, 0.2), 20);
          }
        }

        button:nth-child(2) {
          border-radius: 50px;
          border: 1px solid var(--3485-aa, #3485aa);
          color: var(--3485-aa, #3485aa);
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 18.7px */

          &:hover {
            font-weight: 400;
            background-color: #0b455f;
            color: #fff;
          }
        }
      }

      button {
        &.empty {
          background-color: transparent;
          color: var(--3485-aa, #3485aa);
          text-align: right;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 18.7px */
          margin-left: auto;

          &:hover {
            transform: scale(1);
            font-weight: 600;
          }
        }
      }
    }

    &.invites {
      .top {
        margin-bottom: 20px;
      }
      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 2rem;

        h4 {
          color: var(--3485-aa, #3485aa);
          /* Heading 4 */
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 38.4px */
          margin-top: 4rem;
        }

        h5 {
          color: var(--3485-aa, #3485aa);
          text-align: right;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 18.7px */
          margin-top: 1rem;
          margin-bottom: 3rem;
        }
      }
      .invite {
        display: flex;
        justify-content: space-between;
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px; /* 85.714% */
        margin-bottom: 2rem;
        align-items: center;

        @media (max-width: 1200px) {
          flex-wrap: wrap;
        }

        div:last-child {
          display: flex;
          justify-content: flex-end;

          @media (max-width: 1200px) {
            justify-content: flex-start;
          }
        }

        > div {
          width: 23%;

          @media (max-width: 1200px) {
            width: 100%;
            margin-bottom: 10px;
          }

          button {
            box-shadow: none;
            width: 150px;
          }

          .resend {
            background: transparent;
            border-radius: 7px;
            border: 1px solid var(--hero-blue-0-b-455-f, #0b455f);
            box-shadow: none;
            color: var(--hero-blue-0-b-455-f, #0b455f);
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 16.8px */
          }
        }

        > .photo {
          width: 80px;
        }

        > .invite-name {
          display: flex;
          align-items: center;

          span {
            color: var(--hero-orange-ff-9-e-67, #ff9e67);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px; /* 85.714% */
            margin-left: 15px;
          }
        }

        button {
          &:disabled {
            opacity: 0.5;
          }
        }

        h3 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 28px */
        }
      }
      .emptyProgram {
        border-radius: 8px;
        background: #fff2eb;
        display: flex;
        width: 1094px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;

        h5 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
        }

        h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
        }

        button {
          box-shadow: none;
        }
      }
    }

    h3 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      /* Heading 4 */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }

    .left {
      width: 20%;
      margin-right: 2rem;

      @media (max-width: 1200px) {
        width: 100%;
        margin-right: 0;
      }

      .value {
        border-radius: 10px;
        background: rgba(52, 133, 170, 0.2);
        padding: 15px 20px;
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        margin-bottom: 1rem;

        .value-name {
          word-break: break-word;
        }

        &.active,
        &:hover {
          background: var(--hero-blue-0-b-455-f, #0b455f);
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 75%;

      @media (max-width: 1200px) {
        width: 100%;
        margin-right: 0;
      }

      .masonry-grid {
        margin-top: 2rem;

        img {
          border-radius: 10.2px;
          width: 100%;
        }
      }

      .header {
        border-radius: 10px;
        background: #0b455f;
        padding: 20px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        h3 {
          color: var(--white-ffffff, #fff);
          /* Heading 4 */
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 38.4px */
        }
        p {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 24px */
        }
      }
    }
  }
}
