.LearnPaths {
  padding: 40px;

  > h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #6c7275;
  }
  .currentPath {
    display: flex;
    background: #ffffff;
    border-radius: 24px;
    margin-top: 1rem;
    overflow: hidden;
    margin-bottom: 3rem;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .middle {
      padding: 20px;
      width: 100%;
      max-width: 600px;

      .progress {
        margin-top: auto;
        margin-bottom: 0px;
        width: calc(100% + 40px);
        background-color: rgba(255, 158, 103, 0.1);

        span {
          background-color: transparent;
          color: #3eac6a;
        }
        .progress-bar {
          height: 4px;
          background-color: #ff9e67;
        }
      }

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #013247;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15.0494px;
        line-height: 140%;
        color: #777777;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* or 20px */

        /* #013247 */

        color: #013247;

        opacity: 0.6;
        max-width: 500px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 40px;
      margin-left: auto;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 140%;
        margin-left: auto;
        margin-top: 1rem;
      }
    }

    .background {
      background-color: #fede66;
      padding: 10px 20px;
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
      }

      img {
        margin: 0px auto;
        display: block;
        margin-top: 1.2rem;
      }

      span {
        background-color: #fff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 12.6452px;
        line-height: 140%;
        padding: 6px 10px;
        text-align: center;
        border-radius: 5px;
        color: #2a7394;
        width: 120px;
        margin-top: 10px;
      }
    }
  }

  .otherPaths {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .smallCard {
    background-color: #fff;
    width: 32%;
    background: #ffffff;
    border: 0.940585px solid #ffffff;
    border-radius: 26px;
    overflow: hidden;
    padding-bottom: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      margin-left: 20px;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    &.soon {
      opacity: 1;
    }
    .background {
      background: rgba(11, 69, 95, 0.1);
      padding: 90px 20px;
      width: 100%;
      height: 140px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg,
      img {
        margin: 0px auto;
        display: block;
        width: 70px;
        height: 70px;
      }

      span {
        background: #0b455f;
        color: #fff;
        padding: 8px 10px;
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 20px;
        font-size: 12px;
        background: #0b455f;
        border-radius: 73.8462px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 10.3385px;
        line-height: 140%;
      }
    }
    span,
    h3,
    p {
      padding: 0px 20px;
      margin-top: 1rem;
    }

    > span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 11.287px;
      line-height: 140%;
      text-transform: uppercase;
      color: #000000;
      display: block;
      padding-top: 10px;
      color: #ff9e67;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      margin: 0;
      margin-bottom: 15px;
      color: #000000;
      margin-top: 0.2rem;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin: 0;
      color: #777777;
    }
  }
}
