@import "../../../theme.scss";


.ActivitySingle {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:0px 40px;
    max-width:90rem;
    position: relative;
    margin-top:30px;

    .activity_users {
        display:flex;
        width:100%;
        flex-wrap: wrap;
    }

    .white-background {
        margin-bottom:50px;
    }



    .pill {
        font-size:13px;
        padding:0;
        margin:0;
        background-color: $pink;
        color:#fff;
        padding:5px 15px;
        border-radius: 20px;
        font-weight: 800;
        display: inline-block;
        margin-bottom:20px;
        margin-top:0px;
    }

    .edit {
        color:$pink;
        margin-left:auto;
        display: block;
        text-align: right;
        margin-bottom:20px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .right {
        margin-left:auto;
        margin-bottom:20px;
    }


    .breadcrumbs {
        display: flex;

        a {
            color:$pink
        }

        font-weight: 600;

        div {
            margin-right:6px;
            margin-left:6px;

            &:first-child {
                margin-left:0;
            }
        }
    }

    h2 {
        width: 100%;
        margin-bottom:20px;
    }

    > p {
        font-size:16px;
    }

    .btn {
        margin-top:40px !important;
    }

    .row {
        display:flex;
        justify-content: space-between;

        .coach, .admin {
            margin-left:auto;
            margin-right:70px;

            h4 {
                width: 100%;
            }

            img {
                width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 100%;
            }
        }

        .admin {
            margin-left:0px;
            margin-right:0;
        }
    }

    .crew {
        .members {
            display:flex;

            .imageBox {
                margin-right:-10px;
                img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }
            
        }
    }

    .container {
        width: 100%;

        #async-example {
            background-color: white;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
            padding:10px;

            .dropdown-item {
                margin-bottom:15px;
                color:#000;
                padding:10px;
                img {
                    width: 30px;
                    height: 30px;
                    margin-right:5px;
                    border-radius: 100%;
                }
            }
        }

        h3 {
            margin:0;
            padding:0;
        }
        
        .inputBox {
            display: flex;
            flex-direction: column;
            margin-bottom:30px;
        }

        .lesson {
            background-color: #ececec;
            padding:20px;
            display: flex;
            margin-bottom:20px;
            border-radius: 15px;
            justify-content: space-between;

            .lesson-title {
                display:flex;
                align-items: center;
                margin-bottom:10px;

                .pill {
                    font-size:11px;
                    padding:0;
                    margin:0;
                    margin-left:15px;
                    background-color: $pink;
                    color:#fff;
                    padding:1px 15px;
                    border-radius: 20px;
                    font-weight: 800;
                }
            }

            .lesson-actions {
                display: flex;
                justify-content: flex-end;
                margin-left:40px;
                width: 270px;
                align-items: flex-start;

                button {
                    margin-left:10px;
                    height: 40px;
                }
            }
        }
    }
}