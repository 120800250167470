.Widget.Lifeline {
  width: 31.5%;
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0px;
  border-radius: 8px;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-right: 0;
  padding-bottom: 10px;

  &.Size-2 {
    width: calc(63% + 25px);
    padding-bottom:25px;

    canvas {
      height: 200px !important;
    }

    .activeMoment {
      padding: 0;

      .item {
        padding: 15px 20px;
        height: auto;
        background: #EBF3F7 !important;
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
      h4 {
        display: flex;
        align-items: center;
        color: var(--1-e-6484, #1E6484) !important;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 13.2px */
        text-transform: uppercase;
      }

      h2 {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-bottom:.5em;
        line-height: 110%; /* 17.6px */
        color: var(--hero-blue-0-b-455-f, #0B455F);

      }

      p {
        padding: 0;
        margin: 0;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        color:#155673;
      }
    }
  }

  .empty {
    display: flex;
    width: 201.945px;
    padding: 10px;
    align-items: center;
    gap: 9.972px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--white-ffffff, #fff);
    color: #155673;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.359px */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    background: var(--hero-yellow-fede-66, #fede66);
    margin-top:1rem;
    width:100%;


    &:hover {
      cursor: pointer;
      background-color: #155673;
      color: #fff;

      svg {
        fill: #fff;

        path,
        g {
          fill: #fff;
        }
      }
    }

    svg {
      background-color: transparent;
    }
  }

  > h2 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    font-family: Inter;
    font-size: 23.932px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.719px */
    position: relative;
    z-index: 2;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 0rem;
    margin-top: 20px;
    max-width: 100%;
    justify-self: flex-end;

    > button {
      width: 13px;
      height: 13px;
      background-color: #cedadf;
      position: relative;
      z-index: 10;
      border-radius: 100%;
      border: none;
      margin: 0px 2px;
      cursor: pointer;
      flex-shrink: 0;
      &.active,
      &:hover {
        background-color: #0b455f;
      }
    }

    .next,
    .prev {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      color: #fb9e67;
      margin: 0;
      padding: 4px;
      z-index: 15;
      position: relative;

      &:hover {
        color: darken(#fb9e67, 10);
        cursor: pointer;
      }
    }
  }

  .item {
    border-radius: 8px;
    padding: 20px;
    margin-top: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    word-break: break-word;
    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: none;
      padding: 0;
      margin-top: auto;

      h3 {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 15.4px */
      }
    }

    > h4 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 18.7px */
    }

    > h2 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
    }

    > p {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
    }

    &.happy {
      background: var(--3485-aa, #3485aa);
    }

    &.sad {
      background: var(--e-26-c-29, #e26c29);
    }

    svg {
      margin-top: 0.8rem;
      margin-bottom: 0.5rem;
    }
  }
}
