.highlightReel-share {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f4eeec;
  overflow: scroll;

  .yellow-bg {
    position: fixed;
    top: 20%;
    z-index: 0;
    left: -120px;
  }

  .blue-bg {
    position: fixed;
    top: 70%;
    z-index: 0;
    right: 0;
  }

  .content {
    max-width:90rem;
    padding: 20px 40px;
    margin: 0px auto;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 768px) {
      padding: 0px 20px;
    }

    .logo {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      img {
        @media screen and (max-width: 768px) {
          margin: 0px auto;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      .button {
        margin-left: auto;
        display: flex;
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 0px 0px;
          flex-wrap: wrap;
          margin-bottom: 2rem;
        }

        button {
          background: #0b455f;
          border-radius: 6.31579px;
          color: #fff;
          padding: 2px 20px;
          font-size: 1rem;
          margin-left: 1em;
          border: none;
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 1rem;
          }
        }
        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 30px;
          @media screen and (max-width: 768px) {
            text-align: center;
            margin: 0px auto;
          }
        }
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d1d1d1;
      padding-bottom: 3em;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .avatar {
        img {
          width: 200px;
          height: 200px;
          border-radius: 100%;
          border: 4px solid #005579;
        }

        @media screen and (max-width: 768px) {
          margin: 0px auto;
        }

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 900;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          margin-top: 0.5em;
        }
      }

      .infoBox {
        width: calc(100% - 250px);
        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 900;
          font-size: 44px;
          line-height: 52px;
          border: none;
          margin-bottom: 20px;

          @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 30px;
            line-height: 1.4em;
            margin-top: 1em;
          }

          &:after {
            display: none;
          }
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 300;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }

    .highlights {
      margin-top: 3em;
      h3 {
        margin-bottom: 2rem;
        span {
          font-size: 1.5rem;
        }
      }

      .grid {
        display: block;
        justify-content: space-between;
        flex-wrap: wrap;

        .holder {
          background: #0b455f;
          border: 0.768579px solid #aaaaaa;
          border-radius: 6.17431px;
          padding: 80px 40px !important;
          color: #fff;

          h2 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 900;
            font-size: 30px;
            line-height: 40px;
            color: #fff;
            margin-bottom:.5em;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 300;
            font-size: 17.5469px;
            line-height: 26px;
          }

          button {
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            background: #fede66;
            border-radius: 6.31579px;
            border: none;
            padding: 10px 30px;
            &:hover {
              background: darken(#fede66, 20%);
              cursor: pointer;
            }
          }
        }
        .single-highlight {
          background: #ffffff;
          border: 0.768579px solid #aaaaaa;
          border-radius: 6.17431px;
          padding: 30px;
          margin-bottom: 0rem;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          img {
            width: 100%;
            max-width: 100%;
          }

          a {
            border-bottom: 2.95567px solid #a2def8;
            color: #000;
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 11.8227px;
            line-height: 12px;
            border-radius: 0px;
            padding: 2px;
            color: #0d8cc2;

            &:hover {
              color: darken(#0d8cc2, 20);
              cursor: pointer;
            }
          }

          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 30px;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 300;
            font-size: 17.5469px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
