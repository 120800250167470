.profile {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 60px;


  @media (max-width: 768px) {
    padding: 0px;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13.375px;
    line-height: 13px;
    color:#000;
  }

  .dashboard__header {
    color:#fff;
    justify-content: center;
    display:flex;
    flex-wrap:wrap;
    margin-bottom:30px;

    .empty {
        margin-top:17px;
        width:140px;
        height:40px;
    }

    h3 {
      color:#000;
      width:100%;
      text-align: center;
      margin:0;
      padding:0;
      margin-top:0px;
      font-size:18px;
      font-weight: 500;
    }

    img {
      border-radius: 100%;
      height:141px;
      border:3px solid #fff;
      margin-bottom:12px;
      width: 141px;
      object-fit: cover;
    }
  }

  .crew_container {
    display: flex;
    margin-bottom: 50px;
    overflow-x: scroll;

    img {
      border-radius: 100%;
      border-width: 3px;
      border-style: solid;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 5px;
      border-image-source: linear-gradient(to left, #fff6b7, #f6416c);
    }

    .crew {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-right: 20px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13.375px;
        line-height: 13px;
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  .stories {
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:after {
      content: "";
      flex:auto;
    }

    @media (max-width: 768px) {
      display: block;
      margin: 0px auto;
    }

    .story {
      padding-bottom:20px;
      flex-wrap: wrap;
      width:25%;
      padding-right:20px;

      @media (max-width: 1100px) {
        width: 33%;
      }

      @media (max-width: 990px) {
        width: 100%;
        margin-right:0;
        padding-right:0;

        video {
          width: 100% !important;
        }
      }
    }
  }

  .selector {
    display: flex;
    border-radius: 8px;
    padding: 5px 5px;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;


    @media (max-width: 768px) {
      border-radius: 0px;
    }


    h3 {
      margin: 0;
      padding: 0;
      margin: 0px 10px;
      cursor: pointer;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16.5239px;
      line-height: 19px;
      text-align: center;
      padding: 10px 20px;
      color:#fff;
      opacity: .7;

      &.active,
      &:hover {
        opacity: 1;
        border-bottom:2px solid #fff;
      }
    }
  }

  p {
    font-size: 16px;
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 30px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}
