.careerInput {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #f4eeec;
  height: 100%;
  width: 100%;

  

  .modal {
    .modal-content {
      padding: 40px;
      h2 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 1em;
      }

      

      .boxes {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        .box {
          width: 30%;
          border-radius: 10px;
          background: var(--3485-aa, #3485aa);
          display: flex;
          padding: 25px 47px 152px 25px;
          flex-direction: column;
          align-items: center;

          h4 {
            color: var(--white-ffffff, #fff);

            /* Heading 6 */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
          }
        }
      }
    }
  }

  .bottom {
    border-top:2px solid #ececec;
    button {
      width:100%;
    }
  }

  .inputForm {
    display: flex;
    flex-direction: column;

    .connections {
      margin-top: 1rem;
      max-height: 500px;
      overflow: scroll;

      span {
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 18px */
        margin-bottom:1rem;
        display: block;
      }

      .connection {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background: rgba(52, 133, 170, 0.15);
        display: flex;
        width: 350px;
        padding: 15px 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        margin-bottom: 1rem;
        cursor: pointer;

        &:hover {
          background: rgba(52, 133, 170, 0.35);
        }

        p {
          padding: 0;
          margin: 0;
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 18px */

          &:nth-child(2) {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            opacity: 0.5;
          }
        }
      }
    }

    .top {
      justify-content: space-between;
      display: flex;

      span {
        color: var(--2-a-7394, #2a7394);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-decoration-line: underline;
      }
    }

    button {
      width: 100px;
      margin-left: auto;
      margin-top: 1rem;
    }

    label {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 18.7px */
    }
  }

  .right {
    .white-background {
      border: none;

      .boxes {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;


        .empty {
          border-radius: 10px;
          border: 2px dashed #2a7394;
          width: 24%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;
          h4 {
            color: var(--013247, #013247);
            text-align: center;

            /* Small Body */
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
          }
        }

        .box {
          border-radius: 10px;
          display: flex;
          width: 24%;
          height: 260px;
          padding: 20px;
          flex-direction: column;
          align-items: flex-start;
          height: 200px;
          position: relative;

          .delete {
            position: absolute;
            top:10px;
            right:10px;
            cursor: pointer;

            svg {
              color:#fff;
              opacity: .5;

              &:hover {
                opacity: 1
              }
            }
          }

          h3 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
          }
        }
      }

      .top {
        h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 22.332px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 24.565px */
        }
      }

      p {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
        margin-top: 1rem;
        border-bottom: 2px solid #ececec;
        padding-bottom: 2rem;
      }
    }
  }
}
