#strengthInviteModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 4;
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  

  > .left {
    width: 30%;
    border: none;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: scroll;

    @media (max-width: 768px) {
      width: 100%;
    }

    @media (max-width: 1200px) {
      height: calc(100vh - 80px);
    }

    h2 {
      svg {
        margin-right:1rem;
      }
    }

    .group {
      display: flex;
      justify-content: flex-start;

      button {
        background-color: transparent;
        border: none;
        padding: 8px 40px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 140%;
        cursor: pointer;
        margin: 0;
        text-align: center;
        color: #0b455f;
        margin-right: 1rem;
        margin-top: 0.5rem;

        &.active,
        &:hover {
          background: #2a7394;
          border-radius: 99px;
          color: #fff;
        }
      }
    }

    .actions {
      display: flex;
      margin: 0;
      padding: 0;
      padding: 20px;
      justify-content: space-between;
      border-top: 1px solid #ececec;
      button {
        margin: 0;
      }
    }

    .success {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      color: #013247;
      margin-left: 20px;
      margin-top: 1em;
      margin-bottom: 1em;
      display: flex;
      align-items: center;

      svg {
        background: #47884f;
        border-radius: 50%;
        color: #fff;
        padding: 10px;
        margin: 0;
        padding: 0;
      }
    }

    .inviteList {
      margin-top: 3rem;
      border-top: 2px solid #ececec;
      padding: 20px 0px !important;
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 20px !important;
      max-height: 200px;
      overflow: scroll;

      .inviteContainer {
        display: flex;
        justify-content: space-between;
      }

      p {
        color: #013247;
        font-weight: 600;
        opacity: 0.6;
      }
    }

    button {
      margin-left: auto;
      margin-right: 20px;
    }

    .inputField {
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.4rem;
      label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        color: #0b455f;
        margin-bottom: 0.5rem;
      }

      input,
      textarea {
        background: #ffffff;
        border: 1px solid #f4eeec;
        box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36);
        border-radius: 7px;
      }
    }

    > h2 {
      border-bottom: 2px solid #ececec;
      padding: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      color: #013247;
      margin-bottom: 1rem;
    }
  }

  > .right {
    width: 70%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #f4eeec;
    height: 100vh;
    overflow: scroll;
    margin-bottom: 2rem;

    .bubbleContainer {
      display: flex;
      .left {
        border: none;
        padding-right: 0;
        flex: none;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          flex: 1;
          flex-grow: 1;
        }
      }
      .right {
        padding-left: 20px;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 25.6px;
          line-height: 31px;
          color: #000000;
        }
        p {
          background: #fede66;
          padding: 20px;
          opacity: 1;
          border-radius: 0px 24px 24px 24px;
        }
      }
    }

    .email {
      background-color: #fff;
      width: 80%;
      padding: 30px;
      background: #ffffff;
      border-radius: 24px;
      margin: 0px auto;

      > h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        color: #013247;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #013247;
        opacity: 0.6;
        margin-top: 1rem;
      }

      .emailContent {
        .blueBox {
          background-color: #dcf4ff;
          padding: 30px;

          button {
            width: 100%;
          }
        }

        .emailHeader {
          background-color: #57c5f2;
          height: 140px;
          width: 100%;
          margin-bottom: 2em;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 80px;
            height: 80px;
          }
        }
        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24.5999px;
          line-height: 20px;
          color: #013247;
          border: none;
          padding: 0;
        }
        p {
          color: #000;
          opacity: 1;

          b {
            display: block;
          }
        }
      }
    }
  }
}
