.Widget {
  background-color: white;
  border-radius: 24px;
  margin-bottom: 3rem;
  width: 32%;
  margin-right: 2%;
  margin-top:2rem;
  display: flex;
  flex-direction: column;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &.full {
    width: 100%;
  }

  @media (max-width: 1300px) {
    width: 48% !important;
    margin-right: 0;
  }

  @media (max-width: 990px) {
    width: 100% !important;
  }
  .top {
    display: flex;
    padding: 5px 40px;
    margin-bottom: 0rem;
    align-items: center;

    .icon img {
      width: 40px;
      height: 40px;
      border: 1px solid #aaaaaa;
      padding: 5px;
      border-radius: 5px;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 2rem;
      line-height: 50px;
      margin-left: 10px;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20.1766px;
      line-height: 130%;
      display: flex;
      align-items: flex-end;
      color: #0b455f;
      margin-top:1em;
    }
  }

  .middle {
    display: flex;
    overflow: scroll;
    height: 300px;
  }

  .bottom {
    border-top:2px solid #ececec;
    display:flex;
    align-items: center;
    padding:20px;
    margin:0;
    justify-content: flex-end;

    button {
      margin:0;
    }
  }

}
