.Widget.HighlightReel {
  width: 31.5%;
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0px;
  border-radius: 8px;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-right: 0;
  align-self: stretch;

  > h2 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    font-family: Inter;
    font-size: 23.932px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.719px */
    position: relative;
    z-index: 2;
  }
  .empty {
    display: flex;
    width: 201.945px;
    padding: 10px;
    align-items: center;
    gap: 9.972px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--white-ffffff, #fff);
    color: #155673;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.359px */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    background: var(--hero-yellow-fede-66, #fede66);
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: #155673;
      color: #fff;

      svg {
        fill: #fff;

        path,
        g {
          fill: #fff;
        }
      }
    }

    svg {
      background-color: transparent;
    }
  }

  &.Size-1 {
    padding-bottom: 10px;

    .navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-right: 10px;
      margin-bottom: 0rem;
      margin-top: 20px;
      width: 100%;
      justify-self: flex-end;
      overflow: scroll;
      flex-shrink: 1;

      > button {
        width: 13px;
        height: 13px;
        background-color: #cedadf;
        position: relative;
        z-index: 10;
        border-radius: 100%;
        border: none;
        margin: 0px 2px;
        cursor: pointer;
        flex-shrink: 0;
        &.active,
        &:hover {
          background-color: #0b455f;
        }
      }
    }

    .item {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 20px;
      min-height: 280px;
      border: 5px solid #fcde66;
      background-size: cover !important;
      background-position: center !important;
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-radius: 10px;
      word-break: break-word;
      h4 {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4em;
        z-index: 2;
        position: relative;
      }
      p {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 13;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4em;
        z-index: 2;
        position: relative;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        z-index: 1;
      }
    }
  }

  &.Size-2 {
    width: calc(63% + 25px);
    padding-right: 20px;
    padding-bottom: 2rem;
    height: 100%;

    .empty {
      margin-top: 1rem;
    }

    .items {
      display: flex;
      padding: 0px 20px;
      padding-right: 0;
      width: 100%;
      overflow-x: scroll;
      background-color: #fcde66;
      border-radius: 4px;
      margin-top: 1rem;
      padding-bottom: 15px;
      height: 80%;

      &::-webkit-scrollbar {
        display: block; /* Safari and Chrome */
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none !important;
        width: 7px !important;
        height: 9px !important;
        margin-top: 2rem;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 7px !important;
        background-color: rgba(0, 0, 0, 0.5) !important;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
        margin-top: 15px;
      }

      .item {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        padding: 20px;
        min-height: 230px;
        max-height: 240px;
        border: 5px solid #fff;
        background-size: cover !important;
        background-position: center !important;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 65%;
        flex-shrink: 0;
        margin-right: 15px;
        border-radius: 10px;
        word-break: break-word;
        overflow: scroll;
        img {
          height: 100%;
        }

        &:last-child {
          margin-right: 20px;
        }
        h4 {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 19px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.4em;
          z-index: 2;
          position: relative;
        }
        p {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 13;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4em;
          z-index: 2;
          position: relative;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.75);
          z-index: 1;
        }
      }
    }
  }
}
