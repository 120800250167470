.lifelineMoment {
  padding-top: 1rem;
  overflow: hidden;
  margin-top: -40px;

  .fullSlide {
    background-color: #521e86;
    padding: 50px 0px;
    background-image: radial-gradient(
      circle at center,
      /* dot color */ rgb(140, 68, 213) 2px,
      rgb(140, 68, 213) 0,
      /* background color */ rgb(82, 30, 134) 2px,
      rgb(82, 30, 134) 100%
    );

    background-repeat: repeat;
    background-position: left center;
    background-size: 20px 20px;
    overflow: hidden;

    .add {
      transform: translateY(75px);
      margin: 0px auto;
    }
  }

  .container {
    max-width:90rem;
  padding:0px 40px;
    margin: 0 auto;
    margin-bottom: 20px;

    .btn {
      padding: 12px 24px;
    }
  }

  .content {
    background: #ffffff;
    max-width:90rem;
  padding:0px 40px;
    border: 0.5px solid #aaaaaa;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 40px;
    display: flex;
    margin: 0px auto;
    position: relative;
    min-height: 300px;
    flex-wrap: wrap;

    .actions {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .blank {
        background-color: transparent;
        color: #ff67a7;

        &:hover {
          color: #fff;
          background-color: #ff67a7;
          border-color: #ff67a7;
        }
      }

      .btn {
        margin-right: 20px;
        padding: 12px 24px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 18px;
      }
    }

    .border {
      background: #d9d9d9;
      height: 1px;
      width: 100%;
      margin-top: 50px;
    }

    .year {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #58c5f3;
    }

    .highlight {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 0px;
      margin-top: 20px;
      &.personal {
        background-color: #88d57b;
      }

      &.professional {
        background-color: #58c5f3;
      }
    }

    .bodyText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }
    .emojiBox {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      top: 0px;
      left: 0;
      height: 70px;
      overflow: hidden;
      border-radius: 6px;

      &.personal {
        background-color: #c1f4b8;
      }

      &.professional {
        background-color: #a2def8;
      }

      .emoji {
        cursor: pointer;
        font-size: 3.3rem;
        height: 3.5rem;
        width: 3.5rem;
      }
    }

    .underline {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 36px;
    }

    .form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      textarea {
        height: 160px;
      }

      label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }

      .btn {
        width:200px;
        margin-top:50px;
      }
    }

    .insights {
      width: 100%;
      margin-top: 30px;

      .insight {
        display: flex;
        margin-bottom: 40px;

        .svg {
          svg {
            margin-right: 30px;
            color: #ffde67;
            font-size: 37px;
          }
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
        }
        .date {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          /* identical to box height, or 100% */

          /* blue300 */

          color: #58c5f3;
        }
      }
    }
  }
}
