.onboardingHome {
  display: flex;
  background-color: #f4eeec;
  .sidebar {
    width: 400px;
    height: 100vh;
    @media (max-width: 1200px) {
      height: calc(100vh - 80px);
    }
    background-color: #013247;
    padding: 40px 20px;

    @media (max-width: 768px) {
      display: none !important;
    }

    .accent {
      position: absolute;
      bottom:0;
      left:0;
    }

    img {
      margin: 0px auto;
      margin-top: 20px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 2rem;

      li {
        color: #fff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        align-items: center;
        opacity: .5;

        &.active {
          opacity: 1;
        }

        .dot {
          border: 1.5px solid #ffffff;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;


          &.active {
            border: 1px solid #FEDE66;

            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
