.CoachingDashboard {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;
  padding: 10px 80px;

  h2 {
    font-size: 2rem;
    margin-bottom: 0rem;
    width: 100%;
  }

  .white-background {
    width: 47%;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    h5 {
      margin-bottom: 2rem;
      color: #013247;
    }

    &.usersContainer {
      width: 100%;
    }
  }

  .users {
    display: flex;
    margin-top: 1.5rem;
    display: flex;
    overflow-x: auto;
    flex-shrink: 0;
    padding-bottom: 1.5rem;

    &::-webkit-scrollbar {
      display: block; /* Safari and Chrome */
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none !important;
      width: 7px !important;
      height: 9px !important;
      margin-top: 2rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 7px !important;
      background-color: rgba(0, 0, 0, 0.5) !important;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
      margin-top: 15px;
    }

    @media screen and (max-width: 990px) {
      flex-direction: column;
    }

    .user {
      width: 250px;
      border-radius: 10.286px;
      background: var(--hero-yellow-fede-66, #fede66);
      padding: 20px;
      margin-right: 2rem;
      display: flex;
      flex-shrink: 0;
      align-self: stretch;
      flex-direction: column;

      .email {
        font-size: 0.8em;
        margin-top: auto;
      }

      &.coach {
        background: var(--3485-aa, #3485aa);
        .icon {
          border-radius: 51.429px;
          background: var(
            --155673,
            linear-gradient(0deg, #155673 0%, #155673 100%),
            #ecddd8
          );
        }

        h2,
        h3 {
          color: #fff !important;
        }
      }

      @media screen and (max-width: 990px) {
        width: 100%;
        margin-bottom: 20px;
      }

      .icon {
        border-radius: 51.429px;
        background: #fff8dc;
        display: flex;
        width: 41.143px;
        height: 41.143px;
        padding: 8.229px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: relative;

        img {
          object-fit: cover;
          border-radius: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 41.143px;
          height: 41.143px;
        }
      }

      .name {
        height: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 24.686px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 29.623px */
          margin-top: 10px;
          text-transform: capitalize;
        }

        h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21.6px */
          margin-top: 10px;
          width: 100%;
          display: block;
          margin-bottom: 1.3rem;
        }

        .btn {
          border-radius: 8.229px;
          background: #fff8dc;
          padding: 12px 30px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10.286px;
          flex-shrink: 0;
          border: none;
          color: #155673;
          text-align: center;
          font-family: Inter;
          font-size: 12.343px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 14.811px */
          margin-top: auto;
          cursor: pointer;
          justify-self: flex-end;

          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .activeCrews {
    .coach {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin-bottom: 1rem;

      &.header {
        border-bottom: 2px solid #ececec;
        padding-bottom: 0.2em;
        margin-bottom: 0.5rem;
        opacity: 0.5;
        p {
          font-weight: bold;
        }
      }

      button {
        border: none;
        box-shadow: none;
        padding: 10px;
      }

      > div {
        width: 20%;
      }
    }

    p {
      margin: 0;
      width: 40%;
    }
  }
}
