@import "../../../theme.scss";

.PlatformStats {
  display: flex;
  padding-top: 30px;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  padding: 30px 40px;

  .filters {
    width: 100%;
    display: flex;

    input {
      border-radius: 5px 0px 0px 5px;
    }

    button {
      margin-left: -4px;
      border-radius: 0px 5px 5px 0px;
      box-shadow: none;
    }
  }

  .singleStat {
    min-height: 300px;
  }

  h2 {
    width: 100%;
    display: inline-block;
    margin-bottom: 0px;
    font-size: 24px;
  }

  .back {
    color: #000;

    a {
      color: #000;
    }
  }

  .subtitle {
    margin-top: 3rem;
  }
  .session {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    width: 30%;

    .percent {
      font-size: 56px;
      margin: auto;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .attendance {
      margin-top: auto;
      padding-top: 2rem;
    }

    .grid {
      display: flex;
      align-items: center;
      background-color: #013247;
      border-radius: 15px;
      margin-bottom: 2rem;

      > div {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 15px 0px;

        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 1rem;
          color: #fff;
          margin: 0;
          padding: 0;
        }

        p {
          font-weight: 200;
          margin-top: 0.5em;
          margin: 0;
          padding: 0;
        }
      }
    }

    .crewsStats {
      h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      ul {
        padding: 0;
        margin: 0;
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          list-style: none;
          width: 45%;
          margin-bottom: 0.5em;
        }
      }
    }
  }
}
