.CollabDiagram {
  display: flex;

  .sidebar {
    width: 400px;
    height: 100vh;
    @media (max-width: 1200px) {
      height: calc(100vh - 80px);
    }
    background-color: #fff;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .inner {
    > h3 {
      font-size:1em;
      margin-bottom:1rem;
      margin-top:2em;
    }
    input {
      width: 100%;
    }
  }

    button:disabled {
      opacity: 0.5;
    }

    form {
      position: relative;
      span {
        position: absolute;
        top:15px;
        right:20px;
        color: #3485aa;
        font-weight: bold;
        font-size:12px;
        cursor: pointer;
      }
    }

    .addBox {
      display: flex;
      height: 56px;
      padding: 20px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 7px;
      background: #ebf3f7;
      margin-top: 20px;
      cursor: pointer;

      &:hover {
        background: darken(#d6e9f0, 10);
      }

      h5 {
        color: #0b455f;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
      }
    }

    .questions {
      margin-bottom: 20px;

      .collabTitle {
        color: #cfa400;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.539px; /* 118.135% */
        margin-top: 2rem;
      }

      .entry {
        display: flex;
        padding: 20px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 7px;
        border: 1px solid #f4eeec;
        background: var(--ffffff, #fff);
        box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
        margin-top: 20px;

        &.collab {
          border-radius: 7px;
          border: 2px dashed #e4c037;
          background: var(--ffffff, #fff);
          margin-top: 10px;


        }

        svg {
          margin-left: auto;
          opacity: 0.3;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }

        p {
          color: #0b455f;
          margin: 0;
          padding: 0;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 21px */
        }
      }
      h4 {
        color: var(--0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
      }

      h5 {
        color: #0b455f;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.539px; /* 118.135% */
        margin-top: 5px;
      }

      input {
        display: flex;
        height: 56px;
        padding: 20px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 7px;
        border: 1px solid #f4eeec;
        background: var(--ffffff, #fff);
        box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
        width: 100%;
        margin-top: 10px;
      }
    }

    .bottom {
      margin-top: auto;
      padding: 20px;
      border-top: 1px solid #e6eaed;

      .blank {
        display: flex;
        width: 163.063px;
        padding: 14px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: #f0f2f5;
        border: none;
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
      }

      .secondary {
        display: flex;
        width: 178.526px;
        padding: 14px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
      }
    }

    .careerRole {
      margin: 20px 10px;

      > p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 21.6px */
        opacity: 0.5;
        margin-left: 1.9rem;
        margin-top: 0.5rem;
      }

      .content {
        display: flex;
        align-items: center;

        svg {
          margin-left: auto;
          cursor: pointer;
        }

        h4 {
          color: #0b455f;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
          padding: 0;
          margin: 0;
        }
        .circle {
          cursor: pointer;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          border: 2px solid #0b455f;
          margin-right: 1rem;

          &.active {
            background-color: #0b455f;
          }
        }
      }
    }

    .title {
      display: flex;
      padding: 15px;
      border-bottom: 2px solid #ececec;

      svg {
        margin-right: 1em;
        opacity: 0.7;
      }

      h3 {
        color: #013247;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
    }

    .inner {
      padding: 15px 25px;
      height: calc(100% - 100px);
      overflow: scroll;
    }

    .subtitle {
      padding-bottom: 1rem;

      .opaque {
        opacity: 0.5;
        margin-top: 1rem;
      }

      h4 {
        color: var(--0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
        margin-bottom: 0.5rem;
      }

      p {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        padding: 0;
        margin: 0;
      }
    }

    .prompts {
      text-align: left;

      .prompt {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #ececec;
        padding-bottom: 1rem;

        &:last-child {
          border-bottom: 0px;
        }

        .expanding {
          display: none;
          gap: 15px;
          flex-direction: column;

          .box {
            border-radius: 9.535px;
            background: #eaf1f4;
            display: flex;
            padding: 25px 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;

            &.yellow {
              background: #fff8e0;

              &.selected {
                background: darken(#fff8e0, 20%);
              }
            }

            &.red {
              background: #fff2eb;
              &.selected {
                background: darken(#fff2eb, 20%);
              }
            }

            &.selected {
              background: darken(#eaf1f4, 20%);
            }

            p {
              padding: 0;
              margin: 0;
              color: #0b455f;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
            }
          }
        }

        &.expanded {
          margin-bottom: 1rem;
          .expanding {
            display: flex;
          }
        }

        .title {
          text-align: left;
          display: flex;
          flex-direction: column;
          border: none;
          padding: 0;
          position: relative;

          span {
            color: var(--3485-aa, #3485aa);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
          }

          h3 {
            color: var(--013247, #013247);

            /* Button Body */
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
          }

          p {
            color: #0b455f;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
            margin-top: 10px;
          }
        }
      }
    }
  }

  .right {
    width: 100%;

    .react_fast_diagram_Background {
      background: #f4eeec !important;
    }
    .secondComp {
      display: flex;
      padding: 15px 25px;
      flex: 1 0 0;
      border-radius: 7px;
      background: #fff;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      flex-direction: column;

      &.collab {
        border-radius: 6.18px;
        border: 2px dashed #e1a325;
        box-shadow: 0px 3.531px 22.071px 0px rgba(0, 0, 0, 0.1);
        background-color: transparent;
        h4 {
          font-size:11px;
          color:rgba(0, 0, 0, 0.4);
          margin-left: auto
        }
      }

      h3 {
        color: #0b455f;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 21.6px */
      }
      p {
        color: rgba(11, 69, 95, 0.5);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 17.6px */
        padding: 0;
        margin: 0;
      }
    }

    .firstComp {
      display: flex;
      padding: 15px 25px;
      flex: 1 0 0;
      border-radius: 7px;
      background: #fff;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      flex-direction: column;

      h4 {
        color: #3485aa;

        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 17.6px */
      }

      h3 {
        color: #0b455f;
        text-transform: capitalize;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
      p {
        color: rgba(11, 69, 95, 0.5);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 17.6px */
        padding: 0;
        margin: 0;
      }
    }

    .react_fast_diagram_LinkDefault_Line {
      stroke: #e1c7be;
    }
  }
}
