.privacyPolicy {
    padding:40px;


    a {
        color: black;
        text-decoration: underline;
    }
    h2, h2, h3, h1 {
        margin-bottom: 20px;
        font-size:1.3rem;
    }
}