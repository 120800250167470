.membershipApplication {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .alert {
    background-color: #ff6767;
    color: #fff;
    padding: 15px 25px;
    text-align: left;
    margin-bottom: 2rem;
    border-radius: 10px;

    p {
      padding:0;
      margin:0;
      margin-top:0 !important;
    }
  }

  .white-background {
    padding-top:60px;
  }

  &.company {
    .discountCode {
      border-bottom: 0 !important;
    }

    button:disabled {
      opacity: 0.4;
      border: none;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  .links {
    display: flex;
    margin-bottom: -1rem;
    flex-wrap: wrap;
  }

  a {
    color: #0b455f;
    margin-top: 2rem;
    font-weight: BOLD;
    display: block;
    text-decoration: underline;
    margin-bottom: 0rem;
    margin-right: 2rem;
    &:hover {
      color: #0b455f;
      cursor: pointer;
    }
  }

  .modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .skrim {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0;

      .modal-bottom {
        padding: 0px 50px;
        padding-bottom: 30px;

        button {
          margin-left: auto;
        }

        button:disabled {
          opacity: 0.4;
          border: none;
        }
      }

      .modal-main {
        padding: 50px;
        padding-bottom: 40px;
        padding-top: 20px;

        .error {
          background-color: #ff6767;
          color: #fff;
          padding: 5px;
          text-align: center;
          margin-bottom: 10px;
          border-radius: 10px;
        }

        a {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 48px;
          cursor: pointer;
          text-align: center;
          text-decoration-line: underline;
          color: #2a7394;
        }
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 34px;
          color: #0b455f;
          text-align: center;
          margin-top: 2rem;
          margin-bottom: 2.5rem;
        }

        form {
          margin-top: 1rem;
          label {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
            color: #0b455f;
          }

          input {
            background: #ffffff;
            border: 1px solid #f4eeec;
            box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36);
            border-radius: 7px;
            margin-top: 10px;
          }
        }
      }

      .modal-header {
        display: flex;
        width: 100%;
        text-align: center;
        padding-top: 20px;
        justify-content: center;
        border-bottom: 0.5px solid rgba(170, 170, 170, 0.46);
        svg {
          color: #aaaaaa;
          position: absolute;
          top: 15px;
          right: 20px;
          font-size: 1.5rem;
          cursor: pointer;
        }
        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 900;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          text-align: center;
          color: #0b455f;
          padding-bottom: 20px;
        }
      }
    }
  }

  .btn.link {
    background: none;
    border: none;
    color: #0d8cc2;
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 16px;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline-block;
    margin-top: -2.5em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0em;
    transition: all 0.2s ease-in-out;
    float: right;
    text-decoration: none;

    &:hover {
      color: darken(#0d8cc2, 15);
    }
  }

  .left {
    background-color: #013247;
    min-height: 100vh;
    width: 32%;

    @media screen and (max-width: 768px) {
      display: none;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    padding: 50px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 30px 30px;
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 2rem;
      line-height: 2.2rem;
      color: #fff;
      margin-top: 0.5em;
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 48px;
    }

    p {
      color: #fff;
      margin-top: 30px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: #fff;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    svg {
      margin-bottom: 0.5em;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 2em;
      li {
        display: flex;
        color: #fff;
        margin-bottom: 1em;
        &:before {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" style="fill:black"/></svg>');
          color: #fff;
          width: 1em;
          height: 1em;
          padding: 0.3em;
          background-color: #88d57b;
          display: block;
          border-radius: 100%;
          margin-right: 1em;
          flex-basis: 1;
          flex-basis: 1em;
          flex-grow: 0;
          flex-shrink: 0;
        }
      }
    }
  }

  .right {
    width: 64%;
    padding: 30px;
    padding-right: 5%;

    h3 {
      background-color: transparent;

      span {
        background-color: transparent;
        padding: 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        /* identical to box height, or 29px */

        /* #0B455F */

        color: #0b455f;
      }
    }

    .price {
      margin-top: 2em;
      margin-bottom:2rem;
      h3 {
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        font-family: "Inter";
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 1em;
        align-items: center;

        span {
          margin-left:1rem;
        }
      }
    }

    .discountCode {
      border-top: 2px solid #ececec;
      border-bottom: 2px solid #ececec;
      padding: 20px 0px;
      margin-top: 2em;
      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        cursor: pointer;

        svg {
          margin-left: 1em;
          color: #58c5f3;
        }
      }

      .code {
        margin-top: 1em;
        width: 100%;
        display: flex;

        .error {
          background-color: red;
          padding: 0.5em;
          text-align: center;
          color: #fff;
          border-radius: 10px;
          margin-bottom: 1em;
          width: 100%;
        }
        input {
          width: 100%;
          height: 50px;
          background: #ffffff;
          border: 1px solid #ececec;
          box-sizing: border-box;
          border-radius: 4px 0px 0px 4px;
          padding: 0px 10px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
        }
        button {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 2em;
      li {
        display: flex;
        color: #000;
        margin-bottom: 1em;
        &:before {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" style="fill:black"/></svg>');
          color: #fff;
          width: 1em;
          height: 1em;
          padding: 0.3em;
          background-color: #88d47b;
          display: block;
          border-radius: 100%;
          margin-right: 1em;
          flex-basis: 1;
          flex-basis: 1em;
          flex-grow: 0;
          flex-shrink: 0;
        }
      }
    }

    .white-background {
      margin-top: 20px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 30px 10px;

      .white-background {
        margin-top: 0em !important;
      }

      .white-background {
        padding: 10px;
        width: 100%;
        box-shadow: none;
        border: none;
        padding-bottom: 4rem;
      }
    }
    p {
      margin-top: 1em;
    }

    .inputBoxes {
      display: flex;
      justify-content: space-between;
      .inputBox {
        width: 45%;
        display: flex;
        flex-wrap: wrap;

        label {
          width: 100%;
          margin: 0;
          padding: 0;
        }

        input {
          width: 100%;
          background: #f3f3f3;
          /* blue300 */

          border: 1px solid #58c5f3;
        }
      }
    }
    .inputBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2em;

      &.radioBox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .radioBox {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          align-items: center;

          input {
            width: 20px;
            margin-right: 1em;
          }
        }
      }

      label {
        width: 100%;
        margin: 0;
        padding: 0;

        span {
          text-align: right;
          float: right;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 20px;
          text-align: right;
          color: #aaaaaa;
        }
      }

      input {
        width: 100%;
        background: #f3f3f3;
        border: 1px solid #58c5f3;
      }
    }
  }
}
