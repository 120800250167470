.Widget.Visions {
  width: 31.5%;
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0px;
  border-radius: 8px;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-right: 0;
  padding-bottom: 10px;
  align-self: stretch;

  .slides {
    display: flex;
    overflow: scroll;
    gap: 1rem;
    .box {
      width: 250px;
    }
  }

  .box {
    flex-shrink: 0;
    width: 100%;
    height: 400px;
    border-radius: 6.795px;
    background: #fff;
    border: 10px solid #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    border: 1px solid #eaf1f4;
    border-radius: 13.541px;
    width: 100%;
    padding: 15px;
    height: 400px;
    margin-top: 1rem;

    .inside {
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
      background-color: #fff;
      height: 100%;
      border-radius: 10px;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: scroll;
      word-break: break-word;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 0;

        span {
          margin-bottom: 0;
          margin-top: 1rem;
        }
        button {
          padding: 0;
          color: var(--3485-aa, #3485aa);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 16.5px */
          margin: 0;

          svg {
            margin-left: 0.5em;
          }
        }
      }

      h4 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 11.956px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 14.347px */
      }

      h2 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 19.129px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 22.955px */
        opacity: 1;
        margin: 0;
        margin-top: 1rem;
        word-wrap: break-word;
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 12.753px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 15.941px */
        opacity: 1;
      }

      &.growth {
        background: #eaf1f4;

        span {
          background: var(--3485-aa, #3485aa);
        }
      }

      &.relationships {
        background: #fff8e0;

        span {
          background: var(
            --e-4-c-037,
            linear-gradient(0deg, #e4c037 0%, #e4c037 100%),
            #ecddd8
          );
        }
      }

      &.impact {
        background: #fff2eb;

        span {
          background: var(
            --f-48-a-4-e,
            linear-gradient(0deg, #f48a4e 0%, #f48a4e 100%),
            #ecddd8
          );
        }
      }
    }

    &.nonActive {
      opacity: 0.2;

      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    img {
      max-height: 200px;
      width: auto;
      align-self: flex-start;
      margin: 0px auto;
      margin-top: auto;
    }

    h4 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 18px */
      opacity: 0.5;
    }

    h2 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      opacity: 0.5;
      word-wrap: break-word;
    }

    span {
      display: flex;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: var(--3485-aa, #3485aa);
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 13.2px */
      width: auto;
      flex-grow: 0;
      flex-grow: 0;
      align-self: flex-start;
      margin-bottom: 1rem;
    }

    p {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      opacity: 0.5;
      word-wrap: break-word;
    }
  }

  .coverBox {
    border-radius: 16.988px;
    background: #fff;
    width: 100%;
    padding: 10px;
    height: 400px;

    .inside {
      border-radius: 10px;
      background: rgba(42, 115, 148, 0.5);
      padding: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-position: center;
      background-size: cover;
      position: relative;

      .content {
        z-index: 50;
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 10px;
        max-width: 100%;

        .bottom {
          margin-top: auto;
          color: #fff;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 26px */
          display: flex;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          width: 70%;
          padding: 0;

          p {
            text-align: left;
            padding: 0;
            margin: 0;
            color: #fff;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
          }
        }
      }

      .overlay {
        background-color: red;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;

        &.blue {
          background-color: #94b9c9;
        }

        &.orange {
          background-color: #ff9e67;
        }

        &.yellow {
          background-color: #fede66;
        }

        &.tan {
          background-color: var(
            --e-1-c-7-be,
            linear-gradient(0deg, #e1c7be 0%, #e1c7be 100%),
            #ecddd8
          );
        }
      }

      span {
        border-radius: 5px;
        background: var(--hero-blue-0-b-455-f, #0b455f);
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
        padding: 5px 15px;
        align-self: flex-start;
      }

      h2 {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 48px */
        margin-top: 1.5rem;
        word-wrap: break-word;
      }
    }
  }

  .chart__bubble .leaf {
    fill: #88d47b;
  }

  .chart__bubble {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
  .chart__bubble .bubble {
    fill: #a4a4a477;
  }

  .chart__bubble .leaf {
    fill: #88d47b;
  }

  .bubble-chart-text {
    z-index: 100;
    width: 100% !important;
  }

  /* Center the contents of the bubble chart */
  .bubble-chart-container {
    flex: 1;
    position: relative;
    display: flex;
    user-select: none;
    justify-content: center;
  }

  /* Center text in the circle, and hide any overflow */
  .bubble-chart-container .bubble-chart-text .bubble-label {
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 50%;
    will-change: left, top, height, width;
  }

  .tooltip {
    background-color: green !important;
    color: #fff;
  }

  /* Show the overflow when hovering over a label */
  .bubble-chart-container .bubble-chart-text .bubble-label:hover {
    overflow: visible;
    font-weight: 400 !important;
  }

  /* Optional, but recommended - reduce the size of text on medium bubbles */
  .bubble-chart-container .bubble-chart-text .bubble-label.medium {
    font-size: 0.8em;
  }

  /* Optional, but recommended - hide the text on small bubbles */
  .bubble-chart-container .bubble-chart-text .bubble-label.small {
    font-size: 0;
  }

  /* Make sure to unhide the text when hovering */
  .bubble-chart-container .bubble-chart-text .bubble-label.small:hover {
    font-size: 0.8em;
  }

  &.Size-1 {
    padding-bottom: 10px;

    .image {
      margin-top: 1rem;
      height: 100%;
      object-fit: cover;
      margin-bottom: 1rem;
      border-radius: 10px;
    }

    .list {
      border-radius: 8px;
      background: rgba(52, 133, 170, 0.1);
      padding: 10px;
      max-height: 220px;
      overflow: scroll;
      padding-top: 15px;
      margin-top: 1rem;
      padding-bottom: 15px;
      ul {
        margin: 0;
        padding: 0;
        padding-bottom: 0px;
        li {
          color: #155673;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 18px */
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;

          span {
            border-radius: 100px;
            background: var(--3485-aa, #3485aa);
            width: 20px;
            height: 20px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            margin-right: 0.5rem;
            color: #fff;
            font-size: 13px;
          }

          p {
            color: #155673;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 18px */
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .chart {
      svg {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .feedback {
      border-radius: 8px;
      background: var(--3485-aa, #3485aa);
      display: flex;
      padding: 16px;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      flex-direction: column;

      .icon {
        display: flex;
        align-items: center;
        svg {
          background-color: #013247;
          padding: 10px;
          color: #fff;
          border-radius: 100%;
          margin-right: 1rem;
        }
      }

      .icon h2 {
        color: var(--white-ffffff, #fff);

        /* Button Body */
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
      }

      h3 {
        color: var(--white-ffffff, #fff);
        margin-top: 1.5rem;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
      }

      h5 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        text-transform: capitalize;
        margin-top: 2rem;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
      }
    }

    .item {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    canvas {
      height: 260px;
    }

    .activeMoment {
      padding: 0;
      margin-top: auto;

      .item {
        padding: 15px 20px;
        height: auto;
        background: #ebf3f7 !important;
        border-radius: 10px;
        background: #ebf3f7;
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
      h4 {
        display: flex;
        align-items: center;
        color: var(--1-e-6484, #1e6484) !important;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 13.2px */
        text-transform: uppercase;
      }

      h2 {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0.5em;
        line-height: 110%; /* 17.6px */
        color: var(--hero-blue-0-b-455-f, #0b455f);
      }

      p {
        padding: 0;
        margin: 0;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        color: #155673;
      }
    }
  }

  .empty {
    display: flex;
    width: 201.945px;
    padding: 10px;
    align-items: center;
    gap: 9.972px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--white-ffffff, #fff);
    color: #155673;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.359px */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    background: var(--hero-yellow-fede-66, #fede66);
    margin-top: 1rem;
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: #155673;
      color: #fff;

      svg {
        fill: #fff;

        path,
        g {
          fill: #fff;
        }
      }
    }

    svg {
      background-color: transparent;
    }
  }

  &.Size-2 {
    padding-bottom: 10px;
    width: calc(63% + 25px);
    padding-right: 20px;
    padding-bottom: 2rem;

    .sides {
      display: flex;
      flex-shrink: 1;
      height: 300px;
      max-height: 100%;
      justify-content: space-between;
      border-radius: 4px;
      background: #ebf3f7;
      padding: 5px 20px;
      margin-top: 1rem;
    }

    .list {
      padding: 10px;
      max-height: 300px;
      overflow: scroll;
      padding-top: 15px;
      margin-top: 1rem;
      padding-bottom: 15px;
      width: 45%;
      ul {
        margin: 0;
        padding: 0;
        padding-bottom: 0px;
        li {
          color: #155673;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 18px */
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;

          span {
            border-radius: 100px;
            background: var(--3485-aa, #3485aa);
            width: 20px;
            height: 20px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            margin-right: 0.5rem;
            color: #fff;
            font-size: 13px;
          }

          p {
            color: #155673;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 18px */
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .chart {
      background-color: #fff;
      border-radius: 8px;
      margin-bottom: 2rem;

      .bubble-chart-text {
        width: 100% !important;
      }

      svg {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sides {
      display: flex;
      flex-shrink: 1;
      height: 300px;
      max-height: 100%;
    }

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 1rem;
      overflow: scroll;
      padding-bottom: 20px;

      button {
        border-radius: 8px;
        border: 0.5px solid var(--hero-blue-0-b-455-f, #0b455f);
        background: #ebf3f7;
        display: flex;
        width: 100%;
        height: 34px;
        padding: 10px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 16.8px */
        cursor: pointer;

        &.active {
          border-radius: 8px;
          background: var(--hero-blue-0-b-455-f, #0b455f);
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 16.8px */
        }
      }
    }

    .right {
      width: 100%;
      padding: 40px;
      padding-top: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 20px;
      overflow: scroll;

      .top {
        height: 60%;
        width: 100%;
        object-fit: cover;
        padding: 0;
        border: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bottom {
        display: flex;
        height: 40%;
        padding: 0;
        margin-top: 5px;
        overflow: scroll;
        width: 100%;
        border: none;
        justify-content: flex-start;

        &:last-child {
          margin-right: 0;
        }

        &::-webkit-scrollbar {
          display: block; /* Safari and Chrome */
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none !important;
          width: 7px !important;
          height: 9px !important;
          margin-top: 2rem;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 7px !important;
          background-color: rgba(0, 0, 0, 0.5) !important;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
          margin-top: 15px;
        }

        img {
          max-height: 100%;
          object-fit: cover;
          height: 100% !important;
          margin-right: 5px;
          width: 23%;
        }
      }
    }
  }

  > h2 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    font-family: Inter;
    font-size: 23.932px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.719px */
    position: relative;
    z-index: 2;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 0rem;
    margin-top: 20px;
    max-width: 100%;
    justify-self: flex-end;

    > button {
      width: 13px;
      height: 13px;
      background-color: #cedadf;
      position: relative;
      z-index: 10;
      border-radius: 100%;
      border: none;
      margin: 0px 2px;
      cursor: pointer;
      flex-shrink: 0;
      &.active,
      &:hover {
        background-color: #0b455f;
      }
    }

    .next,
    .prev {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      color: #fb9e67;
      margin: 0;
      padding: 4px;
      z-index: 15;
      position: relative;

      &:hover {
        color: darken(#fb9e67, 10);
        cursor: pointer;
      }
    }
  }

  .activeMoment {
    padding: 0;

    .item {
      padding: 15px 20px;
      height: auto;
      background: #ebf3f7 !important;
    }

    svg {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
    h4 {
      display: flex;
      align-items: center;
      color: var(--1-e-6484, #1e6484) !important;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 13.2px */
      text-transform: uppercase;
    }

    h2 {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0.5em;
      line-height: 110%; /* 17.6px */
      color: var(--hero-blue-0-b-455-f, #0b455f);
    }

    p {
      padding: 0;
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      color: #155673;
    }
  }
}
