.shareable-moment {
    display:flex;
    width:100%;
    justify-content:flex-start;
    background-color: #ececec;
    padding:10px 20px;
    align-items: center;
    margin-top:1em;
    border-radius: 10px;

    h4 {
        font-size:1.2rem;
        margin-left:20px;
    }

    img {
        width: 3em;
        max-height: 3em;
        object-fit: cover;
    }

    .featured {
        margin-left:auto;

        input {
            height: 1.6em;
            width: 1.6em;
        }
    }
}