.programsDashboard {
  padding: 50px 40px;
  padding-top: 20px;
  background-color: #fff;
  padding-bottom: 60px;
  background-repeat: repeat;
  background-position: left center;
  background-size: 20px 20px;
  min-height: 100vh;
  position: relative;

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .skrim {
      background: rgba(21, 86, 115, 0.75);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 5px;
      width: 80%;
      max-width: 900px;
      max-height: 85vh;
      overflow: scroll;
      z-index: 20;
      display: flex;
      justify-content: space-between;
      padding: 0;

      svg {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        color: #155673;
      }

      .left {
        width: 30%;
        background: var(--hero-tan-f-4-eeec, #f4eeec);
        padding: 40px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        img {
          width: 200px;
          height: 200px;
          border-radius: 100%;
          object-fit: cover;
        }
      }

      .right {
        width: 68%;
        padding: 40px 20px;
        height: 100%;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        margin-left: 30%;

        h4 {
          color: #013247;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
          opacity: 0.6;
          padding: 0;
          margin: 0;
        }

        button {
          margin-left: auto;
          margin-top: auto;
        }

        h3 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 27px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 44.8px */
          margin-top: 0.5rem;
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 26px */
          margin-top: 1rem;
        }
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .row {
        display: block;
      }

      .inputBox {
        margin-bottom: 40px;
      }

      label,
      input,
      textarea,
      select {
        width: 100%;
        text-align: left;
      }
    }
  }

  .done {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(11, 39, 51, 0.05);
    display: flex;
    padding-left: 0px;
    align-items: center;
    gap: 31.5px;
    padding: 25px;
    margin-top: 2rem;
    padding: 0;
    overflow: hidden;

    .right {
      width: 50%;
      height: 300px;
      margin-left: auto;
      flex-grow: 1;
      background-size: cover;

      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    .left {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 35px 25px;

      @media screen and (max-width: 990px) {
        width: 100%;
      }
      button {
        width: 150px;
      }

      h4 {
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 15.4px */
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);

        /* Small Body */
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
      }

      h3 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 28.8px */
      }
    }
  }

  .insights {
    border-radius: 10px;
    background: var(--2-a-7394, #2a7394);
    box-shadow: 0px 4px 24px 0px rgba(11, 39, 51, 0.05);
    padding: 35px 24px;
    margin-top: 2rem;

    h3 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      margin-bottom: 1rem;
    }

    .allInsights {
      display: flex;
      flex-grow: 1;
      overflow: scroll;
      gap: 2rem;

      .insight {
        width: 250px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #ebf3f7;
        padding: 15px;

        &.reflection {
          background-color: #fff8dc;

          svg {
            background: #fede66 !important;
          }
        }

        span {
          color: #1e6484;
          opacity: 0.65;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 15.6px */
        }

        svg {
          border-radius: 8px;
          background: var(
            --1-e-6484,
            linear-gradient(0deg, #1e6484 0%, #1e6484 100%),
            #ecddd8
          );
          padding: 5px;
          width: 40px;
          height: 40px;
          margin-bottom: 0.5rem;
        }

        h4 {
          color: #1e6484;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 15.6px */
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
    }
  }

  .reflections {
    border-radius: 8px;
    background: #fff8dc;
    display: flex;
    width: 100%;
    padding: 35px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 3rem;

    textarea {
      border-radius: 8px;
      background: #fff;
      border: 1px solid #d9d9d9;
      padding: 15px;
      width: 100%;
      height: 150px;
      resize: none;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      margin-top: 1rem;
    }

    button {
      border-radius: 12px;
      background: var(--hero-yellow-fede-66, #fede66);
      display: flex;
      width: 190px;
      padding: 12px 24px 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      color: #155673;
      text-align: center;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      border: none;
      box-shadow: none;
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      flex-direction: row;

      &:hover {
        background: darken(#fede66, 25);
      }
    }

    h2 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      margin-top: 1rem;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
      border: none !important;

      &::after {
        display: none;
      }
    }

    h3 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 15.4px */
      display: flex;
      align-items: center;

      svg {
        margin-right: 1em;
      }

      span {
        border-radius: 50px;
        background: var(--hero-yellow-fede-66, #fede66);
        display: flex;
        padding: 5px 10px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 15.4px */
        margin-left: 1rem;
      }
    }
  }

  .center {
    max-width: 90rem;
    margin: 0px auto;

    > a {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
    }
  }

  .header {
    background-size: cover;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    padding-bottom: 2rem;
    margin-top: 1rem;
    background-color: #0b455f;
    display: flex;
    flex-wrap: wrap;

    .timeline {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: scroll;
      flex-wrap: wrap;
      margin-top: -20px;

      .sessions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .line {
        width: 100%;
        height: 2px;
        background-color: #fff;
        z-index: 1;
        margin-left: 90px;
        margin-right: 90px;
        margin-top: 50px;
        margin-bottom: -35px;
      }

      .session {
        width: 180px;
        flex-shrink: 0;
        text-align: center;
        margin-top: 15px;
        z-index: 5;
        padding: 0px 40px;
        cursor: pointer;

        &:hover {
          .number {
            background-color: #0b455f !important;
            color: #fff !important;
          }
        }

        .number {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin: 0px auto;
          border: 4px solid var(--white-ffffff, #fff);
          background: var(--3485-aa, #3485aa);
          font-size: 12px;
          color: #fff;
          margin-bottom: 0.5rem;
          font-weight: 700;

          &.done {
            border: 4px solid var(--hero-blue-0-b-455-f, #0b455f);
            background: var(--white-ffffff, #fff);
            color: #0b455f;
          }
        }

        h4 {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          height: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h5 {
          color: var(--white-ffffff, #fff);
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%; /* 14.4px */
          opacity: 0.8;
        }
      }
    }

    h4 {
      color: var(--white-ffffff, #fff);
      /* Heading 6 */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
      width: 100%;
      margin-bottom: 1rem;
    }

    > h4 {
      margin-left: 1.5rem;
    }
  }

  .progressBanner {
    background-color: #fff;
    border: none;
    padding: 0px;
    margin-top: 20px;
    border-radius: 10px;
    background: var(--white-ffffff, #fff);

    /* Artifact Card */
    box-shadow: 0px 4px 24px 0px rgba(11, 39, 51, 0.05);
    .content {
      display: flex;
      align-items: center;
      padding: 30px 20px;
      span {
        font-size: 11px;
        display: block;
        background-color: grey;
        padding: 5px 10px;
        text-align: center;
        border-radius: 5px;
        border-radius: 4px;
        background: rgba(52, 133, 170, 0.15);
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 20.4px */
        width: 270px;
        margin-right: 20px;
      }

      svg {
        margin-right: 20px;
      }

      h2 {
        font-size: 16px;
        color: var(--hero-blue-0-b-455-f, #0b455f);

        /* Button Body */
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
      }
    }
  }

  .sessionData {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;

    @media screen and (max-width: 990px) {
      flex-wrap: wrap;
    }

    p {
      padding-right: 50%;
    }

    .pre-work {
      border-radius: 10.286px;
      background: rgba(11, 69, 95, 0.1);
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 17px;
      margin-left: 2rem;
      width: 25%;

      @media screen and (max-width: 990px) {
        width: 100%;
        margin-left: 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;

        li {
          border-radius: 8.229px;
          background: rgba(255, 255, 255, 0.9);
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 11.314px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 15px 20px;
          width: 100%;

          &.done {
            text-decoration: line-through;
          }

          svg {
            margin-right: 10px;
          }
        }
      }

      h3 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 17.486px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 19.234px */
      }
    }

    .comingUp {
      width: 100%;
      padding: 40px;
      border-radius: 10.286px;
      background: var(--hero-blue-0-b-455-f, #0b455f);
      box-shadow: 0px 4.114285945892334px 24.685714721679688px 0px
        rgba(11, 39, 51, 0.05);

      @media screen and (max-width: 990px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      .items {
        span {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 18px */
        }

        .date {
          color: var(--hero-yellow-fede-66, #fede66);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 18px */
          margin-left: 2rem;
        }
      }

      h2 {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 32.914px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 39.497px */
        margin-top: 1rem;
      }

      p {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
        margin-top: 0.5em;
      }

      .actions {
        display: flex;
        align-items: center;
        .blank {
          border-radius: 5.999px;
          background: #f1a171;
          display: flex;
          width: 141px;
          height: 39px;
          padding: 11.998px 23.997px;
          align-items: flex-start;
          gap: 11.998px;
          flex-shrink: 0;
          color: #1e445d;
          text-align: center;
          font-family: Inter;
          font-size: 11.998px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 16.798px */
          box-shadow: 4px 4px 0px 0px #fce9dd;

          &:hover {
            background-color: darken(#f1a171, 10);
            transform: none;
          }
        }

        .zoom {
          border-radius: 5.143px;
          border: 1.029px solid var(--white-ffffff, #fff);
          display: flex;
          padding: 10.286px 30.857px;
          justify-content: center;
          align-items: center;
          gap: 10.286px;
          background-color: transparent;
          margin-left: 3rem;
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 20.16px */
          cursor: pointer;

          &:disabled {
            opacity: .3;

            &:hover {
              background-color: transparent;
              transform: none;
              color: var(--white-ffffff, #fff);
            }
          }

          &:hover {
            background-color: darken(#fff, 10);
            transform: none;
            color: #000;
          }
        }

        h6 {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%; /* 15.84px */
          background-color: transparent;
          margin-left: 2rem;

          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .learningActivity {
    margin-top: 2rem;
    display: flex;
    border-radius: 10.286px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(11, 39, 51, 0.13);

    @media screen and (max-width: 990px) {
      flex-wrap: wrap;
    }
    .left {
      background-color: #ffe7dc;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 120px;
      @media screen and (max-width: 990px) {
        width: 100%;
      }
    }

    .center {
      padding: 30px 20px;
      padding-right: 60px;
      max-width: 100%;
      padding-left: 40px;
      width: 100%;
      @media screen and (max-width: 990px) {
        width: 100%;
      }

      h3 {
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 20.16px */
      }

      h2 {
        color: #000;
        font-family: Inter;
        font-size: 24.686px;
        font-style: normal;
        font-weight: 600;
        line-height: 34.289px; /* 138.902% */
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        color: #797979;
        font-family: Inter;
        font-size: 12.343px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 17.28px */
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: 30px;
      @media screen and (max-width: 990px) {
        width: 100%;
      }
    }
  }

  .resources {
    border-radius: 8px;
    background: var(--hero-tan-f-4-eeec, #f4eeec);
    width: 100%;
    margin-top: 2rem;
    padding: 40px 30px;
    overflow: scroll;

    .items {
      display: flex;
      gap: 2rem;
    }

    .resource {
      width: 250px;
      padding: 30px;
      margin-top: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4.114px 24.686px 0px rgba(11, 39, 51, 0.05);
      min-height: 350px;
      width: 229px;
      flex-shrink: 0;

      .icon {
        background-color: #fff;
        flex-grow: 0;
        padding: 15px;
        width: 50px;
        width: 50px;
        border-radius: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          opacity: 0.3;
          color: #000;
        }
      }

      p {
        opacity: 0.7;
        margin-top: 1rem;
      }

      a {
        color: #fff;
        margin-top: 3rem;
        background-color: #0b455f;
        padding: 10px 20px;
        border-radius: 10px;
        text-align: center;
        margin-top: auto;

        &:hover {
          background-color: darken(#0b455f, 10);
        }
      }

      h4 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    h3 {
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Heading 5 */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
    }
  }

  .bottomContent {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: flex-start;

    @media screen and (max-width: 990px) {
      flex-wrap: wrap;
    }

    .meetYourCrew {
      width: 100%;
      padding: 40px 25px;
      border-radius: 10.286px;
      background: var(--ebf-3-f-7, #ebf3f7);
      box-shadow: 0px 4.114px 24.686px 0px rgba(11, 39, 51, 0.05);

      @media screen and (max-width: 990px) {
        width: 100%;
        margin-top: 2rem;
      }

      h3 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 24.686px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 29.623px */
      }

      .users {
        display: flex;
        margin-top: 1.5rem;
        display: flex;
        overflow-x: auto;
        flex-shrink: 0;
        padding-bottom: 1.5rem;
        gap: 2%;
        margin-top: 2rem;
        flex-wrap: wrap;

        &::-webkit-scrollbar {
          display: block; /* Safari and Chrome */
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none !important;
          width: 7px !important;
          height: 9px !important;
          margin-top: 2rem;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 7px !important;
          background-color: rgba(0, 0, 0, 0.5) !important;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
          margin-top: 15px;
        }

        @media screen and (max-width: 990px) {
          flex-direction: column;
        }

        .user {
          display: flex;
          flex-direction: row;
          display: flex;
          width: 32%;
          margin-bottom: 3rem;

          &.coach {
            .icon {
              border-radius: 51.429px;
              background: var(
                --155673,
                linear-gradient(0deg, #155673 0%, #155673 100%),
                #ecddd8
              );
            }
          }

          @media screen and (max-width: 990px) {
            width: 100%;
            margin-bottom: 20px;
          }

          .icon {
            border-radius: 51.429px;
            background: #fff8dc;
            display: flex;
            width: 40px;
            height: 40px;
            padding: 8.229px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            position: relative;
            flex-shrink: 0;
            margin-right: 1rem;

            img {
              object-fit: cover;
              border-radius: 100%;
              position: absolute;
              top: 0;
              left: 0;
              width: 40px;
              height: 40px;
            }
          }

          .name {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
            vertical-align: center;

            &.coach {
              flex-direction: row;
              align-items: center;

              .bio {
                margin-left: auto;
                display: inline-flex;
                border-radius: 20px;
                background: var(--hero-blue-0-b-455-f, #0b455f);
                padding: 6px 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #fff;
                margin-right: 1rem;
                font-size: 12px;
                cursor: pointer;
                flex-shrink: 0;
              }
            }
            h2 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              /* Heading 6 */
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 24px */
            }

            h3 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              /* Small Body */
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 18px */
            }

            .btn {
              border-radius: 8.229px;
              background: #fff8dc;
              padding: 12px 30px;
              flex-direction: column;
              align-items: flex-start;
              gap: 10.286px;
              flex-shrink: 0;
              border: none;
              color: #155673;
              text-align: center;
              font-family: Inter;
              font-size: 12.343px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 14.811px */
              margin-top: auto;
              cursor: pointer;
              justify-self: flex-end;

              svg {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }

    .datesAndLinks {
      width: 25%;
      border-radius: 10.286px;
      background: var(--hero-tan-f-4-eeec, #f4eeec);
      padding: 20px 20px;
      padding-bottom: 28px;
      height: 364px;
      padding-right: 0;

      @media screen and (max-width: 990px) {
        width: 100%;
        margin-top: 2rem;
      }

      > h3 {
        margin-bottom: 32px;
      }

      .schedule {
        height: calc(100% - 40px);
        overflow: scroll;

        .session {
          border-radius: 8px;
          background: #fff;
          padding: 14px 15px;
          margin-bottom: 23px;
          padding-bottom: 25px;
          margin-right: 20px;

          h4 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
            margin-bottom: 0.5rem;
          }

          h5 {
            border-radius: 4px;
            background: rgba(52, 133, 170, 0.15);
            display: flex;
            padding: 7px 8px;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 14.4px */
            display: flex;
          }
        }
      }

      h3 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 20.571px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24.686px */
      }
    }
  }
}
