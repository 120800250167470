.StrengthsOnboarding {
  width: 100%;
  height: 100%;

  .topright {
    position: absolute;
    top: 0;
    right: 0;
  }

  .roxanne {
    position: fixed;
    left:30px;
    bottom:30px;

    .chatMessage {
      p {
        color:#000;
        font-weight: 400 !important;
      }

      h4 {
        text-align: left;
      }
    }
  }

  .slide1 {
    h2 {
      text-align: center;
    }
    svg {
      max-width: 90%;
    }
  }

  .coach {
    img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      margin-bottom: 0.5rem;
    }
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1em;
      color: #0b455f;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }

    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 44px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #0b455f;
      margin-top: 1rem;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;
      /* or 117% */

      text-align: center;

      /* #0B455F */

      color: #0b455f;
    }
  }

  .expect {
    display: flex !important;
    width: 100%;
    .left {
      width: 50%;
      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 70px;
        /* or 117% */

        /* #0B455F */

        color: #0b455f;
      }
    }

    .right {
      width: 50%;
      margin-left: 4em;

      ol {
        margin: 0;
        padding: 0;
        text-align: left;
        margin-top: 3em;
        margin-left: 2em;

        li {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 1rem;
          color: #0b455f;
        }
      }

      h4 {
        font-family: "Inter";
        text-align: left;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        color: #0b455f;
      }
    }
  }

  section {
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 163.5%;
      /* or 98px */

      text-align: left;

      /* #0B455F */

      color: #0b455f;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      text-align: left;
      color: #0b455f;
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;

      .left {
        width: 45%;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          /* or 150% */

          color: #0b455f;
        }
      }

      .third {
        width: 30%;
        background: rgba(42, 115, 148, 0.1);
        border-radius: 8px;
        padding: 40px 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #2a7394;
        }
      }
    }
  }

  button {
    margin: 0px auto;
    margin-top: 2rem;
  }

  .skip {
    position: absolute;
    top: 40px;
    left: 50px;
    font-family: "Inter";
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    color: #000;
    opacity: 0.3;
    z-index: 500;
    cursor: pointer;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  .box {
    background-color: #fff;
    z-index: 40;

    p {
      color: #fff;
      font-size: 30px;
      width: 60%;
      padding: 20px 40px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fff;
      z-index: 10;
      background: #ffffff;
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 123.5%;
      color: #0b455f;
    }
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    color: #fff;
    margin-bottom: 1rem;
    font-family: "Inter";
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 74px;
    line-height: 110%;
    text-align: left;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
    text-align: left;
    margin-bottom: 2rem;
    color: #ffffff;

    &.smaller {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      margin-top: 3rem;
      svg {
        margin-right: 1rem;
      }
      color: #ffffff;
    }
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 163.5%;
    /* or 65px */

    text-align: center;

    /* #0B455F */

    color: #0b455f;
  }
}

.onboardingHome {
  width: 100%;
  height: 100%;
}
