.coverPage {
  display: flex;
  .sidebar {
    width: 400px;
    height: 100vh;
    @media (max-width: 1200px) {
      height: calc(100vh - 80px);
    }
    background-color: #fff;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .bottom {
      margin-top: auto;
      padding: 20px 20px;

      button {
        font-size: 15px;
        box-shadow: none;
        font-weight: 600;
        border-radius: 10px;

        &:disabled {
          opacity: 0.5;
        }
      }

      .blank {
        border-radius: 12px;
        background: #f0f2f5;
        border: none;
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        &:hover {
          background: darken(#f0f2f5, 10%);
        }
      }
    }

    .title {
      display: flex;
      padding: 15px;
      border-bottom: 2px solid #ececec;

      svg {
        margin-right: 1em;
        opacity: 0.7;
      }

      h3 {
        color: #013247;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
    }

    .empty {
      padding: 30px;
      text-align: left;

      h1 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      p {
        &:nth-child(3) {
          opacity: 0.4;
        }
      }
    }

    .inner {
      padding: 15px 25px;
      height: calc(100% - 100px);
      overflow: scroll;

      .colors {
        display: flex;
        justify-content: space-between;
        > div {
          width: 70px;
          height: 70px;
          border-radius: 100%;

          &.blue {
            background-color: #94b9c9;
            border: 8px solid #3485aa;
          }

          &.orange {
            background-color: #ff9e67;
            border: 8px solid #bf754b80;
          }

          &.yellow {
            background-color: #fede66;
            border: 8px solid #c1ab5d80;
          }

          &.tan {
            background-color: #e1c7be;
            border: 8px solid #b7a7a180;
          }
        }
      }

      .inputBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        position: relative;

        &.photo {
          p {
            color: var(--3485-aa, #3485aa);

            /* Small Body */
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
          }

          .photoUpload {
            padding: 30px;
            border: 2px dashed #f48a4e;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
              max-height: 100px;
            }

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }

            h4 {
              color: var(--e-26-c-29, #e26c29);
              font-family: Inter;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 19.689px; /* 82.038% */
              margin-top: 1rem;
              margin-bottom: 1rem;
            }

            p {
              color: var(--e-26-c-29, #e26c29);

              /* Small Body */
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 18px */
            }
          }
        }

        .titleBox {
          display: flex;
          align-items: center;
          border-radius: 10px;
          border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
          background: #fff;
          box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
          padding: 15px;

          p {
            padding: 0;
            margin: 0;
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
          }

          svg {
            margin-left: 2rem;
            cursor: pointer;
          }
        }

        p {
          color: var(--3485-aa, #3485aa);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        .counter {
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: var(--3485-aa, #3485aa);
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        textarea {
          background-color: #fff;
          border-radius: 7px;
          border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
          background: var(--white-ffffff, #fff);
          min-height: 6em;
          box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 21px */
          padding: 10px 15px;
        }

        label {
          color: var(--013247, #013247);

          /* Heading 6 */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
        }
      }
    }

    .subtitle {
      border-bottom: 2px solid #ececec;
      padding-bottom: 1rem;

      h4 {
        color: var(--0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
        margin-bottom: 0.5rem;
      }

      p {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        padding: 0;
        margin: 0;
      }
    }

    .prompts {
      text-align: left;

      .prompt {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #ececec;
        padding-bottom: 1rem;

        &:last-child {
          border-bottom: 0px;
        }

        .expanding {
          display: none;
          gap: 15px;
          flex-direction: column;

          .box {
            border-radius: 9.535px;
            background: #eaf1f4;
            display: flex;
            padding: 25px 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;

            &.yellow {
              background: #fff8e0;

              &.selected {
                background: darken(#fff8e0, 20%);
              }
            }

            &.red {
              background: #fff2eb;
              &.selected {
                background: darken(#fff2eb, 20%);
              }
            }

            &.selected {
              background: darken(#eaf1f4, 20%);
            }

            p {
              padding: 0;
              margin: 0;
              color: #0b455f;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
              word-wrap: break-word;
            }
          }
        }

        &.expanded {
          margin-bottom: 1rem;
          .expanding {
            display: flex;
          }
        }

        .title {
          text-align: left;
          display: flex;
          flex-direction: column;
          border: none;
          padding: 0;
          position: relative;

          span {
            color: var(--3485-aa, #3485aa);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
          }

          h3 {
            color: var(--013247, #013247);

            /* Button Body */
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
          }

          p {
            color: #0b455f;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
            margin-top: 10px;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  .right {
    width: calc(100% - 400px);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:10px;

    .coverBox {
      border-radius: 16.988px;
      background: #fff;
      width: 400px;
      padding: 15px;
      height: 80vh;

      .inside {
        border-radius: 6.795px;
        background: rgba(42, 115, 148, 0.5);
        padding: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-position: center;
        background-size: cover;
        position: relative;

        .content {
          z-index: 50;
          position: relative;
          display: flex;
          height: 100%;
          flex-direction: column;
          max-width: 100%;

          .bottom {
            margin-top: auto;
            color: #fff;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 26px */
            display: flex;
            flex-direction: column;
            text-align: left;
            align-items: flex-start;
            width: 50%;

            p {
              text-align: left;
              padding: 0;
              margin: 0;
              color: #fff;
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 130%;
            }
          }
        }

        .overlay {
          background-color: red;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.8;

          &.blue {
            background-color: #94b9c9;
          }

          &.orange {
            background-color: #ff9e67;
          }

          &.yellow {
            background-color: #fede66;
          }

          &.tan {
            background-color: var(
              --e-1-c-7-be,
              linear-gradient(0deg, #e1c7be 0%, #e1c7be 100%),
              #ecddd8
            );
          }
        }

        span {
          border-radius: 5px;
          background: var(--hero-blue-0-b-455-f, #0b455f);
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
          padding: 5px 15px;
          align-self: flex-start;
        }

        h2 {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 42px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%; /* 48px */
          margin-top: 1.5rem;
          word-wrap: break-word;
        }
      }
    }

    .scrollingBox {
      width: 100%;

      .top {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        margin-top:-1.5rem;

        p {
          color: var(--0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 26px */
        }
        span {
          border-radius: 5px;
          background: var(--hero-blue-0-b-455-f, #0b455f);
          display: flex;
          padding: 10px 15px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: var(--white-ffffff, #fff);
          margin-right: 2rem;
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
        }
      }

      .pages {
        display: flex;
        width: 100%;
        overflow: scroll;
        gap: 1rem;
        margin-top:1rem;

        .coverBox {
          width: 380px;
          height: 90vh;

        }

        .pageNumber {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 16.988px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 20.386px */
          margin-bottom: 1rem;
          margin-left: 15px;
        }

        .box {
          flex-shrink: 0;

          width: 350px;
          height: 90vh;
          border-radius: 6.795px;
          background: #fff;
          border: 10px solid #fff;
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin: 0;
          border: 1px solid #eaf1f4;
          border-radius: 13.541px;
          width: 380px;
          padding: 15px;
          height: 90vh;

          .inside {
            display: flex;
            flex-direction: column;
            padding: 30px;
            background-color: #fff;
            height: 100%;
            border-radius: 5.416px;

            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 1rem;

              span {
                margin-bottom: 0;
                margin-top:1rem;
              }
              button {
                padding: 0;
                color: var(--3485-aa, #3485aa);
                font-family: Inter;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 16.5px */
                margin: 0;

                svg {
                  margin-left: 0.5em;
                }
              }
            }

            h4 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 11.956px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 14.347px */
            }

            h2 {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 19.129px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%; /* 22.955px */
              opacity: 1;
              margin: 0;
              margin-top: 1rem;
            }

            p {
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 12.753px;
              font-style: normal;
              font-weight: 400;
              line-height: 125%; /* 15.941px */
              opacity: 1;
            }

            &.growth {
              background: #eaf1f4;

              span {
                background: var(--3485-aa, #3485aa);
              }
            }

            &.relationships {
              background: #fff8e0;

              span {
                background: var(
                  --e-4-c-037,
                  linear-gradient(0deg, #e4c037 0%, #e4c037 100%),
                  #ecddd8
                );
              }
            }

            &.impact {
              background: #fff2eb;

              span {
                background: var(
                  --f-48-a-4-e,
                  linear-gradient(0deg, #f48a4e 0%, #f48a4e 100%),
                  #ecddd8
                );
              }
            }
          }

          &.nonActive {
            opacity: 0.2;

            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }

          img {
            max-height: 200px;
            width: auto;
            align-self: flex-start;
            margin: 0px auto;
            margin-top: auto;
          }

          h4 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 18px */
            opacity: 0.5;
          }

          h2 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 28.8px */
            opacity: 0.5;
            word-wrap: break-word;
          }

          span {
            display: flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            background: var(--3485-aa, #3485aa);
            color: var(--white-ffffff, #fff);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 13.2px */
            width: auto;
            flex-grow: 0;
            flex-grow: 0;
            align-self: flex-start;
            margin-bottom: 1rem;
          }

          p {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 19.2px */
            opacity: 0.5;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .skrim {
      background: #013247;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
      opacity: 0.67;
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 40px 50px;
      border-radius: 5px;
      width: 80%;
      max-width: 900px;
      max-height: 85vh;
      overflow: scroll;
      z-index: 20;

      svg {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 16px;
        opacity: 0.3;
        cursor: pointer;
      }

      .actions {
        margin-left: auto;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 2rem;

        button.btn {
          margin-left: auto;
          margin: 0;
        }

        button.text {
          color: #013247;
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          text-decoration: none;
          line-height: 140%; /* 19.6px */
          margin-right: 2rem;
        }
      }

      h5 {
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
        text-decoration: none;
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .images {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
      }

      .row {
        display: block;
      }

      .inputBox {
        margin-bottom: 40px;
      }

      h2 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 38.4px */
      }

      button {
        margin-left: auto;
        margin-top: 3rem;
      }

      h5 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        text-decoration-line: underline;
        margin-top: 1.4rem;
      }

      .icons {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        .item {
          display: flex;
          margin-top: 1rem;

          h4 {
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
          }

          p {
            color: #013247;

            /* Small Body */
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
          }

          span {
            width: 42px;
            height: 42px;
            background-color: #013247;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin-right: 1em;
          }
        }
      }

      label,
      input,
      textarea,
      select {
        width: 100%;
        text-align: left;
      }
    }
  }
}
