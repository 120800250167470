.RoleModelOnboarding {
  height: 100vh;
  overflow: hidden;
  &.blue {
    background: var(--hero-blue-0-b-455-f, #0b455f);
    height: 100%;
    display: flex;

    .logo {
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }



  .slides {
    width: 75% !important;

    .left {
      justify-content: flex-start !important;
      align-items: flex-start !important;

      .center {
        margin:0px auto;
        margin-top:4rem;
        margin-bottom:3rem;
      }
    }
  }

  .topLeft {
    position: absolute;
    top: 0;
    left: 0;
  }

  .titleSlide {
    text-align: left;
    justify-content: center;
    padding-left: 10%;

    h1 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 74px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 81.4px */
      margin-bottom: 1rem;
    }

    h2 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
    }

    p {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
    }
  }

  .boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .box {
      width: 32%;
      border-radius: 10px;
      background: var(--white-ffffff, #fff);
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 2rem;

      .top {
        display: flex;
        align-items: center;

        h3 {
          color: #155673;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 26px */
          margin-left: 10px;
        }
      }

      p {
        color: #155673;
      }
    }
  }

  .exampleBoxes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;

    .box {
      height: 500px;
      background: #eaf1f4;
      border-radius: 5.139px;
      border: 10px solid #fff;
      width: 24%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &.yellow {
        background: #fff8e0;

        span {
          background: var(
            --e-4-c-037,
            linear-gradient(0deg, #e4c037 0%, #e4c037 100%),
            #ecddd8
          );
        }
      }

      &.red {
        background: #fff2eb;

        span {
          background: var(
            --f-48-a-4-e,
            linear-gradient(0deg, #f48a4e 0%, #f48a4e 100%),
            #ecddd8
          );
        }
      }

      span {
        border-radius: 3.36px;
        background: var(--3485-aa, #3485aa);
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 11.425px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 12.568px */
        padding: 5px 10px;
      }

      h2 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 21.782px */
        margin-top: 1rem;
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
      }

      &.cover {
        background-size: cover;
        background-position: center;

        span {
          border-radius: 3.36px;
          background: var(--hero-blue-0-b-455-f, #0b455f);
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 11.425px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 12.568px */
          padding: 5px 10px;
        }

        .bottom {
          margin-top: auto;
          p {
            color: #fff;
            font-family: Inter;
            font-size: 13.441px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            width: 70%;
          }
        }

        h2 {
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.4em;
          margin-top: 10px;
        }
      }
    }
  }

  .topRight {
    position: absolute;
    top: -20px;
    right: 0px;
  }

  .content {
    text-align: left;
    height: 100vh;
    display: flex !important;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &.col {
      flex-direction: column;
      justify-content: flex-start;

      .boxes {
        width: 100%;
        display: flex;
        .box {
          width: 30%;
          background-color: #f4e4dc;
          padding: 30px;
          display: flex;
          align-items: center;

          p {
            color: #0b455f;
            font-size: 22px;
          }
        }
      }

      p {
        font-size: 20px;
      }

      h2 {
        margin-top: 0;
        font-size: 45px;
        font-weight: 700;
        max-width: 80%;

      }
    }
    .left {
      width: 50%;
      margin-top:-4rem;

      p {
        font-size: 20px;
      }

      h2 {
        margin-top: 0;
        font-size: 45px;
        font-weight: 700;
      }
    }

    .right {
      width: 40%;

      img {
        margin-top: 0;
      }
    }

    img {
      margin: 0px auto;
    }

    .here {
      max-height: 340px;
      margin: 0px auto;
      display: block;
      margin-top: 3rem;
    }

    .boxes {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;
      .box {
        background-color: rgba(255, 158, 103, 0.43);
        width: 45%;
        text-align: center;
        display: flex;
        justify-content: center;
        span {
          color: var(--0-b-455-f, #0b455f);
          /* Heading 6 */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
          display: inline-block;
          width: 100%;
        }
      }
    }

    h2 {
      margin-top: 0rem;
      color: var(--0-b-455-f, #0b455f);
      /* Heading 3 */
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 52.8px */
      margin-bottom: 2rem;
    }

    h3 {
      color: var(--0-b-455-f, #0B455F);
/* Large Body */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 26px */
text-align: left;
    }

    p {
      color: var(--0-b-455-f, #0b455f);
      /* Large Body */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 26px */
      b {
        margin-bottom: -10px;
        display: block;
        padding: 0;
      }
    }
  }

  .sides {
    display: flex !important;
    justify-content: space-between;

    .left {
      width: 30%;
    }

    .right {
      width: 65%;
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: flex-start;

      .box {
        border-radius: 8.491px;
        border: 0.849px solid rgba(11, 69, 95, 0.25);
        background: var(--white-ffffff, #fff);
        width: 32%;
        padding: 20px;
        height: 300px;
        margin-bottom: 2rem;

        img {
          margin: 0px;
          max-width: 30px;
        }

        &.blue {
          background: var(--2-a-7394, #2a7394);

          .top {
            h3 {
              color: #fff;
            }
          }

          p {
            color: #fff;
          }

          .names {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
              border-radius: 8.491px;
              background: var(--white-ffffff, #fff);
              display: flex;
              padding: 10px;
              align-items: center;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 14.434px;
              font-style: normal;
              font-weight: 600;
              line-height: 110%; /* 15.878px */
            }
          }
        }

        &.yellow {
          background: var(--hero-yellow-fede-66, #fede66);
          .top {
          }

          .names {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
              border-radius: 8.491px;
              background: var(--white-ffffff, #fff);
              display: flex;
              padding: 10px;
              align-items: center;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 14.434px;
              font-style: normal;
              font-weight: 600;
              line-height: 110%; /* 15.878px */
            }
          }
        }

        .top {
          display: flex;
          align-items: center;

          h3 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 14.434px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 15.878px */
            margin-left: 10px;
          }
        }

        > p {
          margin-top: 1rem;
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 10.189px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 12.227px */
        }

        .empty {
          border-radius: 8.491px;
          border: 1.698px dashed #2a7394;
          display: flex;
          height: 150px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 20px;

          h4 {
            color: var(--013247, #013247);
            text-align: center;
            font-family: Inter;
            font-size: 12.736px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 15.283px */
          }
        }
      }
    }
  }
}
