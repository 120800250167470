.valuesInvite {
  display: flex;
  flex-wrap: wrap;
  background-color: #f4eeed;
  min-height: 100vh;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
    padding-top: 30px;
  }

  .right > span {
    color: var(--013247, #013247);
    font-family: Inter;
    font-size: 14.543px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 20.36px */
    text-decoration-line: underline;
    margin-bottom: 15px;
    display: block;
  }

  .rdw-editor-wrapper {
    min-height: 300px;
    border: 1px solid #ececec;

    .public-DraftStyleDefault-block {
      margin-top: 0;
    }
  }

  .rdw-editor-main {
    padding: 10px;
  }

  .rdw-block-dropdown,
  .rdw-fontfamily-dropdown,
  .rdw-embedded-wrapper,
  .rdw-image-wrapper,
  .rdw-colorpicker-wrapper,
  .rdw-fontfamily-wrapper,
  .rdw-fontsize-wrapper,
  .rdw-history-wrapper,
  .rdw-link-wrapper,
  .rdw-remove-wrapper {
    display: none;
  }

  .step {
    display: flex;

    .number {
      width: 100px;
    }

    .right {
      margin: 0;
      padding: 0;
      width: 100% !important;

      h4 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 14.543px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 20.36px */
      }

      p {
        color: #013247;
        font-family: Inter;
        font-size: 10.18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 14.252px */
      }
    }
  }

  .dropzone {
    fill: #fff;
    border-width: 1px;
    border-style: dashed;
    border-color: #f48a4e;
    width: 80%;
    margin-left: 10%;
    padding: 30px;
    text-align: center;
    height: calc(100vh - 200px);
    border-radius: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: lighten(#e26c29, 30);
    }

    svg {
      margin: 0px auto;
      display: block;
    }

    h3 {
      color: var(--e-26-c-29, #e26c29);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.689px; /* 82.038% */
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    p {
      color: var(--e-26-c-29, #e26c29);
      /* Small Body */
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .skrim {
      background: #013247;
      opacity: 0.67;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 20px 40px;
      padding-bottom: 50px;
      border-radius: 5px;
      width: 80%;
      max-width: 1000px;
      overflow: scroll;
      z-index: 20;
      background: #ffffff;
      border-radius: 24px;
      display: flex;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 40px;
        font-size: 30px;
        opacity: 0.3;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .left {
        width: 40%;
        border: none;
        @media (max-width: 768px) {
          width: 100%;
        }

        .box {
          background-color: rgba(255, 158, 103, 0.1);
          padding: 90px;
          display: flex;
          border-radius: 24px;

          @media (max-width: 768px) {
            padding: 20px;
          }
          svg {
            margin: 0px auto;
          }
        }
      }

      .right {
        text-align: left;
        width: 60%;
        @media (max-width: 768px) {
          width: 100%;
        }
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          color: #ff9e67;
          padding: 0;
          text-align: left;
        }

        .number {
          background-color: #013247;
          width: 50px;
          height: 44px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }

        > h2 {
          margin-bottom: 0;
        }

        > p {
          margin-top: 0;
          padding-top: 0;
        }

        button {
          margin-left: auto;
        }

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 140%;
          margin-top: 2rem;
          color: #013247;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          /* or 20px */

          color: #013247;

          opacity: 0.6;
        }
      }

      .row {
        display: block;
      }

      .inputBox {
        margin-bottom: 40px;
      }

      label,
      input,
      textarea,
      select {
        width: 100%;
        text-align: left;
      }
    }
  }

  h2 {
    @media (max-width: 768px) {
      font-size: 18px !important;
      line-height: 1em !important;
    }
    color: #000;
  }

  .introModal {
    .modal-content {
      padding: 40px;
      width: 90%;
      max-width: 1200px;

      .left {
        border: none;

        p {
          color: black;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          line-height: 36px;
        }
      }
    }
  }

  h2 span {
    @media screen and (max-width: 768px) {
      font-size: 25px !important;
      line-height: 1.4em !important;
    }
  }

  h2 {
    @media (max-width: 768px) {
      line-height: 1.4em;
    }
  }

  .white-background {
    padding: 0px 0px;
    margin: 0px auto;
    color: #000;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &.padding {
      padding: 40px;
      height: auto;
      display: block;
    }
  }

  .body-text {
    @media screen and (max-width: 768px) {
      font-size: 15px !important;
      line-height: 1.5em !important;
    }
  }

  .underline {
    font-size: 36px;
  }

  .top {
    max-width: 90rem;
    padding: 0px 40px;
    margin: 0px auto;
    padding-bottom: 50px;
    width: 100%;

    h2 {
      color: #000;
      border: none;
      margin-bottom: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 1.4rem;
      &:after {
        display: none;
      }
    }

    p {
      color: #000;
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }

  .bottom {
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
    display: flex;
    max-width: 90rem;
    padding: 0px 40px;
    margin: 0px auto;
    padding-bottom: 50px;
    border-top: 2px solid rgba(1, 50, 71, 0.1);
    padding-top: 1rem;

    button {
      height: 50px;
      font-size: 15px;
      font-weight: 600;

      &:disabled {
        opacity: 0.2;
      }
    }

    button.blank {
      border: 3px solid #f0f2f5;
      background: #f0f2f5;
      border-radius: 12px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: #0b455f;

      &:hover {
        background-color: #8c44d5;
        color: #fff;
      }
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;

      button {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 48px;
    }
  }

  > p {
    margin-bottom: 20px;
  }

  .white-background {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    > .left {
      background-color: white;
      width: 400px;
      padding-top: 20px;
      overflow-x: visible;
      padding-right: 400px;
      z-index: 3;
      height: 100vh;
      overflow: scroll;
      
      @media (max-width: 1200px) {
        height: calc(100vh - 80px);
      }

      .header {
        padding: 10px 30px;
        .number {
          display: flex;

          h3 {
            color: var(--013247, #013247);
            /* Heading 6 */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
            margin-bottom: 1rem;

            &:first-child {
              margin-right: 15px;
            }
          }

          p {
            color: var(--013247, #013247);
            /* Small Body */
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
            margin-top: 2rem;
            display: block;
          }
        }
      }

      .entry {
        padding: 0px 30px;
        width: 100%;

        &.overflow {
          height: calc(100% - 250px);
          overflow: scroll;
        }

        .responseEdit {
          margin-bottom: 2rem;
          .number {
            display: flex;
            margin-bottom: 1rem;
            h3 {
              color: var(--013247, #013247);
              /* Heading 6 */
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 24px */

              &:first-child {
                margin-right: 10px;
              }
            }
          }

          .rdw-editor-wrapper {
            border: none;
          }

          .rdw-editor-toolbar {
            margin-bottom: 0;
          }

          .rdw-editor-main {
            background-color: rgba(42, 115, 148, 0.2);
            border-radius: 0px 0px 15px 15px;
            min-height: 200px;
          }

          textarea {
            height: 200px;
            min-height: 100px;
            border-radius: 20px;
            background: rgba(42, 115, 148, 0.2);
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.539px; /* 118.135% */
            border: 0px;
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.539px; /* 118.135% */

            &:active,
            &:focus {
              background-color: #fff;
            }
          }
        }

        textarea {
          border-radius: 20px;
          width: 100%;
          min-height: 300px;
          height: calc(100% - 200px);
          display: block;
          background-color: transparent;
        }
      }

      .group {
        display: flex;
        justify-content: flex-start;

        button {
          background-color: transparent;
          border: none;
          padding: 8px 40px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 140%;
          cursor: pointer;
          margin: 0;
          text-align: center;
          color: #0b455f;
          margin-right: 1rem;
          margin-top: 0.5rem;

          &.active,
          &:hover {
            background: #2a7394;
            border-radius: 99px;
            color: #fff;
          }
        }
      }

      .actions {
        display: flex;
        margin: 0;
        padding: 0;
        padding: 20px;
        justify-content: space-between;
        border-top: 1px solid #ececec;
        button {
          margin: 0;
        }
      }

      .success {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #013247;
        margin-left: 20px;
        margin-top: 1em;
        margin-bottom: 1em;
        display: flex;
        align-items: center;

        svg {
          background: #47884f;
          border-radius: 50%;
          color: #fff;
          padding: 10px;
          margin: 0;
          padding: 0;
        }
      }

      .inviteList {
        margin-top: 3rem;
        border-top: 2px solid #ececec;
        padding: 20px 80px;
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 20px !important;
        max-height: 300px;
        min-height: 100px;
        overflow: scroll;

        .inviteContainer {
          display: flex;
          justify-content: space-between;
        }

        p {
          color: #013247;
          font-weight: 600;
          opacity: 0.6;
        }
      }

      button {
        margin-left: auto;
        margin-right: 20px;
      }

      .inputField {
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.4rem;
        label {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 140%;
          color: #0b455f;
          margin-bottom: 0.5rem;
        }

        input,
        textarea {
          background: #ffffff;
          border: 1px solid #f4eeec;
          box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36);
          border-radius: 7px;
        }
      }

      > h2 {
        border-bottom: 2px solid #ececec;
        padding: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: #013247;
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        position: relative;
      }

      .link {
        background-color: transparent;
        border: none;
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
      }

      > .content {
        padding: 20px;
        padding-top: 0;

        img {
          margin-bottom: 20px;
        }

        > h3 {
          color: #013247;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
          margin-bottom: 20px;
          margin-top: 15px;
        }

        > p {
          color: var(--013247, #013247);
          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
        }
      }

      .chatsContainer {
        padding-bottom: 0;

        > div:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      .images {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-top: 0;
        max-height: calc(100vh - 100px);
        overflow: scroll;
        margin-top: 0;

        h4 {
          color: var(--013247, #013247);
          /* Heading 6 */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 20px;
          span {
            color: var(--3485-aa, #3485aa);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
          }
        }
        .image {
          width: 48%;
          border-radius: 8px;
          border: 1px dashed #f48a4e;
          margin-bottom: 20px;
          height: 120px;
          object-fit: cover;
          position: relative;

          img {
            object-fit: scale-down;
            max-height: 120px;
            width: 100%;
            border-radius: 8px;
          }

          button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: -6px;
            right: 0px;
            background-color: #ff9e67;
            padding: 6px;
            border-radius: 100%;
            width: 25px;
            height: 25px;
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              opacity: 0.7;
              cursor: pointer;
            }
          }

          img {
            object-fit: cover;
          }

          &.dropzone-box {
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: #ff9e67;
              cursor: pointer;
            }
          }
        }
      }

      .chatBox {
        min-height: auto;
      }

      .chatContainer {
        height: auto;

        .userMessageBox {
          .bubble {
            background: #fede66;
            color: #013247;
          }
        }

        .bubble {
          background-color: #0b455f;
          color: #fff;

          &.yellow {
            flex-direction: column;
            width: 100%;
            min-width: 100% !important;
            background-color: #fff !important;
            border: 1px solid #2a7394;
            .container {
              flex-direction: column;

              .left,
              .right {
                width: 100%;
                border: none;
              }
            }
          }

          .dot-flashing {
            position: relative;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #fff;
            color: #fff;
            animation: dot-flashing2 1s infinite linear alternate;
            animation-delay: 0.5s;
            margin-left: 1rem;
          }
          .dot-flashing::before,
          .dot-flashing::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
          }
          .dot-flashing::before {
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #fff;
            color: #fff;
            animation: dot-flashing2 1s infinite alternate;
            animation-delay: 0s;
          }
          .dot-flashing::after {
            left: 15px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #fff;
            color: #fff;
            animation: dot-flashing2 1s infinite alternate;
            animation-delay: 1s;
          }

          @keyframes dot-flashing2 {
            0% {
              background-color: #fff;
              color: #fff;
            }
            50%,
            100% {
              background-color: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }

      .finishedGraphic {
        margin-left: auto;
        transform: translate(40px, 50px);
        display: inline-block;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 0rem;
        padding-left: 15px;
        padding-right: 15px;
        height: auto !important;
      }
      height: 100vh;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      flex-direction: column;
      margin-top: 0;

      .bottom {
        margin-top: auto;
        padding: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: #013247;
        padding-left: 20px;
        border-bottom: 2px solid rgba(1, 50, 71, 0.1);
        padding-bottom: 1rem;
        svg {
          margin-right: 1rem;
        }
      }
    }

    > .right,
    > .centerContainer {
      background: #ffffff;
      border-radius: 24px;
      width: 100%;
      margin-left: 50px;
      margin-right: 50px;
      padding-top: 3rem;
      z-index: 2;
      margin-bottom: 4rem;
      padding-bottom: 40px;

      &.centerContainer {
        margin-left: 0;
        max-width: 1300px;
        width: 80%;
        margin: 0 auto;
        padding: 30px;
        text-align: left !important;
        margin-top: 20px;
        margin-bottom: 20px;

        .headerTop {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .left {
            border: none;
          }

          button {
            margin-right: 30px;
          }
        }

        h3 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 19.689px; /* 82.038% */
          margin-bottom: 1rem;
        }
      }

      .email {
        margin-right: 40px;
        .header {
          width: 100%;
          height: 140px;
          background-color: #75c4ed;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 70%;
          }
        }

        b {
          margin-top: 5px;
          margin-bottom: 0px;
          display: block;
        }
      }

      > p {
        color: var(--e-26-c-29, #e26c29);
        /* Small Body */
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
      }

      .masonry-grid {
        padding: 20px;
        border-radius: 10px;
        border: 1px dashed var(--hero-orange-ff-9-e-67, #ff9e67);
        margin-right: 20px;

        .photo {
          position: relative;
        }

        img {
          border-radius: 7.453px;
          border: 2px solid #ececec;
          width: 100%;
        }
      }

      &.list {
        .wordList {
          display: flex;
          flex-wrap: wrap;
          p {
            width: 50%;
            display: flex;

            &:before {
              content: url('data:image/svg+xml,<svg style="fill:cornflowerblue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
              color: red;
              width: 17px;
              height: 17px;
              margin-right: 1rem;
              display: block;
              fill: red;

              @media (max-width: 768px) {
                width: 18px !important;
                height: 18px !important;
              }
            }
          }
        }
      }

      &.center {
        text-align: center;

        img {
          width: 183px;
          height: 183px;
          border-radius: 100%;
        }

        h4 {
          margin-top: 2rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 20px;
          /* identical to box height, or 82% */

          color: #013247;
        }
      }

      @media screen and (max-width: 768px) {
        width: 100% !important;
        margin-left: 0;
        padding: 30px;
        margin-right: 0;
        margin-top: 3rem;
      }
    }
  }

  .titles {
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .left {
      border: none;
    }

    .left,
    .right {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .left {
    border-right: 2px solid #ececec;
    padding-right: 50px;
  }

  .right {
    padding-left: 50px;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: #013247;
    }

    @media (max-width: 768px) {
      h4 {
        font-size: 20px;
      }
    }
  }

  .left,
  .right {
    margin-top: 10px;
    padding-top: 15px;

    @media (max-width: 768px) {
      width: 100%;
      border-right: none;
      padding-right: 0px;
      padding-left: 0px;
    }

    > p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #013247;
      opacity: 0.6;
      margin-top: 1rem;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 0px;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid rgba(1, 50, 71, 0.1);
      padding-left: 15px;
      padding-right: 25px;
      margin-top: 0rem;
      align-items: center;
      flex-wrap: wrap;
      max-height: 50vh;
      overflow: scroll;

      &:nth-child(2) {
        margin-top: 0rem;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        color: #013247;
        margin: 0;
        padding: 0;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        color: #2a7394;
        margin: 0;
        padding: 0;
        cursor: pointer;
        svg {
          margin-left: 5px;
        }
      }
    }

    .wordsContainer {
      margin-bottom: 3rem;
      cursor: pointer;
      width: 100%;
      margin-top: 0.5rem;
      max-height: calc(100% - 20px);
      overflow: scroll;

      > p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #013247;
        opacity: 0.6;
        margin-bottom: 1rem;
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      .strengthSuggestion {
        padding: 15px 10px;
        background-color: #ececec;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        font-size: 24px;
        background: rgba(1, 50, 71, 0.06);
        border-radius: 7px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;

        @media (max-width: 768px) {
          font-size: 18px !important;
          line-height: 1em;
        }

        &:hover {
          background-color: darken(#ececec, 10%);
        }

        &:before {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" style="fill:black" /></svg>');
          color: #fff;
          width: 17px;
          height: 17px;
          margin-right: 1rem;
          display: block;

          @media (max-width: 768px) {
            width: 18px !important;
            height: 18px !important;
          }
        }

        p {
          margin: 0;
          padding: 0;
        }
      }
    }

    .strength {
      display: flex;
      margin-bottom: 10px;
      width: 100%;
      align-items: center;

      .strengthSearch {
        width: 100%;
        margin-right: 40px;

        @media (max-width: 768px) {
          width: 100%;
        }

        input {
          width: 100%;
          border-radius: 6px;
        }
      }

      span {
        margin-right: 20px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        width: 1em;
      }
    }
  }
}
