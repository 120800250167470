@import '../../theme.scss';

.PreviewVideo {
  overflow: hidden;

  h2 {
    margin-bottom:25px;
  }

  &.Signup {
    .info {
      padding: 2.5rem;
      display: flex;
      img {
        width: 3.125rem;
        height: 3.125rem;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 1em;
      }

      p {
        margin: 0;
        padding: 0;
        margin-bottom: 0.25rem;
        font-family: Inter, sans-serif;
        color: #000;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 300;

        &:nth-child(2) {
          font-family: Inter, sans-serif;
          color: #000;
          font-size: 0.75rem;
          line-height: 0.875rem;
          font-weight: 800;
        }
      }
    }

    .cta {
      width: 100%;
      padding: 2rem;
      border-style: solid;
      border-width: 2px;
      border-color: #ff67a7;
      border-radius: 12px;
      margin: 2.5rem;

      p {
        font-size: 1.5rem;
        line-height: 2.25rem;
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      button {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  &.video {
    .emailContainer {
      padding: 0 !important;
      margin-top: 40px;
    }

    .white-background {
      flex-wrap: nowrap;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    .tryagain {
      margin: 0 !important;
      margin-top: 40px !important;
    }
  }
  .customize {
    display: flex;
    width: 100%;
    max-width: 150%;
    min-width: 150%;
    height: 3em;
    font-family: Inter, sans-serif;
    color: #0d8cc2;
    font-size: 4rem;
    line-height: 4.5rem;
    font-weight: 200;
    flex-wrap: wrap;
    overflow: hidden;
    padding-top: -20px;
    overflow: hidden;

    span {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }

  .continueBox {
    margin-top: 40px;
    display:flex;
    justify-content: space-between;
    width: 100%;

    .btn.primary {
      justify-self: flex-end;
      width:200px !important;
      align-self: end;
      margin-left:auto;
    }

    .btn.secondary {
      width:150px;
      background-color: transparent;
      color:$pink;
      font-family: Inter, sans-serif;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 900;
      letter-spacing: 0.5px;

      &:hover {
        border-color: #bc0e57;
        background-color: #ffe6f1;
      }
    }



    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 137% */

      color: #000000;
    }
    button {
      width: 100%;
    }
  }

  .infoBox {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 50px;
      height: 50px;
      margin: 0;
      padding: 0;
      margin-right: 20px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      padding: 0;
    }
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin-bottom: 25px;
  }

  .preview {
    background: #fff;
    border: 1.17934px solid #e5e8f0;
    border-radius: 4.71736px;
    padding: 30px;

    p:first-child {
      margin-top: 0;
    }

    p {
      margin-bottom: 30px;
      margin-top: 0;
    }
  }

  .white-background {
    padding:0;
    padding-bottom:60px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &.flex {
      flex-wrap: nowrap;
      padding: 2.5rem;

      video {
        width: 100%;
        flex-wrap: nowrap;
      }

      .container {
        width: 36%;
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        width: 64%;
        padding: 0;
        margin-left: 2rem;

        .cta {
          margin-left: 0;
          margin-right:0;

          p {
            font-size: 1.5rem;
            line-height: 2.25rem;
            font-weight: 300;
          }
        }
      }
    }

    .tryagain {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding: 1rem 1.5rem;
      justify-content: center;
      align-items: center;
      border: 3px solid #a2def8;
      border-radius: 6px;
      text-decoration: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      width: 100%;
      line-height: 20px;
      margin: 1rem 2.5rem;
      color: #58c5f3;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 900;
      letter-spacing: 0.5px;
      cursor: pointer;
    }
    .textBox {
      display: flex;
      width: 100%;
      padding: 2.5rem;
      padding-top: 0rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      background-color: #f0f0f0;
      margin-top: 0;
      border-radius: 6px 6px 0px 0px;
      font-family: Inter, sans-serif;
      font-size: 1.125rem;
      line-height: 1.875rem;

      p {
        margin-top:20px;
        line-height: 1.4em;
      }
    }

    h3 {
      position: relative;
      z-index: 5;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-right: 0.3rem;
      padding-bottom: 0.3rem;
      padding-left: 0.3rem;
      font-family: Inter, sans-serif;
      font-size: 1.125rem;
      line-height: 1.125rem;
      font-weight: 800;
      text-align: left;
      padding-left: 2.5rem;
    }

    .emailContainer {
      width: 100%;
      padding: 20px 2.5rem;

      h3 {
        padding: 0;
        z-index: 5;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-right: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 0.3rem;
        font-family: Inter, sans-serif;
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 800;
      }

      .inputBox {
        width: 100%;
        margin-bottom: 20px;

        input,
        textarea {
          width: 100%;
        }

        textarea {
          min-height: 300px;
        }
      }
    }

    .box-container {
      display: flex;
      justify-content: space-between;
      padding: 20px 2.5rem;
      align-items: flex-start;
      width: 100%;
      flex-wrap: wrap;
      .box {
        margin-right: 1rem;
        padding: 2rem;
        justify-content: center;
        flex: 1;
        border-color: #a2def8;
        -webkit-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        border: 3px solid #a2def8;
        color: #a2def8;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 900;
        letter-spacing: 0.5px;
        align-items: center;
        border-radius: 6px;
        text-decoration: none;
        height: 80px;
        margin-top: 0;

        @media (max-width: 768px) {
          width: 100%;
          flex: auto;
          margin-bottom: 20px;
        }

        &:hover,
        &.active {
          border-color: #58c5f3;
          background-color: rgba(220, 244, 255, 0.5);
        }
      }
    }
  }

  .videoPlayer {
    width: 45%;
    margin: 0;
    margin-left: 2.5rem;
    margin-top: 1.5rem;
    margin-bottom: 70px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 2.5rem;
      margin-bottom: 20px;

      .image {
        width: 100%;
        margin-top: -10px;
        height: calc(100% + 20px);
      }
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 40px;
  }

  span {
    cursor: pointer;
  }
}
