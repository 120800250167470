.strengthSearch {
  position: relative;
  .dropdown {
    background-color: white;
    position: absolute;
    padding: 15px 20px;
    width: 100%;
    top: 45px;
    z-index: 6;
    background: #ffffff;
    border-width: 1px 1px 5px 1px;
    border-style: solid;
    border-color: #58c5f3;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

    > div {
      width: 100%;
      text-transform: capitalize;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .inputContainer {
    display: flex;
    align-items: center;
    background: #fafafa;
    background: #ffffff;
    border: 1px solid rgba(11, 69, 95, 0.5);
    border-radius: 6px;
    svg {
      margin-left: 1rem;
      color: #58c5f3;
      font-size: 24px;

      @media (max-width: 768px) {
        font-size: 18px !important;
      }

      &.hide {
        opacity: 0;
      }
    }
    input {
      text-transform: capitalize;
      background-color: white;
      border: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      padding: 0;
      padding-left: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: #013247;
      box-shadow: none;
      @media (max-width: 768px) {
        font-size: 18px !important;
        line-height: 1em;
      }
    }

    .close {
      padding: 2px 4px;
      border: 2px solid #aaaaaa;
      border-radius: 100%;
      display: block;
      width: 18px;
      height: 18px;
      font-size: 6px;
      margin-right: 5px;
      color: #aaaaaa;
      cursor: pointer;

      &:hover {
        background-color: #aaaaaa;
        color: white;
      }
    }
  }
}
