@import "../../theme.scss";


.membershipsAdmin {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:0px 40px;
    position: relative;
    margin-top:30px;

    h2 {
        width:100%;
        display:inline-block;
    }

    .alert.success {
        background-color: cadetblue;
        padding:10px;
        width: 100%;
        border-radius: 10px;
        margin-bottom:1em;

        p {
            padding:0;
            margin:0;
            color:#fff;
        }
    }

    .add {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        
        margin-top:3em;

        textarea {
            width:100%;
            height:100px;
            margin-bottom:1em;
            margin-top:2em;
        }
    }


    .right {
        width:70%;
        padding:20px;
        background-color: white;
        margin-top:20px;

        .link {
            margin-left:auto;
            margin-bottom:2em;

            background-color: transparent;
            border: none;
            color:red;
            padding:0;

            a {
                color:#ff67a7;
                font-size:1.4em;
            }
        }

        .coupon {
            display:flex;
            justify-content: space-between;
            padding:10px;

            &:nth-child(odd) {
                background-color: #f5f5f5;
            }

            p, button {
                width:20%;
                text-align: center;
            }

            button {
                width:100px;
            }

            &.top {
                margin-bottom:0px;
                border-bottom:2px solid #ececec;
                p {
                    font-weight: 600;

                    &:last-child {
                        width:100px;
                    }
                }
            }
        }

    }
}