@import "../theme";

.lifeline {
  padding-top: 1rem;
  overflow: hidden;
  margin-top: -40px;
  position: relative;

  @media (max-width: 768px) {
    height: auto !important;
    overflow: visible;
  }

  .skrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 400;
  }

  .form-group {
    width: 100%;
    margin-bottom: 0.5rem;
    width: 100%;
    padding: 0;
    input,textarea {
      width: 100%;
    }


    label {
      font-weight: 600;
      font-size: 0.8rem !important;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 2em;
    }

    &.full {
      width: 100%;
    }
  }

  .helpBoxMain {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 500;
    padding: 30px;
    color: #000;
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 0;
      margin-bottom: 15px;
    }
    p {
      line-height: 1.5em;
    }

    svg {
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 22px;
      cursor: pointer;
    }

    .right {
      min-width: 300px;
      padding-left: 40px;
    }

    h1,
    h2,
    h3,
    h4,
    p {
      color: #000 !important;
    }

    p {
      font-weight: 200 !important;
    }
  }

  .lifeline__message {
    // position: absolute;
    background-color: #ff67a6;
    width: 100%;
    text-align: center;
    color: #fff;
    top: 84px;
    padding: 10px;
    margin-top: -3px;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .cBoxAdd {
    height: auto;


    .addInsight {
      width:100%;
      .input {
        margin-bottom:1.5rem;
        padding-bottom:2rem;
        border-bottom:2px solid #ececec;
        textarea {
          width:100%;
          margin:0;
          margin-top:.5rem;
          margin-bottom:1rem;
        }
      }
    }


    .content {
      > h3 {
        display: flex;
        justify-content: space-between;
        width:100%;

        button {
          border-radius: 6.126px;
          border: 0.511px solid var(--ff-9-e-67, #ff9e67);
          background-color: transparent;
          padding: 4px 10px;
          color: var(--0-b-455-f, #0b455f);
          text-align: center;
          font-size: 12px;
          font-family: Inter;
          font-weight: 600;
          line-height: 140%;
          display:flex;
          align-items: center;

          &:hover {
            background-color: var(--ff-9-e-67, #ff9e67);
            color:#fff;
            cursor: pointer;
          }

          svg {
            background-color: #FF9E67;
            padding:3px;
            border-radius: 100%;
            width:1em;
            height: 1em;
            font-size:8px;
            margin-left:5px;
          }
        }
      }
    }

    .skrim {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 400;
    }

    .helpBox {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      z-index: 500;
      padding: 30px;
      color: #000;
      display: flex;
      justify-content: space-between;

      &.editingBox {
        display: flex;
        flex-direction: column;
        max-width:800px;
        width:80%;

        .form-control {
          display:flex;
          flex-direction:column;

          textarea {
            margin-bottom:2rem;
          }

          button {
            width:100px;
          }
        }
      }

      svg {
        position: absolute;
        top: 20px;
        right: 40px;
        font-size: 22px;
        cursor: pointer;
      }

      .right {
        min-width: 300px;
        padding-left: 40px;
      }

      h1,
      h2,
      h3,
      h4,
      p {
        color: #000 !important;
      }

      p {
        font-weight: 200 !important;
      }
    }

    .help {
      position: absolute;
      top: 0px;
      right: 10px;
      background: #aaaaaa;
      height: 15px;
      width: 15px;
      border-radius: 100px;
      color: #fff;
      padding: 5px;
      font-size: 5px;
      cursor: pointer;
    }
    .content {
      margin-top: 2rem;
      position: relative;

      .skrim {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }

      .emojiList {
        position: absolute;
        display: flex;
        background-color: #fff;
        background: #ffffff;
        top: -60px;
        border: 2px solid #0d8cc2;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
        z-index: 700;

        @media (max-width: 768px) {
          flex-wrap: wrap;
        }

        .emojiItem {
          font-size: 45px;
          margin-right: 20px;
          height: 100%;
          padding: 30px 10px;

          &:hover {
            background-color: #dcf4ff;
            border-radius: 20px;
            cursor: pointer;
          }
        }
      }
      input,
      textarea,
      select {
        margin-bottom: 2rem;
        background-color: #f3f3f3;
        border: 2px solid #58c5f3;
        padding: 10px;
      }

      select {
        padding-right: 20px !important;
        margin-bottom: 10px !important;
      }

      .buttons {
        display: flex;
        margin-top: 0px;
        margin-bottom: 20px;

        button {
          margin-bottom: 10px;
          margin-right: 15px;
          cursor: pointer;
          color:#fff;
          box-shadow: none;
          font-size: 14px;
          padding:15px 23px;

          &:nth-child(2) {
            border: 2px solid #88d57b;
            color: #2b9d19;
            background-color: transparent;
            border-color: #FF9E67;
            border-width: 2px;
            background-color: #FF9E67;
            color: #fff;

            &:hover {
              background-color: darken(#FF9E67, 10);
            }
          }
          &:first-child {
            border: 2px solid #58c5f3;
            background-color: transparent;
            border-width: 2px;
            border: 2px solid #4F7282;
            background: #4F7282;

            &:hover {
              background-color: #58c5f3;
            }
          }
        }
      }

      .emojiBox {
        margin-top: -60px;
        padding: 10px;
        background-color: #f3f3f3;
        border: 2px solid #58c5f3;
        display: inline-block;
        margin-bottom: 40px;
        display: flex;
        align-self: flex-start;

        .emoj {
          font-size: 60px;
          height: 40px;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          position: relative;

          svg {
            background: #58c5f3;
            border-radius: 100px;
            font-size: 14px;
            padding: 10px;
            color: #fff;
            position: absolute;
            bottom: -20px;
            right: -25px;
          }

          span {
            display: block;
          }
        }
      }

      textarea {
        height: 150px;
      }

      select {
        margin-right: 1rem;
      }

      .box {
        background-color: #f3f3f3;
        padding: 15px;
        border: 2px solid #58c5f3;
        input,
        select {
          background-color: #dcf4ff;
          color: gray;
          margin: 0;
          padding: 0;
          margin-right: 20px;
          padding: 6px;
        }
      }
    }
  }

  .chart {
    height: 90%;
  }

  .legend {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-left: 20px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #aaaaaa;
      margin: 0;
      padding: 0;
      margin-right: 10px;
    }

    button {
      background: #ffffff;
      border: 3px solid #88d57b;
      border-radius: 6px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #88d57b;
      margin-right: 20px;
      padding: 7px 10px;
      box-shadow: none;

      &.blue {
        color: #FF9E67;
        border-color: #FF9E67;
        border-width: 2px;
        background-color: #FF9E67;
        color:#fff;

        &:hover {
          border-color: #FF9E67;
          background-color: #FF9E67 !important;
          color: #fff !important;
        }
      }

      &.green {
        color: #fff;
        border-color: #4F7282;
        background-color: transparent;
        border-width: 2px;
        border: 2px solid #4F7282;
        background: #4F7282;

        &:hover {
          color:#fff !important;
          border: 2px solid #4F7282;
          background: #4F7282 !important;
        }
      }
    }
  }

  .insights {
    max-width: 90rem;
    margin: 0px auto;
    margin-top: 5rem;
    padding: 0px 40px;

    @media (max-width: 768px) {
      padding: 0px 0px;
    }
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 50px;
      margin-bottom: 1rem;

      svg {
        color: #ffde67;
        margin-right: 10px;
      }
    }

    .dots {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 90px;
      margin-top: 30px;
      .dot {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffeaa0;
        border-radius: 100px;
        color: #fff;
        margin-right: 15px;
        cursor: pointer;
        &.active {
          color: #000;
        }
      }
    }

    .insights__container {
      background-color: white;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 60px;
      padding: 20px;
      margin-bottom: 90px;

      .insight {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 30px;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 30px;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
        }

        .smallEdit {
          width: 24px;
          height: 24px;
          background: #58c5f3;
          border-radius: 100px;
          display: block;
          padding: 0;
          border: none;
          cursor: pointer;
        }

        .left {
          width: 80%;
        }

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          width: 100%;
          margin: 0;
          padding: 0;
        }

        h6 {
          margin: 0;
          padding: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          color: #aaaaaa;
        }
      }

      .navigator {
        display: flex;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
        width: 100%;

        button {
          float: right;
          margin-left: auto;
        }
      }

      span {
        width: 40px;
        height: 40px;
        display: flex;
        background: #58c5f3;
        border-radius: 50px;
        padding: 20px;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-right: 20px;
      }

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.4em;
        padding: 0;
        margin: 0;
        width: calc(100% - 60px);
      }

      textarea {
        width: 100%;
        margin-top: 40px;
        min-height: 150px;
      }
    }
  }

  .graphContainer {
    max-width: 90rem;
    margin: 0px auto;
    margin-top: 100px;
    min-height: 1000px;
    .buttons {
      display: flex;

      .btn {
        border-radius: 0px 0px 0px 0px;
        margin-right: 20px;

        background-color: #fff9e4;
        color: #ffde67;
        border: none;
      }

      .btn.active {
        background-color: #ffde67;
        color: #7b6002;
      }
    }
    .graphs {
      margin-bottom: 200px;
      background-color: white;
      padding: 0px;
      border-radius: 5px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      padding-top: 20px;
      padding-bottom: 40px;

      @media (max-width: 768px) {
        .LineChart,
        .BarChart {
          width: 95%;
        }
      }

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 40px;
        margin-left: 50px;
        margin-top: 40px;
      }
    }
  }
  .fullSlide {
    padding: 50px 0px;
    padding-top: 25px;
    background-color: transparent;
    padding-bottom: 50px;
    background-repeat: repeat;
    background-position: left center;
    background-size: 20px 20px;
    min-height: calc(100vh - 80px);
    height: 100%;

    @media (max-width: 768px) {
      height: auto !important;
    }

    .contentBox {
      margin: 0px auto;
      padding: 0px 40px;
      padding-top: 40px;

      .insights {
        max-height: 400px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;

        .insight {
          background-color: #fff2eb;
          padding: 20px;
          border-radius: 10px;
          margin-bottom: 1rem;
          display:flex;
          justify-content: space-between;

          p {
            color: var(--013247, #013247);
            font-size: 13.233px;
            font-family: Inter;
            line-height: 140%;
            padding: 0;
            margin: 0;
            width:calc(100% - 40px);
          }

          svg {
            color:rgba(255, 158, 103, 0.54)
          }
        }
      }

      h3 {
        color: #013247;
        font-size: 20px;
        font-family: Inter;
        font-weight: 600;
        line-height: 120%;
      }

      .contentBox__title {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .buttons {
          display: flex;

          @media (max-width: 768px) {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          button {
            margin-right: 15px;
            font-size:15px;
            padding:5px 30px;
            box-shadow: none;

            @media (max-width: 768px) {
              margin-right: 0;
              width: 45% !important;
              padding: 10px;
              font-size: 14px;
            }

            &:nth-child(2) {
              background-color: transparent;
              border-radius: 6.126px;
              border: 0.511px solid var(--ff-9-e-67, #FF9E67);
              color: var(--0-b-455-f, #0B455F);
              text-align: center;
              font-family: Inter;
              font-weight: 600;
              line-height: 140%;
              box-shadow: none;
            }

            &.yellow {
              background: #ffde67;
              border-radius: 6px;

              &.disabled {
                opacity: 0.8;
              }
            }
          }
        }

        .text {
          width: 50%;

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }

      .cBox {
        height: 100%;
        p {
          color: #000;
        }

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;

          p {
            max-height: 300px;
            overflow: scroll;

            &::-webkit-scrollbar {
              display: none; /* Safari and Chrome */
            }
          }
        }

        .year {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          color: #aaaaaa;
          border-bottom: 1px solid #aaaaaa;
          padding-bottom: 20px;
          flex: none;
          margin-top: auto;
        }

        button {
          align-self: bottom;
          margin-bottom: 0px;
        }

        .insights {
          margin: 0 !important;
          padding: 0;
        }
      }

      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 30px;
        margin: 0;
        color: #000;
        margin-bottom: 20px;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #000;
      }
    }

    .add {
      transform: translateY(75px);
      margin: 0px auto;
    }
  }

  .slideBox {
    width: 100%;
    border-radius: 6px;
    display: flex;
    min-height: 540px;
    margin: 0px 20px;
    height: auto;
    justify-content: space-between;
    margin-top:10px;

    @media (max-width: 768px) {
      width: 90% !important;
      margin: 0px !important;
      padding: 0 !important;
      height: auto;
    }

    .contentBox {
      width: 40%;
      background: #ffffff;
      border: 0.5px solid #aaaaaa;
      padding: 40px;
      margin-left: 0;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .graphs {
      width: 57% !important;
      background: #ffffff;
      border: 0.5px solid #aaaaaa;
      padding: 40px;
      height: auto;
      position: relative;
      padding-bottom: 0px;

      @media (max-width: 768px) {
        width: 100% !important;
        margin-top: 40px;
        padding: 15px;
      }

      .Selector {
        position: absolute;
        top: -40px;

        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          margin-left: -10px;
        }

        button {
          width: 180px;
          height: 40px;
          background: #ffde67;
          border-width: 0.5px 0.5px 0px 0.5px;
          border-style: solid;
          border-color: #aaaaaa;
          flex: none;
          order: 2;
          flex-grow: 0;
          margin-right: 30px;
          cursor: pointer;
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          color: #7b6002;

          @media (max-width: 768px) {
            width: 40%;
          }

          &.active {
            background: #fff9e4;
            border-color: #aaaaaa;
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 16px;
            color: #ffde67;
          }

          &:hover {
            width: 180px;
            height: 40px;
            background: #ffde67;
            border-width: 0.5px 0.5px 0px 0.5px;
            border-style: solid;
            border-color: #aaaaaa;
            flex: none;
            order: 2;
            flex-grow: 0;
            margin-right: 30px;
            cursor: pointer;
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 16px;
            color: #7b6002;
          }
        }
      }
    }

    .chart {
      height: 90%;
    }

    .LineChart {
      width: 100% !important;
      background: #ffffff;
      height: 90%;

      .no-data {
        h3 {
          font-size: 13px;
          margin: 0;
          padding: 0;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #aaaaaa;
          margin-bottom: 10px;
        }
      }

      canvas {
        width: 100%;
        height: 60%;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0px 10px;
      width: 80%;
      padding: 20px;

      .content {
        p {
          font-size: 15px !important;
          line-height: 1.4em !important;
        }
      }

      .insights {
        flex-wrap: wrap;
        .btn {
          width: 100%;
          margin-bottom: 0px;
        }
      }
    }

    .insights {
      display: flex;

      .btn {
        margin-right: 20px;
        padding: 10px 24px;
        font-size: 12px;
        background-color: #ff67a7;
        color: #fff;
        border: none;
        text-transform: uppercase;

        &:hover {
          background-color: darken(#ff67a7, 15);
        }

        svg {
          color: #fff;
        }

        &.blank {
          background-color: transparent;
          color: #cda414;
          background: #ffe6f1;
          border: 3px solid #ffe6f1;
          border-radius: 6px;
          text-transform: uppercase;
          color: #ff67a7;

          &:hover {
            background-color: darken(#ffe6f1, 5);
            border: 3px solid darken(#ffe6f1, 5);
          }

          svg {
            color: #ff67a7;
          }
        }

        &.edit {
          background-color: transparent;
          border: 3px solid #ffe6f1;
          text-transform: uppercase;
          color: #ff67a7;
          margin-bottom: 20px;

          @media (max-width: 768px) {
            width: 100%;
          }

          &:hover {
            background-color: darken(#ffe6f1, 5);
            border: 3px solid darken(#ffe6f1, 5);
          }

          svg {
            color: #ff67a7;
          }
        }
      }
    }

    .emoji {
      padding-right: 60px;
      padding-top: 0px;
      margin-top: -40px;
      span {
        font-size: 80px;
        display: block;
        height: 80px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      h3 {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;

        span {
          font-weight: 900;
          font-size: 36px;
          line-height: 36px;
        }
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4em;
      }

      .year {
        flex-grow: 1;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        color: #58c5f3;
      }
    }
  }

  .controls {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 20px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0px 0px;
      .left,
      .right {
        svg {
          font-size: 45px !important;
          z-index: 100;
        }
      }

      .left {
        transform: translate(10px, 0px);

        svg {
          color: black !important;
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        }
      }

      .right {
        transform: translate(-10px, 0px);

        svg {
          color: black !important;
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .left,
    .right {
      svg {
        font-size: 90px;
        color: #000;
        margin-top: 200px;

        &:hover {
          color: darken(#fff, 20);
          cursor: pointer;
        }
      }
    }
  }


  .example-track {
    height: 5.927px;
    background: rgba(79, 114, 130, 0.42);
  }

  .slider-group {
    margin-bottom: 2rem !important;

    .horizontal-slider {
      margin-top: 15px;
    }
  }

  .example-thumb {
    outline: none;
    font-size: 30px;
    margin-top: -5px;
    cursor: pointer;
  }

  .slider {
    width: 100%;
    display: flex;
    margin-top: 50px;
    padding-bottom: 3rem;

    .bar {
      width: 100%;
      height: 20px;
      background-color: #0d8cc2;
      display: block;
      position: absolute;
      z-index: 2;
    }

    .yearsContainer {
      width: 1280px;
      margin: 0 auto;
      position: relative;

      .verticalLine {
        width: 8px;
        height: 70px;
        background-color: #fff;
        display: block;
        position: absolute;
        z-index: 0;
        bottom: 20px;
        left: 120px;
      }
    }
    .years {
      display: block;
      z-index: 10;
      position: relative;
      color: black;
      width: 10000%;
      padding: 20px;
      margin-top: -29px;
      height: 60px;
      overflow-y: hidden;

      .slide {
        background: #0d8cc2;
        border-radius: 100px;
        display: inline-block;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 36px;
        margin-right: 50px;
        width: 180px !important;
        padding: 10px 0px;
        margin-bottom: 100px;

        &.active {
          background-color: #fff;
          .year {
            color: #0d8cc2;
          }
        }
        .year {
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

.LifeLine-intro {
  max-width: 95rem;
  margin: 0px auto;
  display: flex;
  transform: translateY(50%);
  padding-bottom:0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:80%;
  border-radius: 20px;

  .left {
    width: 65%;
    padding-right: 30px;
    @media (max-width: 768px) {
      width: 100% !important;
    }
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 48px;
      margin: 0;
      padding: 0;
      background-color: transparent;
      margin-bottom:20px;
    }
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      margin-bottom:20px;
    }
  }

  .right {
    width: 35%;
    @media (max-width: 768px) {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  }
}
