.Widget.Values {
  width: 31.5%;
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0px;
  border-radius: 8px;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 8px;
  position: relative;
  margin-right: 0;
  padding-bottom: 10px;
  align-self: stretch;

  &.inProgress {
    border: 6px solid #2a7394;
    background: #dbecf4;

    h5 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
      margin-top: 1rem;
    }

    .btn {
      border-radius: 20px;
      background: var(--hero-blue-0-b-455-f, #0b455f);
      display: flex;
      padding: 6px 15px;
      gap: 10px;
      color: var(--white-ffffff, #fff);
      text-align: center;
      font-family: Inter;
      font-size: 12.645px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 17.703px */
      border: none;
      margin-left: auto;

      &:hover {
        cursor: pointer;
        background-color: darken(#0b455f, 10);
      }
    }

    svg {
      margin-top: auto;
    }

    p {
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Small Body */
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
    }

    .bar {
      background-color: #fff;
      width: 100%;
      height: 4px;
      border-radius: 1px;
      margin-bottom: 1rem;

      .progress {
        height: 4px;
        width: 50%;
        background-color: var(--hero-blue-0-b-455-f, #0b455f);
      }
    }
  }

  &.Size-1 {
    padding-bottom: 10px;

    .image {
      margin-top: 1rem;
      height: 100%;
      object-fit: cover;
      margin-bottom: 1rem;
      border-radius: 10px;
      max-height: 60%;
    }

    .item {
      height: 100%;
      display: flex;
      flex-direction: column;

      p {
        height: 3em;
      }
    }

    canvas {
      height: 260px;
    }

    .activeMoment {
      padding: 0;
      margin-top: auto;

      .item {
        padding: 15px 20px;
        height: auto;
        background: #ebf3f7 !important;
        border-radius: 10px;
        background: #ebf3f7;
      }

      svg {
        padding: 0;
        margin: 0;
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
      h4 {
        display: flex;
        align-items: center;
        color: var(--1-e-6484, #1e6484) !important;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 13.2px */
        text-transform: uppercase;
      }

      h2 {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0.5em;
        line-height: 110%; /* 17.6px */
        color: var(--hero-blue-0-b-455-f, #0b455f);
      }

      p {
        padding: 0;
        margin: 0;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        color: #155673;
      }
    }
  }

  .empty {
    display: flex;
    width: 201.945px;
    padding: 10px;
    align-items: center;
    gap: 9.972px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--white-ffffff, #fff);
    color: #155673;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.359px */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: none;
    background: var(--hero-yellow-fede-66, #fede66);
    margin-top: 1rem;
    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: #155673;
      color: #fff;

      svg {
        fill: #fff;

        path,
        g {
          fill: #fff;
        }
      }
    }

    svg {
      background-color: transparent;
    }
  }

  &.Size-2 {
    padding-bottom: 10px;
    width: calc(63% + 25px);
    padding-right: 20px;
    padding-bottom: 2rem;
    max-height: 444px;

    .sides {
      display: flex;
      flex-shrink: 1;
      height: 300px;
      max-height: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 1rem;
      overflow: scroll;
      padding-bottom: 20px;

      button {
        border-radius: 8px;
        border: 0.5px solid var(--hero-blue-0-b-455-f, #0b455f);
        background: #ebf3f7;
        display: flex;
        width: 100%;
        height: 34px;
        padding: 10px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 16.8px */
        cursor: pointer;

        &.active {
          border-radius: 8px;
          background: var(--hero-blue-0-b-455-f, #0b455f);
          color: var(--white-ffffff, #fff);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 16.8px */
        }
      }
    }

    .right {
      width: 45%;
      padding: 40px;
      padding-top: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 20px;
      overflow: scroll;

      .top {
        height: 60%;
        width: 100%;
        object-fit: cover;
        padding: 0;
        border: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .bottom {
        display: flex;
        height: 40%;
        padding: 0;
        margin-top: 5px;
        overflow: scroll;
        width: 100%;
        border: none;
        justify-content: flex-start;

        &:last-child {
          margin-right: 0;
        }

        &::-webkit-scrollbar {
          display: block; /* Safari and Chrome */
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none !important;
          width: 7px !important;
          height: 9px !important;
          margin-top: 2rem;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 7px !important;
          background-color: rgba(0, 0, 0, 0.5) !important;
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
          margin-top: 15px;
        }

        img {
          max-height: 100%;
          object-fit: cover;
          height: 100% !important;
          margin-right: 5px;
          width: 23%;
        }
      }
    }
  }

  > h2 {
    color: var(--hero-blue-0-b-455-f, #0b455f);
    font-family: Inter;
    font-size: 23.932px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 28.719px */
    position: relative;
    z-index: 2;
  }

  .navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 0rem;
    margin-top: 20px;
    max-width: 100%;
    justify-self: flex-end;

    > button {
      width: 13px;
      height: 13px;
      background-color: #cedadf;
      position: relative;
      z-index: 10;
      border-radius: 100%;
      border: none;
      margin: 0px 2px;
      cursor: pointer;
      flex-shrink: 0;
      &.active,
      &:hover {
        background-color: #0b455f;
      }
    }

    .next,
    .prev {
      background-color: transparent;
      border: none;
      font-size: 2rem;
      color: #fb9e67;
      margin: 0;
      padding: 4px;
      z-index: 15;
      position: relative;

      &:hover {
        color: darken(#fb9e67, 10);
        cursor: pointer;
      }
    }
  }

  .activeMoment {
    padding: 0;

    .item {
      padding: 15px 20px;
      height: auto;
      background: #ebf3f7 !important;
    }

    svg {
      padding: 0;
      margin: 0;
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
    h4 {
      display: flex;
      align-items: center;
      color: var(--1-e-6484, #1e6484) !important;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 13.2px */
      text-transform: uppercase;
    }

    h2 {
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      margin-bottom: 0.5em;
      line-height: 110%; /* 17.6px */
      color: var(--hero-blue-0-b-455-f, #0b455f);
    }

    p {
      padding: 0;
      margin: 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      color: #155673;
    }
  }
}
