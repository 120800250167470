.memberships {
  max-width:90rem;
  margin: 0 auto;

  &.success {
    max-width: 100%;
  }


  .bottomContent {
    max-width:90rem;
    padding: 0px 40px;
    margin: 0px auto;
    padding-top: 3em;
    display: flex;
    flex-wrap: wrap;

    .boxes {
      display: flex;
      margin-bottom: 10em;
      width: 100%;
      justify-content: space-between;

      .box {
        width: 48%;
        background: #dcf4ff;
        border: 1px solid #58c5f3;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;

        &:hover {
            cursor: pointer;
            .bottomBox {
                background: darken(#0d8cc2,15);
            }
        }

        .bottomBox {
          width: 100%;
          background: #0d8cc2;
          border-radius: 0px 0px 6px 6px;
          display: flex;
          align-items: center;
          padding: 30px;
          color: #fff;
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 16px;
          justify-content: space-between;

          svg {
            margin-left: 1em;
          }
        }

        h3 {
          text-align: center;
          padding: 50px;
          padding-bottom: 20px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
        }
      }
    }

    h2 {
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 50px;
    }
    p {
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
    }
  }

  .top {
    margin-top: -20px;
    background-image: radial-gradient(
      circle at center,
      /* dot color */ rgba(8, 112, 158, 1) 2px,
      rgba(8, 112, 158, 1) 0,
      /* background color */ rgb(0, 85, 121) 2px,
      rgb(0, 85, 121) 100%
    );
    padding-bottom: 0;
    background-repeat: repeat;
    background-position: left center;
    background-size: 20px 20px;
    min-height: 600px;

    .top__container {
      max-width:90rem;
      margin: 0 auto;
      padding: 0px 40px;
      padding-top: 40px;
      display: flex;
      justify-content: space-between;

      .left,
      .right {
        width: 47%;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        .celebrationBox {
          background-color: #fff;
          padding: 2em;
          background: #ffffff;
          border: 0.5px solid #aaaaaa;
          border-radius: 6px;
          z-index: 2;

          span {
            color: #000;
            text-align: center;
            margin: 0px auto;
            display: block;
            margin-top: 20px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-left: 0.5em;
            }
          }

          .image {
            margin: 0px auto;
            margin-top: -140px;

            svg {
              margin: 0px auto;
              display: block;
            }
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        margin-top: 2em;

        li {
          color: #fff;
          list-style: none;
          display: flex;
          align-items: center;
          margin-bottom: 1em;

          &:before {
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" style="fill:black"/></svg>');
            color: #fff;
            width: 1em;
            height: 1em;
            padding: 0.4em;
            background-color: #88d47b;
            display: block;
            border-radius: 100%;
            margin-right: 1em;
          }
        }
      }

      h1 {
        color: #fff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 50px;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #fff;
      }
    }
  }
  

  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 2rem;
    }
  }

  button {
    margin-top: 1.5em;
  }
}
