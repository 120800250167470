.activity_user {
    display:flex;
    align-items: center;
    align-content: center;
    width:100%;
    margin-bottom:2rem;
    margin-top:2rem;

    p {
        margin: 0;
        padding:0;
    }
    .activity_user__name {
        width:20%;
    }

    .activity_user__link {
        width:80%;

        input {
            width:400px;
        }
    }

    .activity_user__save {
        margin:0;

        button {
            margin-top:0 !important;
        }
    }

}