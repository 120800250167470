@import "../theme";

.dashboard {
  padding-bottom: 30px;
  margin-top: -30px;

  .yellowBG {
    background-color: #fff9e4;
    padding-bottom: 50px;
    background-image: radial-gradient(
      circle at center,
      /* dot color */ rgb(220, 220, 220) 2px,
      rgb(220, 220, 220) 0,
      /* background color */ rgb(255, 249, 228) 2px,
      rgb(255, 249, 228) 100%
    );

    background-repeat: repeat;
    background-position: left center;
    background-size: 24px 24px;
  }
  .container {
    padding-top: 0px;
    padding-bottom: 60px;
    max-width:90rem;
  padding:0px 40px;
    margin: 0px auto;
    text-align: left;
    padding: 0px 20px;

    .cards {
      margin-top:40px;
      .card {
        background: #ffe6f1;
        border: 1px solid #ff67a7;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        width: 45%;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 20px;
        }

        
        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 300;
          font-size: 36px;
          line-height: 48px;
          color: #000000;
          padding: 40px;
        }

        .action {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 30px 20px 30px 40px;
          height: 90px;
          background: #ff67a7;
          border-radius: 0px 0px 6px 6px;
          align-items: center;
          width: 100%;

          a {
            height: 90px;
            width: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            font-family: "Inter";
            font-style: normal;
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;

            svg {
              margin-left: auto;
            }
          }
        }
      }
    }

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 48px !important;
      line-height: 60px;
      margin-top: 1em;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: left;
      margin-top: 1em;
      text-align: left;
    }

    .white-background {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 15px;
      }

      .left,
      .right {
        width: 50%;
        padding: 20px;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .left > div {
        width: 100%;
        height: 100%;
        background-color: grey;
      }

      h2 {
        margin: 0;
        padding: 0;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        text-align: left;
        margin: 0;
        padding: 0;
        margin-top: 15px;
      }

      .btn {
        margin-top: 20px;
      }
    }
  }

  .bluebg {
    width: 100%;
    background-color: #58c5f3;
    margin-top: 30px;
    background-image: url("../../images/background.png");
    margin-top: 60px;
    display: flex;
    align-items: center;

    .container {
      margin: 0px auto;
      text-align: left;
      padding: 0px 0px;
      background-color: $yellow;
      min-height: auto;
      margin-top: 30px;
      width: 100%;
      max-width: 985px;
      margin-bottom: 30px;
      padding: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;

      .left,
      .right {
        padding: 20px;

        width: 60%;

        @media (max-width: 768px) {
          width: 100%;
        }

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 800;
          font-size: 36px !important;
          line-height: 36px;
          margin: 0;
          padding: 0;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          /* or 150% */
        }
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .buttons {
          padding: 20px 0px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  .cards {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .card {
      width: 48%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 2em;

      &.widget {
        display: block;
        h3 {
          width: 100% !important;
        }

        .videoPlayer {
          box-shadow: none;
          border: none;

          .actionBox {
            border-top: none;
          }
        }

        .btn {
          display: inline-block;
        }

        .container {
          max-height: 300px;
        }

        .story {
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .image {
        width: 100%;
        height: 300px;
        background-color: grey;
      }

      .gray {
        opacity: 0.3;
      }

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 36px;
        margin: 0.5em 0px;
        padding: 0;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
