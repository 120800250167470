.highlightTop.highlight_moment {
  background-color: white;
  padding: 40px 30px;

  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 24px;
  word-break: break-word;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    height: 600px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  a {
    color: red;
  }

  .left {
    width: 33%;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
      margin-top: -30px;
    }

    img {
      border-radius: 5px;
      max-height: 170px;
      max-width: 85%;
      margin: 0px auto;
      display: block;
    }
  }

  .right {
    width: 63%;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      /* identical to box height, or 20px */

      /* #013247 */

      color: #013247;
    }

    &.full {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    h2 {
      margin-bottom: 1rem;
      span {
        font-size: 26px;
        font-weight: 700;
        font-weight: 900;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        /* or 34px */

        /* #013247 */

        color: #013247;
      }
    }
  }

  .link_data {
    background-color: lighten(#ececec, 3);
    padding: 20px;
    border-radius: 10px;

    &:hover {
      background-color: #dcdcdc;
    }

    h3 {
      font-size: 1.2em;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    p {
      color: #000;
      font-size: 0.8em;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      /* or 20px */

      /* #013247 */

      color: #013247;

      opacity: 0.6;
    }
  }

  .type {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.25;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }

  p {
    word-wrap: break-word;
    font-size: 1.2em;
    line-height: 1.5em;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  a {
    padding: 0 !important;
    margin: 0;
    margin-left: 0px;
    color: #0d8cc2 !important;
    word-wrap: break-word;
    margin-top: 0px;
    border-bottom: 3px solid #a2def8;
    border-color: #a2def8 !important;
    font-weight: 700;

    &:hover {
      color: darken(#0d8cc2, 15) !important;
      border-bottom: 3px solid;
      border-color: darken(#a2def8, 25) !important;
    }
  }
}
