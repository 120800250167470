.collaboratorAdd {
  overflow: scroll;
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #ececec;
    padding-top: 10px;
    margin-bottom: 15px;

    button {
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 10px;
    }
  }

  .formData {
    height: calc(75% - 50px);
    overflow-y: scroll;
    padding: 0px 5px;
    margin-top: -20px;
    padding-top: 15px;

    button:disabled {
      opacity: .2;
    }

    > p {
      color: var(--013247, #013247);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    }
  }

  .formData > h3 {
    color: #013247;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
    display: flex;
    width: 100%;
    margin-bottom: 0.5rem;

    span {
      margin-left: auto;
      color: #013247;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 24px */
    }
  }

  .inviteList {
    margin-top: auto;
    border-top: 2px solid #ececec;
    padding-top: 20px;
    width: 100%;
    display: flex;
    overflow: scroll;
    height: calc(30% - 50px);
    margin-bottom: 0px !important;

    > p {
      color: var(--013247, #013247);
      font-family: Inter;
      font-size: 13.577px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.008px */
    }

    .inviteContainer {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: .5rem;

      svg {
        border-radius: 100%;
        padding: 5px;
        color: #3485aa;
        border: 2px solid #3485aa;
        margin: 0;
        margin-right: 15px;
      }

      p {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 13.577px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.008px */
        margin: 0;
        padding: 0;
      }

      p:nth-child(3) {
        margin-left: auto;
        color: #2a7394;
        font-family: Inter;
        font-size: 13.577px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.008px */
      }
    }

    p {
      font-weight: 600;
    }
  }
  .inputField {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    textarea,
    input {
      background-color: #fff;
      border: 0px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 1px 10px 0px #dec0b5;
    }

    label {
      color: #013247;

      /* Button Body */
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 18.7px */
    }
  }

  .btn.secondary {
    margin-bottom: 2rem;
    width: 150px;
    margin-left: auto;
  }
  .grouping {
    label {
      color: var(--013247, #013247);

      /* Small Body */
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 18px */
    }
    .group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }
    button {
      background-color: transparent;
      border: none;
      color: var(--hero-blue-0-b-455-f, #0b455f);
      /* Button Body */
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 18.7px */
      padding: 9px 30px;
      margin: 0;
      margin-top: 0rem;
      cursor: pointer;

      &.active,
      &:hover {
        border-radius: 50px;
        background: var(--hero-blue-0-b-455-f, #0b455f);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }
  }
}

.collaboratorRight {
  padding: 30px;
  padding-right: 50px;
  width: 100%;
  .email {
    background-color: #f4eeec;
    max-width: 100%;
    .header {
      background-color: #0c455f;
      display: flex;
      height: 100px;
      align-items: center;
      justify-content: center;

      img {
        max-height: 40px;
      }
    }

    .content {
      padding: 20px 30px;

      h3 {
        margin-bottom: 1.5rem;
      }

      p {
        color: #0c455f;
      }

      .box {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;

        button {
        }

        p {
          color: #0c455f;
          font-weight: 700;

          &:nth-child(2) {
            font-weight: 400;
          }
        }
      }
    }
  }
}
