.chooseGoals {
  display: flex;
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .right {
      padding-top: 0;
      padding-bottom:0;
    }

    .coachTips {
      margin-top: 1rem;

      .number {
        display: flex;
        align-items: center;

        img {
          border-radius: 100%;
          max-width: 32px;
          margin-right: 1rem;
        }

        h4 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 14.543px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 20.36px */
        }
      }

      .right {
        padding-top: 0;
        width: 100% !important;
        padding-right: 0;
      }

      ul {
        margin-left: 15px;
        padding: 0;
        opacity: 0.6;
        margin-top: 10px;

        li {
          color: #013247;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.4em;
          margin-bottom: 8px;
        }
      }
    }

    .skrim {
      background: #013247;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
      opacity: 0.67;
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 40px 50px;
      border-radius: 5px;
      width: 80%;
      max-width: 1200px;
      max-height: 95vh;
      overflow: scroll;
      z-index: 20;
      display: flex;
      padding-bottom: 35px;

      .actions {
        display: flex;
        margin-top: 0 !important;
      }

      p {
        color: #013247;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
        opacity: 0.6;
      }

      .left {
        width: 30%;
      }

      .right {
        width: 70%;
      }

      svg {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 16px;
        opacity: 0.3;
        cursor: pointer;
      }

      .actions {
        margin-left: auto;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 3rem;
        align-items: center;

        span {
          margin-right: 1rem;
          color: #013247;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        button.btn {
          margin-left: auto;
          margin: 0;
        }

        button.text {
          color: #013247;
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          text-decoration: none;
          line-height: 140%; /* 19.6px */
          margin-right: 2rem;
        }
      }

      h5 {
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
        text-decoration: none;
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .images {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
      }

      .row {
        display: block;
      }

      .inputBox {
        margin-bottom: 40px;
      }

      h2 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 38.4px */
        margin-bottom: 1rem;
      }

      button {
        margin-left: auto;
        margin-top: 3rem;
      }

      h5 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        text-decoration-line: underline;
        margin-top: 1.4rem;
      }

      .icons {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .item {
          display: flex;
          margin-top: 1rem;
          align-items: center;
          margin-bottom: 0.2rem;

          h4 {
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
            margin-bottom: 0.2rem;
          }

          p {
            color: #013247;

            /* Small Body */
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
            padding: 0;
            margin: 0;
          }

          span {
            width: 32px;
            height: 32px;
            background-color: #013247;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin-right: 1em;
            flex-shrink: 0;
            flex-grow: 0;
          }
        }
      }

      label,
      input,
      textarea,
      select {
        width: 100%;
        text-align: left;
      }
    }
  }
  .sidebar {
    width: 400px;
    height: 100vh;
    @media (max-width: 1200px) {
      height: calc(100vh - 80px);
    }
    background-color: #fff;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .inner {
      flex-direction: column !important;
    }

    .goals {
      display: flex;
      flex-direction: column;

      textarea {
        background-color: #fff;
        border-radius: 7px;
border: 1px solid var(--hero-tan-f-4-eeec, #F4EEEC);
background: var(--white-ffffff, #FFF);

/* Text box */
box-shadow: 0px 4px 14px 0px rgba(232, 221, 218, 0.36);
      }

      button {
        margin-bottom: 1rem;
        border-radius: 8px;
        border: 2px solid var(--2-a-7394, #2a7394);
        background: #fff;
        display: inline-flex;
        padding: 16px 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        color: var(--3485-aa, #3485aa);
        text-align: left;
        cursor: pointer;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        &:hover,
        &.active {
          background: var(--2-a-7394, #2a7394);
          color: #fff;
        }
      }
    }

    .bottom {
      margin-top: auto;
      padding: 20px 0px;

      button {
        font-size: 15px;
        box-shadow: none;
        font-weight: 600;
        border-radius: 10px;

        &:disabled {
          opacity: 0.5;
        }
      }

      .blank {
        border-radius: 12px;
        background: #f0f2f5;
        border: none;
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        &:hover {
          background: darken(#f0f2f5, 10%);
        }
      }
    }

    .title {
      display: flex;
      padding: 15px;
      border-bottom: 2px solid #ececec;

      svg {
        margin-right: 1em;
        opacity: 0.7;
      }

      h3 {
        color: #013247;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
    }

    .inner {
      padding: 15px 25px;
      height: calc(100% - calc(100px + 2rem));
      overflow: scroll;
    }

    .subtitle {
      padding-bottom: 1rem;

      .opaque {
        opacity: 0.5;
        margin-top: 1rem;
      }

      h4 {
        color: var(--0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
        margin-bottom: 0.5rem;
      }

      p {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        padding: 0;
        margin: 0;
      }
    }

    .prompts {
      text-align: left;

      .prompt {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid #ececec;
        padding-bottom: 1rem;

        &:last-child {
          border-bottom: 0px;
        }

        .expanding {
          display: none;
          gap: 15px;
          flex-direction: column;

          .box {
            border-radius: 9.535px;
            background: #eaf1f4;
            display: flex;
            padding: 25px 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;

            &.yellow {
              background: #fff8e0;

              &.selected {
                background: darken(#fff8e0, 20%);
              }
            }

            &.red {
              background: #fff2eb;
              &.selected {
                background: darken(#fff2eb, 20%);
              }
            }

            &.selected {
              background: darken(#eaf1f4, 20%);
            }

            p {
              padding: 0;
              margin: 0;
              color: #0b455f;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 16.8px */
            }
          }
        }

        &.expanded {
          margin-bottom: 1rem;
          .expanding {
            display: flex;
          }
        }

        .title {
          text-align: left;
          display: flex;
          flex-direction: column;
          border: none;
          padding: 0;
          position: relative;

          span {
            color: var(--3485-aa, #3485aa);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
          }

          h3 {
            color: var(--013247, #013247);

            /* Button Body */
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
          }

          p {
            color: #0b455f;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 16.8px */
            margin-top: 10px;
          }
        }
      }
    }
  }

  .right {
    width: calc(100% - 400px);
    padding: 40px;

    .white-background {
      border: none;

      h3 {
        color: #000;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 19.689px; /* 82.038% */
      }

      p {
        color: var(--013247, #013247);
        margin-top: 1rem;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
        opacity: 0.6;
      }

      .goal {
        border-radius: 6px;
        border: 1px solid var(--hero-tan-f-4-eeec, #f4eeec);
        background: #fff;
        display: flex;
        padding: 18px 21px 18px 25px;
        justify-content: flex-start;
        align-items: center;
        gap: 259px;

        h3 {
          color: var(--3485-aa, #3485aa);

          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          text-align: left;
        }
      }
    }
  }
}
