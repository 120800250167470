.chatMessage.addon-strengths {
  display: flex;
  position: relative;
  margin-top: 1.5rem;

  .chatMessageBox {
    display: flex;
    width: 100%;
    padding: 0px 20px;
    align-items: flex-start;
    padding-right: 0;

    > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .hide {
      display: none;
    }

    img.hide {
      display: block;
      opacity: 0;
    }

    .chatMessageTitle {
      max-width: 73%;
      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }
      .bubble {
        background: #fff;
        margin: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0px 20px 20px 20px;
        padding: 15px 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-width: 85px;

        &.yellow {
          background-color: #fede66;
          background-image: url("../../../../images/chat_bubble.png");
          background-position: bottom left;
          background-repeat: no-repeat;
          padding-top: 2rem;
          min-width: 800px;

          @media screen and (max-width: 768px) {
            min-width: 250px;
          }

          .left {
            h3 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 140%;
              color: #013247;
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              opacity: 1;
              /* or 21px */

              /* #013247 */

              color: #013247;
            }
          }

          .right {
            button {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 17px;
              line-height: 140%;
              padding: 15px 30px !important;
              text-align: center;
              color: #0b455f;
              cursor: pointer;
            }
          }
        }

        @media (max-width: 768px) {
          min-width: 300px;
        }

        .container {
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            flex-wrap: wrap;
          }

          .left {
            width: 60%;
            @media (max-width: 768px) {
              width: 100%;
            }
          }

          .right {
            @media (max-width: 768px) {
              width: 100%;
            }
            button {
              background: #ff9e67;
              box-shadow: 3.5468px 3.5468px 0px rgba(255, 158, 103, 0.24);
              border-radius: 7.0936px;
              padding: 6px 20px;
            }
          }
        }

        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 130%;
          /* or 26px */

          display: flex;
          align-items: flex-end;

          color: #000000;
        }

        .option {
          background: #ffffff;
          border: 0.8px solid #dddddd;
          border-radius: 6px;
          padding: 1rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          .check {
            width: 40px;
            height: 40px;
            border: 1px solid #e1e1e1;
            border-radius: 100%;
            display: block;
            margin-left: auto;

            &.active {
              background-color: #0a455f;
            }
          }

          h5 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.4rem;
            width: calc(100% - 70px);
          }
        }

        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 1rem;
          letter-spacing: -0.02em;
        }

        .type {
          background-color: #fff;
          padding: 10px;
        }

        .dot-flashing {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite linear alternate;
          animation-delay: 0.5s;
          margin-left: 1rem;
        }
        .dot-flashing::before,
        .dot-flashing::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }
        .dot-flashing::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 0s;
        }
        .dot-flashing::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #000;
          color: #000;
          animation: dot-flashing 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes dot-flashing {
          0% {
            background-color: #000;
          }
          50%,
          100% {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 1.4em;
          width: 100%;
          margin: 0;
          padding: 0;

          &.waiting {
            border-radius: 15px 15px 15px 15px;
          }
        }
      }
    }
  }
}

.strengthsExercise {
  .content {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    max-width: 80%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 100%;
        width: 20px;
        height: 20px;
      }
    }

    .exerciseContainer {
      margin-left: 1rem;
      @media (max-width: 768px) {
        margin-right: 30px;
        width: 100%;
      }
      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }

      .bubble {
        background-color: #fff;
        width: 100%;
        display: flex;
        padding: 20px;
        border-radius: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1rem;

        .activity {
          background-color: #fff;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .values {
            flex-direction: column;
            flex-direction: column;
            background-color: transparent;
            min-height: auto;
          }

          @media (max-width: 768px) {
            flex-wrap: wrap;
            width: 100%;
          }

          .left,
          .right {
            width: 45%;

            @media (max-width: 768px) {
              width: 100%;
            }

            > h4 {
              margin-bottom: 1rem;
            }

            > button:disabled {
              opacity: 0.5;
            }
          }

          .left {
            max-height: calc(100vh - 400px);
            overflow: scroll;
          }

          .value {
            background: #ffffff;
            border: 2px dashed #c1c1c1;
            border-radius: 6px;
            padding: 12px 10px;
            margin-bottom: 1rem;
            color: #013247;
            cursor: pointer;
            background: #ffffff;
            border: 1px dashed #0b455f;
            border-radius: 6px;
            background: rgba(1, 50, 71, 0.06);
            border: 2px dashed rgba(1, 50, 71, 0.5);
            border-radius: 7px;

            svg {
              margin-right: 1rem;
            }
          }

          .myValue {
            background: #fafafa;
            border: 2px dashed #c1c1c1;
            border-radius: 6px;
            padding: 10px 10px;
            margin-bottom: 1rem;
            color: #013247;
            cursor: pointer;

            &.filled {
              border: 2px solid #c1c1c1;
              padding: 6px 10px;
            }

            span {
              margin-right: 1rem;
              font-weight: 700;
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
            }

            p {
              display: inline;
              font-weight: 500;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 30px;
            }

            svg {
              margin-right: 1rem;
            }
          }
        }

        h5 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 120%;
          width: 100%;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #6c7275;
          margin-top: 1rem;
        }
      }
    }
  }
}
