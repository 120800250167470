@import "../../../theme.scss";


.add {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin-top:30px;

    h2 {
        width: 100%;
        margin-bottom:30px;
    }

    .container {
        width: 100%;

        #async-example {
            background-color: white;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
            padding:10px;

            .dropdown-item {
                margin-bottom:15px;
                color:#000;
                padding:10px;
                img {
                    width: 30px;
                    height: 30px;
                    margin-right:5px;
                    border-radius: 100%;
                }
            }
        }

        h3 {
            margin:0;
            padding:0;
        }
        
        .inputBox {
            display: flex;
            flex-direction: column;
            margin-bottom:30px;
        }
    }
}