@import "../../../theme.scss";

.eventsAdmin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 40px;
  position: relative;
  margin-top:30px;
  h2 {
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;
  }

  .crews {
    padding: 0;
  }

  .right {
    width: 70%;
    padding: 30px;
    background-color: white;
    margin-top: 20px;

    .program {
      display: flex;
    }

    .crew {
      margin-bottom: 3em;

      h4 {
        font-weight: 300;
        margin-top: 0.5em;
        font-size: 17px;
        margin-left: 0.5em;
        margin-bottom: 2em;
      }

      h5 {
        margin-left: 0.5em;
        font-weight: 200;
      }
    }

    .members {
      display: flex;
      margin-top: 1em;
      width: 100%;
      flex-wrap: wrap;

      .crew__member {
        display: flex;
        flex-direction: column;
        margin-right: 2em;
        text-align: center;
        align-items: center;
        width: 200px;

        img {
          width: 3em;
          border-radius: 100%;
          height: 3em;
        }
      }
    }
  }
}
