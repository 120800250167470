.crew-mailing-list {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.associated-text {
  color: green;
  font-weight: bold;
}

.not-associated-text {
  color: gray;
  font-weight: bold;
}
