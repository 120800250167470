.highlightReel {
  padding-top: 20px;
  margin-bottom: 2em;
  padding-bottom: 2em;
  margin-top: 0px;
  overflow: hidden;
  position: relative;


  

  .noHighlights {
    .card {
      display: flex;
      padding: 24px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 24px;
      background: #fff;
      width: 30%;
      flex-direction: column;
      cursor: pointer;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      .plus {
        border-radius: 18px;
        background: var(
          --ecddd-8,
          linear-gradient(0deg, #ecddd8 0%, #ecddd8 100%),
          #ecddd8
        );
        display: flex;
        width: 100%;
        height: 231px;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
      }

      .arrow {
        margin-top: 5px;
        margin-left: auto;
        background-color: var(--hero-orange-ff-9-e-67, #ff9e67);
        width: 28px;
        height: 28px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h3 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        /* Heading 6 */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }
      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        /* Small Body */
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
        opacity: 0.5;
      }
    }
  }

  .insights {
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    background-color: #fff;
    border-radius: 20px;
    background: #fff;
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    overflow: scroll;
    margin-bottom:5rem;

    .insightContainer {
      display: flex;
      gap: 2rem;
      overflow: scroll;

      .insight {
        width: 240px;
        border-radius: 10px;
        background: linear-gradient(0deg, #ebf3f7 0%, #ebf3f7 100%), #ecddd8;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        gap: 1rem;

        &.blank {
          background: transparent;
          border: 1px dashed var(--3485-aa, #3485aa);

          button {
            display: flex;
            width: 158.061px;
            padding: 8px;
            flex-direction: row;
            align-items: flex-start;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 8px;
            background: #c6dde8;
            color: var(--155673, #ecddd8);
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
            box-shadow: none;
            color:#155673;
            justify-content: flex-start;
            margin-top:auto;

            svg {
              padding:0;
              margin:0;
              margin-left:10px;
            }
          }
        }

        h4 {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 16.8px */
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);

          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
        }

        svg {
          border-radius: 8px;
          background: linear-gradient(0deg, #c6dde8 0%, #c6dde8 100%), #ecddd8;
          padding: 15px;
        }
      }
    }

    > h2 {
      color: #013247;

      /* Heading 4 */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }

    .blue {
      border-radius: 8px;
      background: #ebf3f7;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      justify-content: flex-start;
      align-items: flex-start;

      .btn.primary {
        font-size:12px;
        font-weight: 800;
        padding:10px 40px;
      }

      > h3 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        margin-top: 1rem;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
      }

      textarea {
        background-color: #fff;
        border: none;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
        gap: 15px;

        svg {
          color: #0b455f;
        }

        p {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
        }

        span {
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 15.4px */
          border-radius: 50px;
          background: rgba(52, 133, 170, 0.2);
          display: flex;
          padding: 5px 10px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  .nextSteps {
    border-radius: 25px;
    background: var(--white-ffffff, #fff);
    padding: 20px;
    max-width: 90rem;
    margin: 0px auto;
    margin: 0px 0px;

    .orange {
      border-radius: 8px;
      background: #fff2eb;
      display: flex;
      width: 100%;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;

      h5 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 15.4px */
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 24px */
        margin: 0;
        padding: 0;

        span {
          color: var(--3485-aa, #3485aa);
          cursor: pointer;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }
  }

  .programIntro {
    .skrim {
      background-color: #013247;
      opacity: 0.67;
    }

    &.congrats {
      .left {
        background-color: #fff5f0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
      }

      .right {
        h2 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 100% !important;
          margin-bottom: 20px;
        }
        p {
          color: #013247;

          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
        }

        button {
          margin-left: auto;
          color: var(--hero-blue-0-b-455-f, #0b455f);
          text-align: center;
          font-family: Inter;
          font-size: 11.511px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 16.116px */
          display: flex;
          padding: 16.251px 27.085px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 6.771px;
        }
      }
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        svg {
          opacity: 0.3;
          font-size: 25px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .modal-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 40px;
      max-height: 98vh;

      .actions {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        align-items: center;
        margin-top: 2rem;

        button {
          box-shadow: none;
          display: flex;
          width: 188px;
          padding: 16.251px 27.085px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 6.771px;
          flex-shrink: 0;
          border-radius: 8.126px;
          background: var(--hero-orange-ff-9-e-67, #ff9e67);
          box-shadow: 4.06278px 4.06278px 0px 0px rgba(255, 158, 103, 0.24);

          @media screen and (max-width: 768px) {
            width: 70% !important
          }
        }

        p {
          padding: 0;
          margin: 0;
          margin-right: 1rem;
          color: #013247;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }
      }

      > .left {
        width: 35%;

        @media screen and (max-width: 768px){
          width: 100%;

          .box {
            height: 150px !important;
            margin-bottom:30px;
          }
        }

        .box {
          background-color: #FFF6F1;
          width: 100%;
          border-radius: 25px;
          height: 270px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      > .right {
        width: 60%;
        @media screen and (max-width: 768px){
          width: 100%;
        }

        h2 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 44.8px */
        }

        .coachTips {
          margin-top: 0rem;
          display: flex;

          > .right {
            width: calc(100% - 60px);

            h4 {
              color: var(--013247, #013247);
              font-family: Inter;
              font-size: 14.543px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 20.36px */
            }
          }

          .number {
            img {
              border-radius: 100%;
              width: 43px;
              height: 43px;
              margin-right: 20px;
            }
          }

          ul {
            margin-left: 15px;
            padding: 0;
            opacity: 0.6;
            margin-top: 10px;

            li {
              color: #013247;

              /* Small Body */
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 18px */
              margin-bottom:1rem;
            }
          }
        }

        .list {
          display: flex;
          flex-grow: 0;
          justify-content: space-between;

          .circle {
            background-color: #013247;
            padding: 6px;
            border-radius: 100%;
            margin-right: 10px;
            width: 43px;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              color: #fff;
            }
          }

          p {
            width: calc(100% - 60px);
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 14.543px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 20.36px */
          }
        }

        p {
          color: #013247;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        h3 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 14.543px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 20.36px */
          text-decoration-line: underline;
          margin-bottom: 20px;
        }
      }
    }
  }

  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.proactive
    .slider-single-content {
    opacity: 0.8;
  }

  .react-3d-carousel
    .slider-container
    .slider-content
    .slider-single.preactive
    .slider-single-content {
    opacity: 0.8;
  }

  .react-3d-carousel .slider-container {
    width: 100%;
    margin: 0px auto 0px -50%;
  }

  h4 {
    color: var(--hero-blue-0-b-455-f, #0b455f);

    /* Heading 6 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
  }

  .subtitle {
    margin-top: 27px;
    margin-left: 0px;
  }

  .skrim {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 400;
    cursor: pointer;
  }

  .swiper-button-next {
    color: #fff;
  }

  .swiper-button-prev {
    color: #fff;
    left: 50px;
  }
  > h2 {
    margin-bottom: 20px;
  }

  .container {
    max-width: 90rem;
    padding: 0px 40px;
    width: 100%;
    margin: 0px auto;

    @media screen and (max-width: 768px) {
      padding: 0px 20px;
    }
  }

  .subtext {
    font-weight: 700;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right:0px;
    margin-top:-40px;

    .share {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: 0px auto;
        margin-right: 6px;
      }
    }
    button {
      margin-left: 20px;
      border: none;
      padding: 12px 24px;
      font-size:12px;
      border-radius: 6px;
      font-weight: 800;
      background-color: #ff9e67;
      box-shadow: 6px 6px 0px rgba(255, 158, 103, 0.24);
      color: #0b455f;
      &:hover {
        background-color: darken(#ff9e67, 10);
        cursor: pointer;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  .top {
    background-color: transparent;
    padding: 20px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    h3 {
      color: var(--013247, #013247);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
opacity: 0.6;
    }

    @media screen and (max-width: 768px) {
      z-index: 5000;
      position: relative;
    }

    .container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }



    h2 {
      color: #0b455f;
      border: none;
      font-size: 32px;
      font-weight: 600;

      &:after {
        content: none;
        background-color: none;
      }
    }
  }

  .swiper-pagination {
    display: none;
  }

  .navigation {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    max-width: 84rem;
    margin: 0px auto;
    margin-right: 50px;
    margin-top:-30px;

    button {
      background: #0b455f;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      color: #fff;
      margin-left: 1em;
      box-shadow: none;
      outline: none;
      border: none;

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .topbg {
    margin-top: 30px;
    .swiper {
      overflow: visible;
      margin-bottom: 45px;
      background-color: red;
      padding-bottom: 50px;
      padding-top: 80px;
      background-color: transparent;
      background-repeat: repeat;
      background-position: left center;
      background-size: 20px 20px;
      padding: 0px 0px;

      &.hideNav {
        .slider-left,
        .slider-right {
          display: none !important;
        }
      }

      @media screen and (max-width: 768px) {
        height: 650px;
        width: 90%;
        margin-top: -40px;

        .slider-content {
          width: 100%;
        }
      }

      .slider-single,
      .slider-single-content {
        height: 400px;
      }

      .slider-right {
        background-color: transparent;
        top: -20px;

        > div {
          background-color: transparent;
          border: none;
          opacity: 0.6;
          &:before {
            background-color: transparent;
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            height: 40px;
            width: 40px;
            display: block;
            content: url('data:image/svg+xml,    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" style="fill:black"/></svg>');
          }
        }
      }

      .slider-left {
        background-color: transparent;
        top: -20px;
        left: -80px;

        > div {
          background-color: transparent;
          border: none;
          opacity: 0.6;
          &:before {
            background-color: transparent;
            font-family: "Font Awesome 5 Free";
            font-weight: 600;
            height: 40px;
            width: 40px;
            display: block;
            content: url('data:image/svg+xml,    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" style="fill:black"/></svg>');
          }
        }
      }
    }
  }

  .swiper-pagination {
    bottom: -30px !important;
  }

  .swiper-slide {
    width: 70%;
    display: flex;
    height: auto;
  }

  .add {
    background-color: #ff67a7;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: center;
    box-shadow: 0 6px 12px 2px rgb(0 0 0 / 25%);
    border-radius: 100000px;
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center;
    flex: 1 1;
    font-family: Inter, sans-serif;
    color: #fff;
    font-size: 1.125rem;
    line-height: 1.125rem;
    font-weight: 900;
    letter-spacing: 0.5px;
    transition: all 300ms ease-in-out;
    position: fixed;
    bottom: 20px;
    right: 50px;
    z-index: 200;

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }

    svg {
      color: #fff;
      text-align: center;
      font-size: 30px;
    }
  }

  .grid {
    margin-top: 28px;
    width: 100%;
  }
}
