.story_page {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  max-width:90rem;
  padding: 0px 40px;
  margin: 0px auto;

  @media (max-width: 768px) {
    padding:0px 10px;
  }

  .header {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      button {
        width:130px !important
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    .left,
    .right {
      width: 47%;
      margin-top: 3rem;

      @media (max-width: 768px) {
        width:100%;
      }

      .videoPlayer, .TextStory {
        max-width: 350px;
      }
    }

    .right {
      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #013247;
      }
      ul {
        margin: 0;
        padding: 0;
        margin-top: 4em;
        li {
          list-style: none;
          margin-bottom: 1rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20.244px;
          line-height: 130%;
          color: #0b455f;

          img {
            margin-right: 1rem;
          }
        }
      }
    }

    .left {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      border-right: 2px solid rgba(1, 50, 71, 0.1);

      @media (max-width: 768px) {
        border-right:0;
      }

      svg {
        margin: 0px auto;
      }
    }
  }

  .information {
    display: flex;
    margin-top: 6rem;
    flex-wrap: wrap;
    img {
      margin: 0px auto;
      display: block;
    }

    .membership {
      position: relative;
      background-color: #0b455f;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
      flex-direction: column;
      margin-bottom: 60px;
      border-radius: 7px;

      img {
        position: absolute;
        bottom: 0;
        right: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }

      button {
        margin-top: 4rem;
      }

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 120%;
        text-align: center;
        color: #ffffff;
      }
    }

    .blocks {
      display: flex;
      width: 100%;
      margin-top: 3rem;
      justify-content: space-between;
      margin-bottom: 90px;
      flex-wrap: wrap;

      .block {
        width: 31%;
        margin-bottom:2rem;
        @media (max-width: 768px) {
          width:100%;
        }
        img {
          margin-bottom: 1rem;
        }

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
          color: #0b455f;
          margin-bottom: 1rem;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 140%;
          color: #0b455f;
        }
      }
    }
  }
}
