.TyingOnboarding {
  height: 100vh;
  &.blue {
    background: var(--hero-blue-0-b-455-f, #0b455f);
    height: 100%;
    display: flex;

    .logo {
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }

  .slides {
    width: 75% !important;
  }

  .topLeft {
    position: absolute;
    top: 0;
    left: 0;
  }

  .end {

    .bottom {
      color: var(--hero-blue-0-b-455-f, #0B455F);

/* Heading 5 */
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 28.8px */
    }
    .sideBoxes {
      display: flex;
      justify-content: space-between;
      margin-top:2rem;

      .box {
        display: flex;
        width: 45%;
        border-radius: 10.994px;
        border: 3px solid #0b455f;
        padding: 10px 20px;

        .left {
          width:60%;
        }

        p {
          color: #0b455f;
          margin-top:20px;
          /* Heading 5 */
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 28.8px */
        }

        h3 {
          border: 2px solid #0b455f;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          margin: 0;
        }
      }
    }
    > h2 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 54px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 59.4px */
    }

    p {
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Heading 5 */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
    }
  }

  .titleSlide {
    text-align: left;
    justify-content: center;
    padding-left: 10%;

    h1 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 74px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 81.4px */
      margin-bottom: 1rem;
    }

    h2 {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
    }

    p {
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
    }
  }

  .topRight {
    position: absolute;
    top: -20px;
    right: -50px;
  }

  .content {
    text-align: left;
    height: 100vh;

    &.row {
      display: flex !important;
      flex-direction: row;
      justify-content: space-between;
    }

    .left {
      width: 50%;

      h2 {
        color: var(--hero-blue-0-b-455-f, #0b455f);

        /* Heading 2 */
        font-family: Inter;
        font-size: 54px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 59.4px */
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }

    .right {
      width: 40%;
      display: flex;
      justify-content: flex-end;
      margin-top: 0px;
      align-items: center;
      align-items: flex-start;
      img {
        width: auto;
        max-height: 65% !important;
        margin-top:9rem;
      }
    }

    h3 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      /* Heading 5 */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      margin-top: 3rem;
    }

    img {
      margin: 0px auto;
    }

    .here {
      max-height: 340px;
      margin: 0px auto;
      display: block;
      margin-top: 3rem !important;
    }

    h2 {
      color: var(--0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.4em;
      margin-bottom: 1rem;
      max-width: 80%;
      margin-top: 3rem;
    }

    p {
      color: var(--0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;

      b {
        margin-bottom: -10px;
        display: block;
        padding: 0;
      }
    }
  }

  .sides {
    display: flex !important;
    justify-content: space-between;

    .left {
      width: 30%;
    }

    .right {
      width: 65%;
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: flex-start;

      .box {
        border-radius: 8.491px;
        border: 0.849px solid rgba(11, 69, 95, 0.25);
        background: var(--white-ffffff, #fff);
        width: 32%;
        padding: 20px;
        height: 300px;
        margin-bottom: 2rem;

        img {
          margin: 0px;
          max-width: 30px;
        }

        &.blue {
          background: var(--2-a-7394, #2a7394);

          .top {
            h3 {
              color: #fff;
            }
          }

          p {
            color: #fff;
          }

          .names {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
              border-radius: 8.491px;
              background: var(--white-ffffff, #fff);
              display: flex;
              padding: 10px;
              align-items: center;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 14.434px;
              font-style: normal;
              font-weight: 600;
              line-height: 110%; /* 15.878px */
            }
          }
        }

        &.yellow {
          background: var(--hero-yellow-fede-66, #fede66);
          .top {
          }

          .names {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h4 {
              border-radius: 8.491px;
              background: var(--white-ffffff, #fff);
              display: flex;
              padding: 10px;
              align-items: center;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 14.434px;
              font-style: normal;
              font-weight: 600;
              line-height: 110%; /* 15.878px */
            }
          }
        }

        .top {
          display: flex;
          align-items: center;

          h3 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 14.434px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 15.878px */
            margin-left: 10px;
          }
        }

        > p {
          margin-top: 1rem;
          color: var(--hero-blue-0-b-455-f, #0b455f);
          font-family: Inter;
          font-size: 10.189px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 12.227px */
        }

        .empty {
          border-radius: 8.491px;
          border: 1.698px dashed #2a7394;
          display: flex;
          height: 150px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 20px;

          h4 {
            color: var(--013247, #013247);
            text-align: center;
            font-family: Inter;
            font-size: 12.736px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 15.283px */
          }
        }
      }
    }
  }
}
