.link.highlight_moment {
  background-color: white;
  padding: 25px;
  border-radius: 6px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 24px;
  word-break: break-word;
  a {
    color: red;
  }
  .hide {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 100;
    &.empty svg {
      fill: transparent;
      stroke: black;
      stroke-width: 20px;
      font-size: 13px;

      path {
        fill: transparent;
      }
    }
    &.fill svg,
    &:hover svg {
      fill: #005579;
      stroke-width: 20px;
      font-size: 13px;

      path {
        fill: #005579;
      }
    }
    &:hover svg {
      fill: #005579;
      stroke-width: 20px;
      cursor: pointer;
      font-size: 13px;


      path {
        fill: #005579 !important;
      }
    }
  }

  .highlight_moment_featured {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    &.empty svg {
      fill: transparent;
      stroke: black;
      stroke-width: 20px;
      font-size: 13px;

      path {
        fill: transparent;
      }
    }
    &.fill svg,
    &:hover svg {
      fill: #005579;
      font-size: 13px;

      stroke-width: 20px;

      path {
        fill: #005579;
      }
    }
    &:hover svg {
      fill: #005579;
      stroke-width: 20px;
      font-size: 13px;
      cursor: pointer;

      path {
        fill: #005579 !important;
      }
    }
  }

  img {
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 6px;
    max-width: 100%;
    border-radius: 24px;
  }

  .blank {
    background-color: orange;
    width: 100%;
    height: 200px;
    margin-bottom: 1em;
    background: #ff9e67;
    border-radius: 24px;
    margin-top: 15px;
  }

  .link_data {
    background-color: lighten(#ececec, 3);
    padding: 20px;
    border-radius: 10px;

    &:hover {
      background-color: #dcdcdc;
    }

    h3 {
      font-size: 1.2em;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    p {
      color: #000;
      font-size: 0.9em;
    }
  }

  .type {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.25;
  }

  h2 {
    margin-bottom: 20px;
    background-color: transparent;
    padding: 0;
    font-size: 1.6em;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 140%;
    color: #013247;
    margin: 0;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #013247;
    opacity: 0.6;
  }

  a {
    word-wrap: break-word;
    margin-top: 20px;
  }

  button {
    width: 48px;
    height: 48px;
    background: #ff9e67;
    border-radius: 100%;
    display: block;
    text-align: center;
    box-shadow: none;
    margin-left: auto;

    svg {
      margin: 0px auto;
      display: block;
      font-size: 22px;
      margin-left: -10px;
    }
  }
}
