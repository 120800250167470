.addHighlight {
  position: absolute;
  z-index: 999999999999;
}

.highlightReel-add {
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  overflow: scroll;
  > p {
    margin-top: 20px;
  }
  position: fixed;
  z-index: 900;
  overflow: hidden;
  height: 100%;

  &.edit {
    width: 100%;

    .left {
      width: 400px !important;
      @media screen and (max-width: 1200px) {
        width: 100% !important;
      }
    }

    .right {
      width: calc(100% - 400px) !important;
      @media screen and (max-width: 1200px) {
        width: 100% !important;

      }
    }
  }

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    overflow: scroll;
  }

  .white-background {
    margin-top: 0px;
    position: relative;
    border-radius: 0px;
    height: 100%;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    @media screen and (max-width: 1200px) {
      height: 100%;
      margin-bottom:0;
      padding-bottom:0;
    }

    > h2 {
      margin-bottom: 20px;

      span {
        font-size: 1.5rem;
      }
    }
  }

  .avatar {
    display: flex;
    margin-bottom: 2rem;
    align-items: center;

    select {
      background-color: #e1f6ff;
      border: None;
      color: #000;
      font-weight: 500;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 20px;
    }
  }
  .form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      height: 100%;
    }

    button {
      &:hover {
      }
    }

    label {
      margin-bottom: 5px;
      width: 100%;
      display: flex;

      span {
        margin-left: auto;
        opacity: 0.3;
        font-size: 0.7rem;
      }
    }
    input,
    textarea {
      background-color: #ececec;
      border: 1px solid #58c5f3;
    }

    .right {
      width: calc(100% - 400px);
      background-color: #f4eeec;
      padding-left: 40px;
      display: flex;
      padding-right: 40px;

      image {
        width: 100%;
      }

      @media screen and (max-width: 1200px) {
        padding-left: 5px;
        padding-right: 5px;
        height: auto;
      }

      .white-background {
        width: 87%;
        padding: 5px 20px;
        background-color: transparent;
        box-shadow: none;
        border: none;
        margin-top: 20px;
        height: 90vh;
        border-radius: 8px;
        padding-top: 0;
        margin: 0px auto;
        margin-top: 50px;
        @media screen and (max-width: 1200px) {
          width: 100%;
          padding: 20px 20px;
          height: 100%;
          margin-top:0;

          .preview {
            min-height: 400px;
            height: 100%;

            .highlightArea {
              min-height:400px;
              @media screen and (max-width: 1200px) {
                min-height: 400px;
                height: 100%;
              }
            }
          }
        }
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      .preview {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 0 0 100%; /* Let it fill the entire space horizontally */
        max-width: 100%;
        flex-direction: column;

        img {
          width: 100%;
          object-fit: cover;
        }

        h5 {
          width: 100%;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 15px;
        }
        .highlightArea {
          background-color: transparent;
          width: 100% !important;
          height: 90%;
          margin-bottom: 20px;
          background: transparent;
          border-radius: 6px;
          position: relative;

          .highlightMoment {
            background: #ffffff;
            border: 0px solid #aaaaaa;
            border-radius: 6px;
            padding: 36px;
            width: 60%;
            max-height: 92%;
            overflow: scroll;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 36px;
            word-break: break-word;
            @media screen and (max-width: 1200px) {
              width: 90%;
            }

            .noPhoto {
              height: 200px;
              background-color: orange;
              margin-bottom: 2rem;
              border-radius: 27px;
              background: var(--hero-orange-ff-9-e-67, #ff9e67);
            }

            img {
              max-height: 200px;
              margin-bottom: 2rem;
              border-radius: 27px;
            }

            h3 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 800;
              font-size: 32px;
              line-height: 36px;
              color: #9d9d9d;
              margin-bottom: 1rem;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 30px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 36px */
            }

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 300;
              font-size: 17.5469px;
              line-height: 26px;
              margin: 0;
              padding: 0;
              margin-bottom: 1rem;
              color: var(--hero-blue-0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 22.5px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 27px */
            }

            a {
              color: #0d8cc2;
              display: block;
              margin-top: 2em;
            }
          }
        }
      }

      .btn.primary {
        background-color: #6699af;
        border-color: #6699af;
        color: #fff;
        padding: 7px 20px;
      }

      .buttons {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .btn {
          background-color: #6699af;
          border-color: #6699af;
          color: #fff;
          padding: 7px 20px;
          border-radius: 5px;
          box-shadow: none;
          border: none;
          font-size: 1em;
          font-weight: 600;
          margin: 0;
          margin-left: 20px;
          cursor: pointer;
          height: 40px;

          &:hover {
            background-color: darken(#6699af, 10);
            border-color: #6699af;
          }

          &.delete {
            background-color: #ff6767;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .left {
      width: 400px;
      padding-top: 20px;
      overflow: scroll;
      height: 100vh;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1200px) {
        width: 100%;
        margin-bottom: 0rem;
        padding-left: 15px;
        padding-right: 15px;
        height: auto;
      }

      .title {
        display: flex;
        border-bottom: 2px solid #ececec;
        margin-bottom: 0em;
        padding-bottom: 1em;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        svg {
          cursor: pointer;
        }
        .close {
          display: flex;
          position: relative;
          color: #b3c1c8;
          margin: 0;
          margin-right: 1em;
          font-size: 25px;

          &:hover {
            color: #000;
          }
        }

        h2 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          margin: 0;
          padding: 0;
          color: #0b455f;
        }
      }

      .collaborators {
        margin-bottom: 0rem;
        margin-top: 1rem;
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        border-top: 2px solid #ececec;
        padding-top: 0.5rem;
        display: flex;
        flex-direction: row;
        max-height: 50%;
        flex-wrap: wrap;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }

        p {
          margin-bottom: 0.5rem;
          align-self: flex-start;
          width: 100%;
          color: #0b455f;
        }
        h5 {
          font-size: 1rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 100%;
          font-size: 1rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #0b455f;
          margin-top: 1rem;

          svg {
            margin-right: 10px;
          }

          .arrow {
            margin-left: 10px;
          }

          span {
            opacity: 0.5;
            font-size: 0.6rem;
            margin-left: auto;
          }
        }

        .collaboratorInput {
          margin-top: 0rem;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;
          margin-bottom: 2rem;

          .error {
            width: 100%;
            display: flex;
            background-color: #ee9b65;
            color: #fff;
            padding: 6px 20px;
            border-radius: 5px;
          }

          .form-group {
            width: 100%;
            margin-bottom: 0.5rem;
            width: 100%;
            padding: 0;

            label {
              font-weight: 600;
              font-size: 0.8rem !important;
              color: #0b455f;
              font-size: 16px;
            }

            @media screen and (max-width: 1200px) {
              width: 100%;
              margin-top: 2em;
            }

            &.full {
              width: 100%;
            }
          }
          input {
            width: 100%;
          }

          .infoSection {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            align-items: flex-start;
            margin-top: 2rem;
            flex-direction: column;
            h4 {
              font-size: 0.9rem;
              font-weight: 700;
              span {
                display: block;
                font-weight: 300;
                font-size: 0.9rem;
              }
            }
            button {
              color: #fff;
              font-size: 1rem;
              height: auto;
              margin-top: 0 !important;
              box-shadow: none;
              margin-top: 1rem !important;
              margin-left: auto;
              color: var(--0-b-455-f, #0b455f);
              text-align: center;
              font-family: Inter;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 21px */
            }
          }

          label {
            font-weight: 300;
            font-size: 1rem;
            color: #0b455f;
            font-size: 16px;

            span {
              font-size: 0.7rem !important;
              font-weight: 600;
            }
          }
          button {
            height: 47px;
            border-radius: 5px 5px 5px 5px;
          }
        }

        .collaborator-item {
          width: 100%;
        }

        .collaborator,
        .collaborator-item {
          margin-top: 1rem;
          padding-bottom: 0.5rem;
          display: flex;
          border-bottom: 2px solid #ececec;
          opacity: 0.5;
          align-items: center;
          justify-content: center;
          align-content: center;
          width: 100%;

          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-right: 10px;
          }

          svg {
            font-size: 20px;
          }

          .delete {
            cursor: pointer;

            &:hover {
              opacity: 1;
              color: #58c5f3;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
          p {
            margin: 0;
            width: 100%;
            padding: 0;
            margin-right: 2rem;
            display: flex;
            margin-top: 10px;

            svg {
              margin-right: 10px;
            }
          }
        }
      }

      .buttonActions {
        margin-top: auto;
        margin-bottom: 0px;
        background: #ffffff;
        box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.11);
        margin-left: -30px;
        width: calc(100% + 60px);
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        padding-left: 60px;
        padding-right: 60px;

        @media screen and (max-width: 1200px) {
          width: 100%;
          margin-left: 0px;
          box-shadow: none;
          justify-content: space-between;
          margin-top: 0;
          padding: 10px 0px;

          .btn {
            margin-right: 20px;
          }
        }

        .btn {
          background: red;
          border-radius: 6.38597px;
          color: #fff;
          font-size: 16px;
          box-shadow: none;
          font-weight: 500;
          padding: 10px 40px;
          background: var(--ff-9-e-67, #ff9e67);
          width: 45%;
          color: var(--0-b-455-f, #0b455f);
          text-align: center;
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 21px */
          box-shadow: 6px 6px 0px 0px rgba(255, 158, 103, 0.24);

          &:disabled {
            opacity: 0.3;
          }

          &.secondary {
            background: #e4e6ea;
            color: #000;
            color: var(--0-b-455-f, #0b455f);
            text-align: center;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 21px */
            box-shadow: none;
            &:hover {
              border: none;
              background: darken(#e4e6ea, 10);
            }
          }

          &:hover {
            border: none;
            background: darken(#ff9e67, 10);
          }
        }
      }

      .photoBox {
        border-top: 2px solid #ececec;
        padding-top: 2em;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 2em;

        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          color: #000000;
          color: #0b455f;
        }

        .inputPhotoBox {
          display: flex;
          align-items: center;
          position: relative;
          .icon {
            margin-top: 1em;
            margin-bottom: 1rem;
            background-color: #f0f2f5;
            display: inline-block;
            padding: 10px 13px;
            border-radius: 100%;
          }

          input {
            &:hover {
              cursor: pointer;
            }
          }

          img {
            width: 1rem;
            height: 1rem;
          }

          input {
            position: absolute;
            top: 10px;
            left: 0;
            width: 100%;
            opacity: 0;
            color: var(--0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 21px */
          }

          h5 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16.5306px;
            line-height: 19px;
            margin-left: 15px;
          }

          span {
            margin: 0;
            padding: 0;
            margin-left: auto;
            opacity: 0.5;
            font-size: 11px;
          }
        }
      }

      .photo {
        position: relative;
        width: auto;
        display: flex;
        img {
          max-width: 100%;
          min-width: 50%;
          object-fit: cover;
        }

        svg {
          background-color: #005579;
          padding: 5px;
          color: #fff;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .dragAndDrop {
        width: 100%;
        height: 500px;
        background-color: #ececec;
        padding: 20px;
        align-items: center;
        justify-content: center;
        border: 2px dotted black;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        text-align: center;
        border-radius: 5px;

        @media screen and (max-width: 1200px) {
          height: 250px;
        }

        svg {
          font-size: 60px;
          opacity: 0.5;
          margin-bottom: 1rem;
        }

        h4 {
          font-size: 1.4rem;
        }

        p {
          font-size: 18px;
          line-height: 25px;
          margin-top: 1em;
          opacity: 0.5;
        }
      }
    }

    .preview {
      max-width: 200px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
      margin-top: 20px;

      padding-left: 30px;
      padding-right: 30px;

      &.info {
        .reel {
          display: flex;
          width: 100%;
          margin-bottom: 1.5rem;
          align-items: center;
          .icon {
            width: 40px;
            height: 40px;
            background-color: #ececec;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
          }

          input {
            margin-left: auto;
            width: 20px;
            background-color: transparent !important;
            background: transparent !important;
          }

          .text {
            margin-left: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            h4 {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              margin: 0;
              padding: 0;
              width: 100%;
              line-height: 1.2em;
            }
            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 300;
              font-size: 12px;
              margin: 0;
              padding: 0;
              line-height: 1.5em;
            }
          }
        }
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #0b455f;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-size: 12px;
          line-height: 18px;
          margin-top: 10px;
          font-size: 13px;
          line-height: 1.5em;
          margin-top: 10px;
          color: #0b455f;
        }
      }
      input,
      textarea {
        background-color: #fff !important;
        padding: 10px 15px;
        margin-bottom: 0rem;
        border: 2px solid #dadada;
        border-radius: 6px;
        color: var(--0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 21px */

        &:focus {
          border: 2px solid #0d8cc2;
        }
      }
      label {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 10px;
        color: #0b455f;
        font-size: 16px;
      }
      &.flex {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;

        label,
        input {
          display: inline-block;
          margin: 0;
          padding: 0;
        }

        label {
          font-size: 1rem;
        }

        input {
          width: 2em !important;
          height: 2em !important;
          background-color: #fff !important;
        }
      }

      input[type="checkbox"] {
        padding: 0;
        width: 20px;
        height: 20px;
      }

      select {
        background: #dcf4ff;
        border: 1px solid #dcf4ff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        padding: 10px 20px;
        font-size: 14px;
        background-color: #ececec;
        border: 1px solid #58c5f3;
        color: #000;
      }
    }
  }
}
