.Sidebar {
    width:25%;
    color:#000;

    ul {
        margin:0;
        padding:0;
        background-color: white;
        padding:20px;
        margin-top:20px;
        li {
            color:#000;
            list-style: none;

            a {
                color:#000;
                display: inline-block;
                width: 100%;
                list-style: none;
                font-size:18px;
                padding:13px 15px;
                border-bottom:2px solid #ececec;
                margin-bottom:20px;
                cursor:pointer;


                &:last-child {
                    border-bottom:none;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #ececec;
                    font-weight: bold;
                }

                &.active {
                    background-color: #ececec;
                    font-weight: bold;
                }
            }
        }
    }
}