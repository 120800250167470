@import "../../theme.scss";

.usersAdmin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 40px;
  position: relative;
  margin-top: 30px;

  h2 {
    width: 100%;
    display: inline-block;
  }

  .csv-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

  .csv-button {
    width: 150px;
  }

  .right {
    width: 70%;
    padding: 20px;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
