@import "../../../theme.scss";

.ProgramEdit {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 90rem;
  padding: 0px 40px;
  position: relative;
  margin-top: 30px;

  .coachContainer {
    h3 {
      font-size: 1.3rem;
      opacity: 0.9;
    }
  }

  .welcomePack {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    input {
      width: 100%;
      margin-right: 2rem;
      height: 3.5rem;
    }

    button {
      height: 3.5rem;
    }
  }

  .bulkContainer {
    h3 {
      font-size: 1rem;
      font-size: 1.3rem;
      opacity: 0.9;
    }
    .container {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .form-input {
        display: flex;
        flex-direction: column;
      }
    }
    margin-bottom: 2rem;
  }
  .coach {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 3rem;

    .coach-info {
      display: flex;
      flex-wrap: wrap;

      .coach-name,
      .coach-email {
        width: 80%;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin-right: 1rem;
      }
    }

    > div {
      width: 40%;
    }

    .coach-search {
      position: relative;
      &:hover {
        .coaching-staff {
          display: block;
        }
      }
      .coaching-staff {
        position: absolute;
        top: 45px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        padding: 0px;
        z-index: 900;
        background-color: white;
        width: 300px;
        display: none;

        .coach {
          padding: 0;
          margin: 0;
        }

        .coach-info {
          border-bottom: 2px solid #ececec;
          width: 100%;
          padding: 10px 20px;
          cursor: pointer;

          &:hover {
            background-color: #ececec;
          }
        }
      }
    }
  }

  .users {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      position: relative;

      .delete {
        background-color: #000;
        position: absolute;
        top: 0px;
        right: 20%;
        z-index: 55;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          background-color: transparent;
          border: none;
          color: #fff;
          font-size: 13px;
          cursor: pointer;
        }
      }
      .transfer {
        background-color: #000;
        position: absolute;
        top: 0px;
        left: 20%;
        z-index: 55;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          background-color: transparent;
          border: none;
          color: #fff;
          font-size: 13px;
          cursor: pointer;
        }
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        object-fit: cover;
      }

      p {
        font-size: 12px;
        margin: 0;
        padding: 0;

        svg {
          color: green;
        }
      }

      h4 {
        font-size: 12px;
        text-align: center;
        margin-top: 5px;
        word-break: break-word;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .right {
    margin-left: auto;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  .rbt-menu {
    z-index: 50;
  }

  .breadcrumbs {
    display: flex;

    a {
      color: $pink;
    }

    font-weight: 600;

    div {
      margin-right: 6px;
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  h2 {
    width: 100%;
    margin-bottom: 30px;
  }

  > p {
    font-size: 16px;
  }

  .row {
    display: flex;
    justify-content: space-between;

    .coach,
    .admin {
      margin-left: auto;
      margin-right: 70px;

      h4 {
        width: 100%;
      }

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    .admin {
      margin-left: 0px;
      margin-right: 0;
    }
  }

  .crew {
    .members {
      display: flex;

      .imageBox {
        margin-right: -10px;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
    }
  }

  .container {
    width: 100%;

    #async-example {
      background-color: white;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      padding: 10px;

      .dropdown-item {
        margin-bottom: 15px;
        color: #000;
        padding: 10px;
        img {
          width: 30px;
          height: 30px;
          margin-right: 5px;
          border-radius: 100%;
        }
      }
    }

    h3 {
      margin: 0;
      padding: 0;
    }

    .inputBox {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }

    .lesson {
      background-color: #ececec;
      padding: 20px;
      display: flex;
      margin-bottom: 20px;
      border-radius: 15px;
      justify-content: space-between;

      .lesson-title {
        margin-bottom: 15px;
      }

      .lesson-info {
        width: calc(100% - 270px);
      }

      .lesson-actions {
        display: flex;
        justify-content: flex-end;
        margin-left: 40px;
        width: 270px;
        align-items: flex-start;

        button {
          margin-left: 10px;
          height: 40px;
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .lesson-info {
          width: 100%;
        }

        .lesson-actions {
          margin-left: 0px;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}

.transferModal {
  input {
    width: 100%;
  }

  .MuiOutlinedInput-root {
    display: flex;
  }
}
