.InterestForm {
  background: var(--hero-blue-0-b-455-f, #0b455f);
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;

  .MuiTextField-root {
    background-color: transparent;
  }

  .radio {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    p {
      padding: 0;
      margin: 0;
    }
  }

  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #f4eeec;
    box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36);
    border-radius: 7px;
    border: 1px solid #f4eeec;
  }

  .background {
    border-radius: 24px;
    background: var(--white-ffffff, #fff);
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    margin-top: 0px;
    position: relative;
    z-index: 5;

    .logo {
      margin-bottom: 2rem;
    }

    .program-chip {
      position: absolute;
      top: 2.3rem;
      right: 4rem;
    }

    .MuiChip-label {
      padding: 0 3rem;
    }

    .MuiTextField-root {
      border: 1px solid #f4eeec !important;
    }

    select,
    textarea,
    input {
      background-color: #fff;
      background: #ffffff;
      border: 1px solid #f4eeec;
      border-radius: 7px;
      border: 1px solid #f4eeec !important;
      border-radius: 7px;
      border: 1px solid #c3bdbb !important;

      &:active,
      &:focus {
        border: none !important;
        box-shadow: 0px 4px 14px rgba(232, 221, 218, 0.36) !important;
      }
    }

    h3 {
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 20px;
      color: var(--hero-blue-0-b-455-f, #0b455f);
      /* Heading 4 */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
      color: var(--hero-blue-0-b-455-f, #0b455f);
      /* Heading 4 */
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }

    .radios {
      margin-top: 1rem;
      .radio {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        p {
          margin: 0;
          margin-left: 1rem;
        }
      }
    }

    p, li {
      opacity: 0.7;
    }

    a {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-weight: 600;
    }

    ul {
      margin-left:20px;
      padding-left:0;
      margin-top:0;
      padding-top:0;
      margin-bottom:30px;
    }

    .form-input > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      label {
        margin-bottom: 0.5rem;
        font-size: 15px;
        opacity: 0.8;
      }
    }
  }

  .bottomLeft {
    position: fixed;
    bottom: -0px;
    left: -0px;
    z-index: 1;
  }

  .topRight {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
  }
}
