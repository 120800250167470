.roses_and_thorns {
  padding: 1rem;
  padding-top: 1rem;

  .lifeline {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;

    .lifeline__content {
      background-color: #fff;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding:30px;
      border-radius: 15px;
      min-width:300px;
      max-width: 70%;
      display:flex;
      flex-direction: column;

      .underline {
        margin: 0;
        padding: 0;
      }

      label {
        margin-top:40px;

        &:first-child {
          margin-top:0;
        }
      }
    }
  }

  .white-background {
    .underline {
      margin-bottom: 15px;
      margin-top: 0;

      &:nth-child(3) {
        margin-top: 35px;
      }

      &.title {
        margin-bottom: 50px;
        font-size: 2em;
      }
    }

    .section {
      margin-bottom: 60px;
    }

    .intro {
      h3 {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }
  }

  .past {
    .underline {
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }

    .past__memories {
      margin-top: 20px;
      margin-left: 15px;
      .roses,
      .thorns {
        margin-bottom: 20px;
      }
      .past__memory {
        margin-left: 30px;
        background-color: #ececec;
        padding: 10px;

        p {
          margin: 0;
          padding: 0;
        }

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .roses,
  .thorns,
  .memories {
    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      textarea {
        width: 100%;
        margin-bottom: 20px;
      }

      label {
        margin-top: 30px;
      }

      .btn {
        max-width: 150px;
      }
    }
  }

  .memory {
    margin-bottom: 15px;
    padding: 15px;
    position: relative;
    background-color: #ececec;
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }

    span {
      margin-left: 15px;
    }

    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
}
