.connective-tissue {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  .skrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6700000166893005;
    background: #013247 !important;
  }

  .skrim {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px 40px !important;
    border-radius: 5px;
    width: 80%;
    max-width: 900px;
    max-height: 85vh;
    overflow: scroll;
    z-index: 20;

    .bottom {
        justify-content: center;
        
        h3 {
            color: #013247;
text-align: center;
font-size: 15.452px;
font-family: Inter;
font-style: normal;
font-weight: 400;
line-height: 140%;
        }

        button{
            margin-left:1.5rem;
        }
    }

    hr {
        opacity: .4;
        width:70%;
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        width:100%;
        margin-top:1rem;

        button {
            background-color: #0B455F;
            border-radius: 100%;
            width:2.2rem;
            height: 2.2rem;
            padding:6px;
            border: none;
            margin-left:1rem;

            svg {
                color:#fff;
            }

            &:disabled {
                opacity: .5;
            }
        }
    }

    .highlight {
      display: flex;
      width: 100%;
      background-color: transparent;
      height: 100%;
      border: 1px solid rgba(244, 238, 236, 1);
      padding: 0;
      border-radius: 10px;
      overflow: hidden;

      .image {
        background-color: rgba(254, 222, 102, 1);
        height: auto;
        width: 150px;
      }

      .middle {
        padding: 20px;
        text-align: left;

        h4,
        p {
          text-align: left;
        }

        h4 {
          color: var(--013247, #013247);
          font-size: 19px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }

        p {
          color: var(--013247, #013247);
          font-size: 12px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          margin-top:1rem;
        }
      }

      .right {
        margin-left: auto;
        align-self: center;
        margin-right: 30px;
        padding-left:50px;
      }
    }

    .moments {
      display: flex;
      flex-wrap: wrap;

      .moment {
        border-radius: 7.726px;
        border: 1.104px dashed #013247;
        background: #f9f7f7;
        width: 30%;
        padding: 15px;
        margin-right: 3%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-bottom: 20px;
        position: relative;

        svg {
          color: #fff;
          text-align: center;
          font-size: 12.452px;
          font-weight: 600;
          line-height: 140%;
          background-color: #000;
          padding: 5px;
          border-radius: 100%;
          position: absolute;
          top: -10px;
          right: 10px;
        }

        p {
          padding: 0;
          margin: 0;
        }

        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }

    .buttonActions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;

      .secondary {
        color: #013247;
        text-align: center;
        font-size: 12.539px;
        font-family: Inter;
        line-height: 140%;
        background-color: transparent;
        box-shadow: none;
      }

      .primary {
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-size: 13.434px;
        font-family: Inter;
        font-weight: 600;
        line-height: 140%;
        padding: 6px 10px;
      }
    }

    h3 {
      color: var(--013247, #013247);
      text-align: center;
      font-size: 22.075px;
      font-family: Inter;
      font-weight: 600;
      line-height: 140%;
    }

    p {
      color: #013247;
      text-align: center;
      font-size: 15.452px;
      font-family: Inter;
      line-height: 140%;
      opacity: 0.6000000238418579;
    }

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .row {
      display: block;
    }

    .inputBox {
      margin-bottom: 40px;
    }

    label,
    input,
    textarea,
    select {
      width: 100%;
      text-align: left;
    }
  }
}
