@import "../../theme.scss";


.admin {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding:0px 40px;
    position: relative;

    h2 {
        width:100%;
        display:inline-block;
    }

    .crews {
        padding:0;
    }

    .right {
        width:70%;
        padding:20px;
        background-color: white;
        margin-top:20px;

        h3 {
            font-size:2rem;
        }

        .row {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            margin-bottom:20px;
            
            button {
                padding:5px 20px;
            }

            h3 {
                padding:0;
                margin:0;
            }
        }
        
        .crew {
            border-radius: 10px;
            display:flex;
            flex-wrap: wrap;
            margin-bottom:10px;
            margin-top:15px;
            width: 100%;


            h6 {
                margin:0;
                padding:0;
            }

            a {
                color:black;
                font-weight: 600;
                width: 100%;
                display:flex;
                justify-content: space-between;
                align-items: center;
                background-color: #ececec;
                padding:10px;
                border-radius: 10px;


                &:hover {
                    background-color: darken(#ececec, 10);
                }
                .info {
                    background-color: $pink;
                    color:#fff;
                    padding:6px 10px;
                    border-radius: 10px;
                }
            }

            svg {
                margin-right:5px;
            }
        }

        h3 {
            margin-top:0;
        }

    }
}