.strengths .ProgramModal {
  &.FurtherModal {
    .modal-content {
      .right {
        h3 {
          color: var(--hero-orange-ff-9-e-67, #ff9e67);
          /* Button Body */
          font-family: Inter;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 18.7px */
        }

        h2 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 44.8px */
        }

        h4 {
          color: var(--013247, #013247);

          /* Heading 6 */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
          text-decoration: none;
        }

        button {
          margin-top:2rem;
        }
      }
    }
  }
  .modal-content {
    .right {
      padding-left: 0px;
      h3 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 44.8px */
      }
      h2 {
        color: #013247;
        /* Small Body */
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
        margin-top: 1rem;
      }

      h4 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 14.543px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 20.36px */
        text-decoration-line: underline;
      }

      ol {
        margin: 0;
        padding: 0;
        margin-left: 0px;
        margin-top: 1rem;
        list-style: none;
        counter-reset: item;
        display: flex;
        flex-direction: column;
        gap: 20px;

        li {
          counter-increment: item;
          display: flex;
          flex-grow: 0;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;

          &:before {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            content: counter(item);
            background: #013247;
            border-radius: 100%;
            color: white;
            width: 30px;
            height: 30px;
            text-align: center;
          }

          p {
            width: calc(100% - 50px);
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 21px */
            opacity: 1;
            margin: 0;
            padding: 0;
          }
        }
      }

      .bold {
        color: #013247;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        opacity: 1;
      }

      .item {
        h4 {
          color: var(--013247, #013247);
          text-decoration: none;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 24px */
        }
        p {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          opacity: 0.6;
        }
      }

      .actions {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        button {
          margin-left: 0;
        }
        .pagination {
          margin-left: auto;
          margin-top: 0rem;
          margin-right: 3rem;
          button {
            background-color: transparent;
            border: none;

            svg {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
