.publicVision {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .skrim {
      background: #013247;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      cursor: pointer;
      opacity: 0.67;
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 50px 50px;
      border-radius: 5px;
      width: 80%;
      max-width: 1200px;
      max-height: 85vh;
      overflow: scroll;
      z-index: 20;
      display: flex;
      display: flex;
      justify-content: space-between;

      .actions {
        display: flex;
      }

      .left {
        flex-shrink: 0;
      }

      .right {

        h4 {
          color: #ff9e67;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
          margin-bottom:1rem;
        }

        h2 {
          color: var(--013247, #013247);
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 44.8px */
          margin-bottom:1em;
        }

        p {
          color: var(--013247, #013247);

          /* Small Body */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 18px */
        }
      }

      p {
        color: #013247;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 18px */
        opacity: 0.6;
      }

      .left {
        width: 30%;

        .item {
            background-color: #fff5f0;
            width: 100%;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;

            svg {
                position: relative;
                top: initial;
                left: initial;
                right: initial;
                opacity: 1;
            }
        }
      }

      .right {
        width: 60%;
      }

      svg {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 16px;
        opacity: 0.3;
        cursor: pointer;
      }

      .actions {
        margin-left: auto;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 3rem;
        align-items: center;

        span {
          margin-right: 1rem;
          color: #013247;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }

        button.btn {
          margin-left: auto;
          margin: 0;
        }

        button.text {
          color: #013247;
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          text-decoration: none;
          line-height: 140%; /* 19.6px */
          margin-right: 2rem;
        }
      }

      h5 {
        color: var(--3485-aa, #3485aa);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
        text-decoration: none;
      }

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .images {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
      }

      .row {
        display: block;
      }

      .inputBox {
        margin-bottom: 40px;
      }

      h2 {
        color: var(--013247, #013247);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 38.4px */
        margin-bottom: 1rem;
      }

      button {
        margin-left: auto;
        margin-top: 3rem;
      }

      h5 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        text-decoration-line: underline;
        margin-top: 1.4rem;
      }

      .icons {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .item {
          display: flex;
          margin-top: 1rem;
          align-items: center;
          margin-bottom: 1rem;

          h4 {
            color: var(--013247, #013247);
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 18.7px */
            margin-bottom: 0.2rem;
          }

          p {
            color: #013247;

            /* Small Body */
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 18px */
            padding: 0;
            margin: 0;
          }

          span {
            width: 42px;
            height: 42px;
            background-color: #013247;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            margin-right: 1em;
          }
        }
      }

      label,
      input,
      textarea,
      select {
        width: 100%;
        text-align: left;
      }
    }
  }
  .container {
    width: 80%;
    background-color: #fff;
    padding: 2rem;
    border-radius: 25px;
    background: var(--white-ffffff, #fff);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    overflow: scroll;

    h2 {
      margin-bottom: 1rem;
      color: var(--hero-blue-0-b-455-f, #0b455f);

      /* Heading 4 */
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 38.4px */
    }
  }

  .slides {
    display: flex;
    overflow: scroll;
    gap: 1rem;

    .box {
      width: 250px;
    }
  }

  .box {
    flex-shrink: 0;
    width: 100%;
    height: 400px;
    border-radius: 6.795px;
    background: #fff;
    border: 10px solid #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    border: 1px solid #eaf1f4;
    border-radius: 13.541px;
    width: 100%;
    padding: 15px;
    height: 400px;
    margin-top: 1rem;

    .inside {
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
      background-color: #fff;
      height: 100%;
      border-radius: 10px;
      align-items: flex-start;
      justify-content: flex-start;
      overflow: hidden;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 0;

        span {
          margin-bottom: 0;
          margin-top: 1rem;
        }
        button {
          padding: 0;
          color: var(--3485-aa, #3485aa);
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 16.5px */
          margin: 0;

          svg {
            margin-left: 0.5em;
          }
        }
      }

      h4 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 11.956px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 14.347px */
      }

      h2 {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 19.129px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 22.955px */
        opacity: 1;
        margin: 0;
        margin-top: 1rem;
      }

      p {
        color: var(--hero-blue-0-b-455-f, #0b455f);
        font-family: Inter;
        font-size: 12.753px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 15.941px */
        opacity: 1;
      }

      &.growth {
        background: #eaf1f4;

        span {
          background: var(--3485-aa, #3485aa);
        }
      }

      &.relationships {
        background: #fff8e0;

        span {
          background: var(
            --e-4-c-037,
            linear-gradient(0deg, #e4c037 0%, #e4c037 100%),
            #ecddd8
          );
        }
      }

      &.impact {
        background: #fff2eb;

        span {
          background: var(
            --f-48-a-4-e,
            linear-gradient(0deg, #f48a4e 0%, #f48a4e 100%),
            #ecddd8
          );
        }
      }
    }

    &.nonActive {
      opacity: 0.2;

      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    img {
      max-height: 200px;
      width: auto;
      align-self: flex-start;
      margin: 0px auto;
      margin-top: auto;
    }

    h4 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 18px */
      opacity: 0.5;
    }

    h2 {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      opacity: 0.5;
      word-wrap: break-word;
    }

    span {
      display: flex;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: var(--3485-aa, #3485aa);
      color: var(--white-ffffff, #fff);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 13.2px */
      width: auto;
      flex-grow: 0;
      flex-grow: 0;
      align-self: flex-start;
      margin-bottom: 1rem;
    }

    p {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      opacity: 0.5;
      word-wrap: break-word;
    }
  }

  .coverBox {
    border-radius: 16.988px;
    background: #fff;
    width: 100%;
    padding: 10px;
    height: 400px;

    .inside {
      border-radius: 10px;
      background: rgba(42, 115, 148, 0.5);
      padding: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-position: center;
      background-size: cover;
      position: relative;

      .content {
        z-index: 50;
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 10px;

        .bottom {
          margin-top: auto;
          color: #fff;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 26px */
          display: flex;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          width: 70%;
          padding: 0;

          p {
            text-align: left;
            padding: 0;
            margin: 0;
            color: #fff;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
          }
        }
      }

      .overlay {
        background-color: red;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;

        &.blue {
          background-color: #94b9c9;
        }

        &.orange {
          background-color: #ff9e67;
        }

        &.yellow {
          background-color: #fede66;
        }

        &.tan {
          background-color: var(
            --e-1-c-7-be,
            linear-gradient(0deg, #e1c7be 0%, #e1c7be 100%),
            #ecddd8
          );
        }
      }

      span {
        border-radius: 5px;
        background: var(--hero-blue-0-b-455-f, #0b455f);
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 18.7px */
        padding: 5px 15px;
        align-self: flex-start;
      }

      h2 {
        color: var(--white-ffffff, #fff);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 48px */
        margin-top: 1.5rem;
        word-wrap: break-word;
      }
    }
  }
}
