.HighlightReelOnboarding {
  width: 100%;
  height: 100%;

  &.start {
    background: var(--hero-blue-0-b-455-f, #0b455f);
    color: #fff;

    h2,
    p {
      color: #fff !important;
    }
  }

  &.Program {
    &.start {
      background: var(--hero-blue-0-b-455-f, #0b455f);
      color: #fff;
      text-align: left !important;
      padding-left:20%;

      h4,h1,p {
        margin-bottom:2rem;
      }

      h4 {
        color: #fff;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        text-align: left !important;
      }

      h1 {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 74px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 81.4px */
        text-align: left !important;
      }

      p {
        color: #fff;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 22px */
        text-align: left !important;
      }
    }
  }

  .topright {
    position: absolute;
    top: 0;
    right: 0;
  }

  .topleft {
    position: absolute;
    top: 0;
    left: 0;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 50px;
    z-index: 500;
  }

  .dots {
    position: absolute;
    right: 38%;
    top: 20px;
  }

  section {
    p {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
    }

    .subline {
      color: var(--hero-blue-0-b-455-f, #0b455f);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
    }
  }

  .roxanne {
    position: fixed;
    left: 30px;
    bottom: 30px;

    .chatMessage {
      p {
        color: #000;
        font-weight: 400 !important;
      }

      h4 {
        text-align: left;
      }
    }
  }

  .slide1 {
    h2 {
      text-align: center;
    }
    svg {
      max-width: 90%;
    }
  }

  .coach {
    img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      margin-bottom: 0.5rem;
    }
    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.1em;
      color: #0b455f;
      margin-bottom: 2rem;
      text-transform: uppercase;
    }

    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 44px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #0b455f;
      margin-top: 1rem;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;
      /* or 117% */

      text-align: center;

      /* #0B455F */

      color: #0b455f;
    }
  }

  .expect {
    display: flex !important;
    width: 100%;
    .left {
      width: 50%;
      h2 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 70px;
        /* or 117% */

        /* #0B455F */

        color: #0b455f;
      }
    }

    .right {
      width: 50%;
      margin-left: 4em;

      ol {
        margin: 0;
        padding: 0;
        text-align: left;
        margin-top: 3em;
        margin-left: 2em;

        li {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 1rem;
          color: #0b455f;
        }
      }

      h4 {
        font-family: "Inter";
        text-align: left;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        color: #0b455f;
      }
    }
  }

  section {
    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 163.5%;
      /* or 98px */

      text-align: left;

      /* #0B455F */

      color: #0b455f;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      text-align: left;
      color: #0b455f;
    }

    .titleBox {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h5 {
        border-radius: 10px;
        background: rgba(52, 133, 170, 0.2);
        display: inline-flex;
        padding: 20px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        /* Large Body */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 26px */
        text-align: left !important;
      }
    }

    .boxImages {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      p {
        padding: 0;
        margin: 0;
        margin-left: 2rem;
        color: var(--hero-blue-0-b-455-f, #0b455f);

        /* Heading 5 */
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 28.8px */
      }

      img {
        border: 4px solid #3485aa;
        padding: 10px;
        border-radius: 18px;

        &.yellow {
          border: 4px solid #e4c036;
        }

        &.orange {
          border: 4px solid #ff9e67;
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 3rem;

      &.second {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          width: 45%;
          text-align: left;

          h2,
          p {
            text-align: left !important;
          }

          h2 {
            color: var(--0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 110%; /* 52.8px */
          }

          p {
            color: var(--0-b-455-f, #0b455f);

            /* Large Body */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 26px */
          }

          .box {
            display: flex;
            align-items: center;
            margin-bottom: 2em;

            p {
              padding: 0;
              margin: 0;
              color: var(--0-b-455-f, #0b455f);
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 28.8px */
              margin-left: 20px;
            }
          }
        }

        .right {
          width: 50%;
        }
      }

      h2 {
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        font-family: Inter;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px; /* 116.667% */
      }

      h3 {
        color: var(--0-b-455-f, #0b455f);
        text-align: center;
        /* Large Body */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 26px */
      }

      .left {
        width: 100%;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          /* or 150% */

          color: #0b455f;
        }
      }

      .sides {
        display: flex;
        margin-top: 3rem;
        justify-content: space-between;

        .boxSection {
          width: 30%;
          gap: 7.5px;
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          align-self: stretch;
          height: 100%;

          > h4 {
            color: var(--0-b-455-f, #0b455f);
            text-align: center;
            /* Large Body */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 26px */
            margin-top: 2rem;
          }
        }

        .box {
          border-radius: 18px;
          background: #fff;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 10px;
          padding: 18px;
          align-self: stretch;
          height: 350px;

          h3 {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 18px */
            text-align: left;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }

          p {
            color: var(--hero-blue-0-b-455-f, #0b455f);
            font-family: Inter;
            font-size: 11.25px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 13.5px */
          }
        }
      }

      .third {
        width: 30%;
        background: rgba(42, 115, 148, 0.1);
        border-radius: 8px;
        padding: 40px 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #2a7394;
        }
      }
    }
  }

  button {
    margin: 0px auto;
    margin-top: 2rem;
  }

  .skip {
    position: absolute;
    top: 40px;
    left: 50px;
    font-family: "Inter";
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    color: #000;
    opacity: 0.3;
    z-index: 500;
    cursor: pointer;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    color: #fff;
    margin-bottom: 1rem;
    font-family: "Inter";
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 74px;
    line-height: 110%;
    text-align: left;
    color: #ffffff;
    margin-bottom: 2rem;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
    text-align: left;
    margin-bottom: 2rem;
    color: #ffffff;

    &.smaller {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      margin-top: 3rem;
      svg {
        margin-right: 1rem;
      }
      color: #ffffff;
    }
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 163.5%;
    /* or 65px */

    text-align: center;

    /* #0B455F */

    color: #0b455f;
  }
}

.onboardingHome {
  width: 100%;
  height: 100%;
}
