.strengthsSort {
  padding-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 80px;
  margin-top: -20px;
  padding: 50px 0px;
  padding-top: 25px;
  background-color: #f4eeec;
  padding-bottom: 0;
  background-repeat: repeat;
  background-position: left center;
  background-size: 20px 20px;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }

  .top {
    max-width:90rem;
  padding:0px 40px;
    margin: 0px auto;
    padding-bottom: 30px;
    width:100%;
    

    h2 {
      color: #000;
      border: none;
      margin-bottom: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 50px;
      &:after {
        display: none;
      }
    }

    p {
      color: #000;
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }

  .strengthsSortContainer {
    width:100%;
    display:flex;
  }

  h2 {
    margin: 0;
    margin-bottom: 15px;

    span {
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 48px;
    }
  }

   .white-background {
    max-width:90rem;
    padding:40px 40px;
    margin: 0px auto;
    width:calc(100% - 80px);
    margin-bottom:30px;

    @media (max-width: 768px) {
      margin-bottom:30px;
    }

    &.padding {
      padding:40px;
      height: auto;
      display:block;
    }
  }

  h2 span {
    @media screen and (max-width: 768px) {
      font-size: 25px !important;
      line-height: 1.3em !important;
    }
  }

  .body-text {
    @media screen and (max-width: 768px) {
      font-size: 15px !important;
      line-height: 1.5em !important;
    }
  }

  .bottom {
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
    display: flex;
    max-width:90rem;
    margin: 0px auto;
    padding:20px 40px;
    padding-bottom:50px;

    button.blank {
      border: 3px solid #8C44D5;
      color:#8C44D4;
  
      &:hover {
          background-color: #8C44D5;
          color:#fff;
      }
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      padding: 0px 20px;

      button {
        width: 100%;
        margin: 0;
        margin-bottom: 30px;
        margin-right: 0px !important;
      }
    }
  }

  .body-text {
    margin-bottom: 20px;
  }

  .strengthContainer {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 36px;
      width: 1em;
    }
    .strength {
      background: #f3f3f3;
      border: 2px dashed #e4e4e4;
      padding: 10px 24px;
      margin-bottom: 10px;
      padding-left: 10px;
      width: 100%;

      svg {
        margin-right: 20px;
      }
    }
  }
}
