.companiesAdmin {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width:90rem;
    padding:0px 40px;
    position: relative;
    margin-top:30px;

    .modal {
        h4 {
            margin-bottom:20px;
        }

        .modal-content {
            padding:40px;
        }

        .input-group {
            margin-bottom:2rem;
        }

        .input-group.image {
            display: flex;
            flex-wrap:wrap;

            label {
                width:100%;
                margin:0;
                padding:0;
            }

            input {
                width:40%;
                margin-right:20px;
            }
        }

        img {
            max-width:100px;
        }

        button {
            margin-top:2rem;
        }
    }

    h2 {
        width:100%;
    }

    .right {
        width: 70%;
        padding: 20px;
        background-color: white;
        margin-top: 20px;

        .top {
            display:flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:3rem;

            h2 {
                margin-bottom:40px;
                font-size:1.7rem;
                font-weight: 300;
                width: auto;
                margin:0;
                padding:0;
            }

            button {
                padding:5px 15px !important
            }
        }

        

        .company {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom:20px;

            button {
                padding:5px 15px;
                box-shadow: none;
                border: none;
            }
            h3 {
                margin-bottom: 1rem;
                font-size: 1.3rem;
            }
        }

      }
}