.comment-item {
    color:#000;
    margin-top:2em;
    border-bottom:2px solid #ececec;

    &:last-child {
        border-bottom:none;
    }

    .user {
        display: flex;
        align-items: center;

        img {
            width:50px;
            height:50px;
            border-radius:50%;
            margin-right:1rem;
        }

        span {
            font-weight: 600;
            font-size:1.2rem;
        }
    }

    .info {
        padding-bottom:2rem;
        span {
            color:#666666;
            font-weight: 600;
        }
    }

    p {
        margin-top:1rem;
    }
}